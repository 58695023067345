import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import cardService from '../../../redux/features/card/cardService';
import {displayError} from '../../../utils/errors';
import LoadTable from '../../../component/Loaders/LoadTable';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import dateFormat from 'dateformat';
import ModalComponent from '../../../component/ModalComponent';
import AddCardBatch from '../../../component/Cards/AddCardBatch';
import PageCover from '../../../component/PageCover';

export default function CardBatchConfig() {
    const {token} = useAppSelector(state => state.auth);

    const [openFilter, setOpenFilter] = useState(false);
    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});
    const page = 1;
    const limit = 15;

    const fetchCardBatchConfig = async () => {
        try {
            setLoad(true);
            let res = await cardService.getCardBatchConfig(token);
            setLoad(false);
            setList(res);
        } catch (err) {
            setLoad(false);
            setList([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchCardBatchConfig();
    }, []);
    return (
        <PageCover page="Card Batch Config">
            <div>
                <BreadCrumbTitle
                    title="Card Batch Config"
                    dataCount={list?.meta?.total}
                    button="+ New"
                    buttonClick={() => setOpenFilter(true)}
                />

                <div className="basic-card mt-3">
                    <div className="head"></div>
                    <div className="body">
                        <div className="table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Batch Reference</th>
                                            <th>Status</th>
                                            <th>Region</th>
                                            <th>Admin Id</th>
                                            <th>Date Created</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    {!load && list?.data?.length > 0 && (
                                        <tbody>
                                            {list?.data?.map(
                                                (tr: any, i: number) => (
                                                    <tr key={tr.id}>
                                                        <td>
                                                            {limit *
                                                                (page - 1) +
                                                                i +
                                                                1}
                                                        </td>
                                                        <td>{tr.batch_ref}</td>
                                                        <td>{tr.user_id}</td>
                                                        <td>{tr.status}</td>
                                                        <td>{tr.region}</td>
                                                        <td>{tr.admin_id}</td>

                                                        <td>
                                                            {dateFormat(
                                                                tr.created_at,
                                                                'mmm dd, yyyy | h:MM TT',
                                                            )}
                                                        </td>
                                                    </tr>
                                                ),
                                            )}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                            {load && <LoadTable />}
                        </div>
                    </div>
                </div>
                <ModalComponent
                    open={openFilter}
                    close={() => setOpenFilter(false)}>
                    <AddCardBatch
                        setClose={() => {
                            setOpenFilter(false);
                            fetchCardBatchConfig();
                        }}
                    />
                </ModalComponent>
            </div>
        </PageCover>
    );
}
