import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import {displayError} from '../../../utils/errors';
import customerServices from '../../../redux/features/customer/customerService';
import Paginate from '../../../component/Paginate';
import LoadTable from '../../../component/Loaders/LoadTable';
import dateFormat from 'dateformat';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';

function Devices() {
    const {token} = useAppSelector(state => state.auth);
    const [list, setList] = useState<any>([]);
    const [load, setLoad] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(15);

    const filters = `?page=${page}&per_page=${limit}`;

    const getDevices = async () => {
        setLoad(true);
        try {
            const res = await customerServices.allDevices(token, filters);
            setList(res);
            setLoad(false);
        } catch (err) {
            setLoad(false);
            displayError(err, true);
        }
    };

    useEffect(() => {
        getDevices();
    }, [filters]);

    return (
        <div>
            <BreadCrumbTitle title="All Devices" dataCount={list?.total} />
            <div className="basic-card mt-3">
                <div className="body">
                    <div className="table-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Face Verification</th>
                                        <th>Face ID</th>
                                        <th>Register Channel</th>
                                        <th>Device Id</th>
                                        <th>Manufacturer</th>
                                        <th>Device Name</th>
                                        <th>Device Os</th>
                                        <th>Date Logged In</th>
                                        <th>Ip Address</th>
                                    </tr>
                                </thead>

                                {!load && list?.data?.length > 0 && (
                                    <tbody>
                                        {list?.data?.map(
                                            (props: any, i: number) => (
                                                <tr key={props.id}>
                                                    <td>
                                                        {' '}
                                                        {limit * (page - 1) +
                                                            i +
                                                            1}
                                                    </td>
                                                    <td>
                                                        {props?.user?.last_name}{' '}
                                                        {
                                                            props?.user
                                                                ?.first_name
                                                        }
                                                    </td>
                                                    <td>
                                                        {props?.user?.email}
                                                    </td>
                                                    <td>
                                                        {
                                                            props?.has_verified_face?"Done":"Not Done"
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            props?.face_verification_id
                                                            
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            props?.user
                                                                ?.register_channel
                                                        }
                                                    </td>
                                                    <td>{props.device_id}</td>
                                                    <td>
                                                        {
                                                            props.device_manufacturer
                                                        }
                                                    </td>
                                                    <td>{props.device_name}</td>
                                                    <td>{props.device_os}</td>
                                                    <td>
                                                        {dateFormat(
                                                            props.created_at,
                                                        )}
                                                    </td>
                                                    <td>{props.ip_address}</td>
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        {!load && list?.last_page > 1 && (
                            <Paginate
                                currentPage={page}
                                totalCount={list.total}
                                pageSize={limit}
                                onSelect={(p: number) => setPage(Number(p))}
                                onNext={(p: number) => setPage(p)}
                                onPrev={(p: number) => setPage(p)}
                                changeLimit={(p: string) => setLimit(Number(p))}
                            />
                        )}
                        {load && <LoadTable />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Devices;
