import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import disputeService from '../../../redux/features/dispute/disputeService';
import {useAppSelector} from '../../../redux/hooks';
import {FiSend} from 'react-icons/fi';
import {displayError} from '../../../utils/errors';
import BasicLoad from '../../../component/Loaders/BasicLoad';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';

function Tickets() {
    const params = useParams();

    const location = useLocation().state

    console.log(location,'tr')

    const [list, setList] = useState<any>({});
    const [load, setLoad] = useState(false);
    const [text, setText] = useState('');
    const {details} = useAppSelector(state => state.auth);
    const id = parseInt(params.id || '');
    const navigate = useNavigate();

    useEffect(() => {
        fetchTicketDetails();
    }, [id]);

    const fetchTicketDetails = async () => {
        try {
            setLoad(true);
            let res = await disputeService.getTicketDetails(id);
            setLoad(false);

            if (res) {
                setList(res);
            }
        } catch (err) {
            setLoad(false);
            setList([]);
        }
    };
    const postReply = async (e: any) => {
        e.preventDefault();
        setLoad(true);
        const payload = {
            disputeId: id,
            comment: text,
            addedBy: details?.id,
            name: details?.username,
            userType: 'admin',
        };
        try {
            let res = await disputeService.postComment(payload);
            setLoad(false);
            setText('');
            if (res) {
                fetchTicketDetails();
            }
        } catch (err) {
            setLoad(false);
            displayError(err);
        }
    };

    const closeTicket = async (e: any) => {
        e.preventDefault();
        const payload = {
            disputeId: id,
            status: 'Closed',
        };
        if (window.confirm('Are you sure want to close this ticket?')) {
            try {
                setLoad(true);
                let res = await disputeService.closeTicket(payload);
                setLoad(false);
                setText('');
                if (res) {
                    fetchTicketDetails();
                }
            } catch (err) {
                setLoad(false);
                displayError(err);
            }
        }
    };

    return (
        <div className="ticket-header">
            <BreadCrumbTitle title="Dispute" dataCount={list?.totalElements} />
            <div className="top-row">
                <div className="ticket-id">Ticket ID - {list?.dispute?.id}</div>
                <div className="d-flex gap-3">
                    <div
                        className="main-btn"
                        role="button"
                        onClick={() => navigate('/dashboard/disputes', {state:location})}>
                        Back
                    </div>
                    {list?.dispute?.status !== 'Closed' && (
                        <div className="btn-danger" onClick={closeTicket}>
                            Close Ticket
                        </div>
                    )}
                </div>
            </div>

            <div className="ticket-box">
                <div className="green">
                    <p>Transaction Ref:{list?.dispute?.transactionRef}</p>
                    <p>Transaction Date:{list?.dispute?.transactionDate}</p>
                    <p>N {list?.dispute?.amount}</p>
                    <p>{list?.dispute?.comment}</p>
                    <p className="sm-date">{list?.dispute?.createdAt}</p>
                    <p className="username">{list?.dispute?.username}</p>
                </div>
                {!load &&
                    list?.disputeComment?.length > 0 &&
                    list.disputeComment.map((props: any) => (
                        <div
                            className={
                                props.userType === 'agent'
                                    ? 'green'
                                    : 'purple-box purple'
                            }
                            key={props.id}>
                            <p>{props.comment}</p>
                            <p className="sm-date">{props.createdAt}</p>
                            <p className="username">{props.name}</p>
                        </div>
                    ))}
            </div>
            <div>
                {list?.dispute?.status !== 'Closed' ? (
                    <form className="ticket-input" onSubmit={postReply}>
                        <input
                            className="p-3"
                            value={text}
                            onChange={e => setText(e.target.value)}
                            placeholder="Type message...."
                        />

                        {load ? (
                            <BasicLoad />
                        ) : (
                            <button type="submit">
                                <FiSend size={'2rem'} />
                            </button>
                        )}
                    </form>
                ) : (
                    <div className="ticket-input">
                        <p className="text-center mt-1">
                            <b>Ticket has been closed</b>
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Tickets;
