import React, {useEffect, useState} from 'react';
import {SelectInput, TextInput} from '../../BasicInputs';
import {displayError} from '../../../utils/errors';
import customerServices from '../../../redux/features/customer/customerService';
import {useAppSelector} from '../../../redux/hooks';
import axios from 'axios';
import {toast} from 'react-toastify';
import config from '../../../utils/config';
import {authHeader} from '../../../utils/headers';
import ModalComponent from '../../ModalComponent';
import SecurityOtp from '../../SecurityOtp';
import adminService from '../../../redux/features/admin/adminService';

function UploadDocuments({details}: {details: any}) {
    const [comment, setComment] = useState('');
    const [name, setName] = useState<string>('');
    const [documentId, setDocumentId] = useState('');
    const [documentUrl, setDocumentUrl] = useState('');
    const [fileData, setFileData] = useState<File | null>(null);

    //otp
    const [otp, setOtp] = useState('');
    const [otpModal, setOtpModal] = useState(false);

    const [load, setLoad] = useState(false);

    const [docData, setDocData] = useState<any>([]);

    const getUserDocuments = async () => {
        try {
            const res = await customerServices.userDocuments(details.id);
            setDocData(res);
        } catch (error) {
            displayError(error, true);
        }
    };

    useEffect(() => {
        getUserDocuments();
    }, []);

    const onImageChange = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            setName(e.target.files[0].name);
            setFileData(e.target.files[0]);
        }
    };

    const displayFormType = (doc: any): string => {
        if (doc && Array.isArray(doc)) {
            const found = doc.find((d: any) => {
                return String(d.documents[0].id) === String(documentId);
            });
            return found ? found.documents[0].type : 'file';
        }
        return 'text';
    };

    const handleSubmission = async () => {
        //check if it is an image

        if (comment && documentId) {
            setLoad(true);
            const formData = new FormData();
            if (fileData) {
                formData.append('file', fileData);

                try {
                    //Upload image
                    const response = await axios.post(
                        `https://api.getripay.com/files/api/documents`,
                        formData,
                    );
                    const {data} = response.data;

                    const requestOptions = {
                        user_id: details.id,
                        comment,
                        document_id: documentId,
                        document_url: data.document_url,
                        google_auth_code: otp,
                    };

                    //submit image data

                    await adminService.changeImage(requestOptions);

                    setOtpModal(false);
                    setLoad(false);
                    toast.success('Uploaded Successfully');
                    setDocumentId('');
                    setComment('');
                    setOtp('');
                    setName('');
                } catch (error) {
                    displayError(error, true);
                    setLoad(false);
                    setOtp('');
                }
            } else {
                const requestOptions = {
                    user_id: details.id,
                    comment,
                    document_id: documentId,
                    document_url: documentUrl,
                    google_auth_code: otp,
                };
                try {
                    //submit text data

                    await adminService.changeImage(requestOptions);

                    setOtpModal(false);
                    setLoad(false);
                    toast.success('Uploaded Successfully');
                    setDocumentId('');
                    setComment('');
                    setOtp('');
                    setDocumentUrl('');
                } catch (error) {
                    displayError(error, true);
                    setLoad(false);
                    setOtp('');
                }
            }
        }
    };

    return (
        <div>
            <h4>Upload Document</h4>
            <div className="row">
                <div className="col-lg-6">
                    <div>
                        <SelectInput
                            val={documentId}
                            label={'Document Type'}
                            changeVal={setDocumentId}
                            option={docData?.map((i: any) => ({
                                value: i?.documents[0]?.id,
                                label: i?.documents[0]?.name,
                            }))}
                            placeholder="Select"
                        />
                    </div>
                    <div>
                        <TextInput
                            val={comment}
                            label={'Comment'}
                            changeVal={setComment}
                            placeholder="Enter Comment"
                        />
                    </div>

                    {displayFormType(docData) === 'file' ? (
                        <div className="d-flex flex-column mt-3 col-lg-6 mb-3">
                            <div className="upload-btn-wrapper">
                                <button className="btn wide">
                                    Click to Upload
                                </button>
                                <input
                                    type="file"
                                    name="myfile"
                                    onChange={onImageChange}
                                />
                            </div>
                            <h5 className="mt-3">{name}</h5>
                        </div>
                    ) : (
                        <div>
                            <TextInput
                                val={documentUrl}
                                changeVal={setDocumentUrl}
                                placeholder="Enter Value"
                            />
                        </div>
                    )}

                    <div className="col-lg-12  mt-5 text-center">
                        <button
                            onClick={() => setOtpModal(true)}
                            className="main-btn">
                            Submit
                        </button>
                    </div>
                </div>
                <div className="col-lg-6"></div>
            </div>
            <ModalComponent
                open={otpModal}
                title="Enter Google Auth Code"
                close={() => setOtpModal(false)}>
                <SecurityOtp
                    otp={otp}
                    setOtp={setOtp}
                    load={load}
                    handleSubmit={() => {
                        handleSubmission();
                    }}
                />
            </ModalComponent>
        </div>
    );
}

export default UploadDocuments;
