import {initializeApp} from 'firebase/app';
import {
    initializeAppCheck,
    ReCaptchaV3Provider,
    getToken as getAppCheckToken,
} from 'firebase/app-check';
import {encryptToken} from './encrypt';

declare global {
    var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

const generateAppCheckToken = async () => {
    if (process.env.REACT_APP_ENV === 'production') {
        try {
            const firebaseConfig = {
                apiKey: process.env.REACT_APP_FIREBASE_KEY,
                authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
                databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
                projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
                storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
                messagingSenderId: process.env.REACT_APP_FIREBASE_MESSENGERID,
                appId: process.env.REACT_APP_FIREBASE_APPID,
                measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
            };
            const app = initializeApp(firebaseConfig);

            let appCheck: any;
            if (process.env.REACT_APP_ENV === 'production') {
                appCheck = initializeAppCheck(app, {
                    provider: new ReCaptchaV3Provider(
                        process.env.REACT_APP_SECRET_KEY || '',
                    ),
                    isTokenAutoRefreshEnabled: true,
                });
            }

            const appCheckTokenResponse = await getAppCheckToken(appCheck);
            let token = encryptToken(
                appCheckTokenResponse.token,
                process.env.REACT_APP_AES_KEY,
                process.env.REACT_APP_AES_IV,
            );
            sessionStorage.setItem('@apptoken', token);
        } catch (err: any) {
            console.log(err.message, 'error');
        }
    } else {
        console.log('Test Platform');
    }
};

export {generateAppCheckToken};