import {apiRequest} from '../../../utils/axiosInstance';

const AllAgents = async (filters: string) => {
    const {data} = await apiRequest('posUrl').get(`/agent${filters}`);
    return data.data;
};

const AgentCategories = async () => {
    const {data} = await apiRequest('posUrl').get(`/agent-category`);
    return data.data;
};

const getTerminal = async (filter: any) => {
    const {data} = await apiRequest('url').get(
        `https://pos-api.getripay.com/tms/api/terminal/in-active`,
    );
    return data.data;
};

const assignPos = async (payload: any) => {
    const {data} = await apiRequest('posUrl').post(`/agent_terminal`, payload);
    return data.data;
};

const agentActivation = async (id: any, type: any) => {
    let url =
        type === 'Active' ? `/agent/deactivate/${id}` : `/agent/activate/${id}`;
    const {data} = await apiRequest('posUrl').get(`${url}`);
    return data.data;
};

const createAgent = async (payload: any) => {
    const {data} = await apiRequest('posUrl').post(`/agent`, payload);
    return data.data;
};

const updateAgent = async (payload: any, id: string) => {
    const {data} = await apiRequest('posUrl').patch(`/agent${id}`, payload);
    return data.data;
};

const agentService = {
    AllAgents,
    AgentCategories,
    assignPos,
    agentActivation,
    getTerminal,
    createAgent,
    updateAgent,
};
export default agentService;
