import {useEffect, useState} from 'react';
import {IoRefreshCircle} from 'react-icons/io5';
import {FaFileCsv, FaPlus} from 'react-icons/fa6';
import {MdFilterListAlt} from 'react-icons/md';
import {IoIosEye} from 'react-icons/io';
import LoadTable from '../../../component/Loaders/LoadTable';
import transactionService from '../../../redux/features/transaction/transactionService';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {formatCurrency} from '../../../utils/numberFormat';
import dateFormat from 'dateformat';
import {Link, useLocation} from 'react-router-dom';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import Paginate from '../../../component/Paginate';
import ModalComponent from '../../../component/ModalComponent';
import Filters from '../../../component/Transaction/Filters';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import {displayError} from '../../../utils/errors';
import SecurityOtp from '../../../component/SecurityOtp';
import {logout} from '../../../redux/features/auth/authSlice';
import {CSVLink} from 'react-csv';
import ReportTransaction from '../../../component/Transaction/ReportTransaction';
import PageCover from '../../../component/PageCover';

const Transactions = () => {
    const {token, details} = useAppSelector(state => state.auth);

    const locState = useLocation()?.state;

    const dispatch = useAppDispatch();

    const [openFilter, setOpenFilter] = useState(false);
    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});
    const [selectedIds, setSelectedIds] = useState([]);

    //OTP
    const [otp, setOtp] = useState('');
    const [openOtp, setOpenOtp] = useState(false);
    const [otpLoad, setOtpLoad] = useState(false);

    //Report
    const [reportModal, setReportModal] = useState(false);
    const [reason, setReason] = useState('');
    const [activity, setActivity] = useState('');

    // Filters
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const [startDate, setStartDate] = useState(
        new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1,
        ).toISOString(),
    );
    const [endDate, setEndDate] = useState(
        new Date(new Date().setDate(new Date().getDate() + 1)).toISOString(),
    );
    const [transactionRef, setTransactionRef] = useState('');
    const [debitedAccount, setDebitedAccount] = useState(locState?.accNo || '');
    const [transactionType, setTransactionType] = useState('');
    const [serviceCode, setServiceCode] = useState('');
    const [narration, setNarration] = useState('');
    const [transactionAmount, setTransactionAmount] = useState('');
    const [channel, setChannel] = useState('');
    const [processor, setProcessor] = useState('');
    const [transactionStatus, setTransactionStatus] = useState('');
    const [applyFilter, setApplyFilter] = useState(false);
    const [recipient, setRecipient] = useState('');
    const [transServ, setTransServ] = useState<any>([]);

    let filters = `?page=${page}&per_page=${limit}&startDate=${startDate}&endDate=${endDate}&transactionRef=${transactionRef}&debitedAccount=${debitedAccount}&recipientNumber=${recipient}&channelId=${channel}&transactionType=${transactionType}&narration=${narration}&transactionAmount=${transactionAmount}&serviceCode=${serviceCode}&processor=${processor}&transactionStatus=${
        transactionStatus === 'FAIL' ? 'TS_FAILED' : transactionStatus
    }&internalStatus=${transactionStatus === 'FAIL' ? 'TS_FAILED' : ''}`;

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchTransaction();
        fetchTransservice();
    }, [page, limit, applyFilter]);

    const refresh = () => {
        fetchTransaction();
    };

    const fetchTransaction = async () => {
        try {
            setLoad(true);
            let res = await transactionService.loadTransactions(token, filters);
            setLoad(false);
            if (res?.transactions) {
                setList(res);
            }
        } catch (err) {
            setLoad(false);
            setList({transactions: []});
        }
    };
    const fetchTransservice = async () => {
        try {
            setLoad(true);
            let res = await transactionService.transService(token);
            if (res) {
                setTransServ(res.service);
            }
        } catch (err) {
            setLoad(false);
            setTransServ([]);
        }
    };

    const badgeColor = (str: string) => {
        if (str) {
            let status = str.toLowerCase();
            if (status.includes('completed')) {
                return 'bg-success';
            } else if (status.includes('failed')) {
                return 'bg-danger';
            } else if (status.includes('pending')) {
                return 'bg-warning text-dark';
            } else if (status.includes('paid')) {
                return 'bg-primary';
            } else {
                return 'bg-info';
            }
        }
        return 'bg-info';
    };

    const returnStatus = (status: string) => {
        let split = status?.split('_');
        return split ? split[1] : status;
    };

    const resetTransaction = () => {
        setPage(1);
        setStartDate(
            new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                1,
            ).toISOString(),
        );
        setEndDate(
            new Date(
                new Date().setDate(new Date().getDate() + 1),
            ).toISOString(),
        );
        setTransactionRef('');
        setDebitedAccount('');
        setTransactionType('');
        setServiceCode('');
        setNarration('');
        setTransactionAmount('');
        setChannel('');
        setProcessor('');
        setRecipient('');
        setApplyFilter(!applyFilter);
    };

    const roles = details?.role_id;

    const handleRadioChange = (id: any) => {
        if (roles === '1') {
            setSelectedIds((prevSelectedIds: any) => {
                if (prevSelectedIds.includes(id)) {
                    return prevSelectedIds.filter(
                        (selectedId: any) => selectedId !== id,
                    );
                }
                return [...prevSelectedIds, id];
            });
        } else return;
    };

    const updateStatus = async (status: any) => {
        if (
            window.confirm(
                `Are you sure you want to update the status of the selected transactions to ${status}?`,
            )
        ) {
            try {
                let payload = {
                    transactionIds: selectedIds,
                    requestStatus:
                        status === 'completed' ? 'TS_COMPLETED' : 'TS_FAILED',
                };
                setLoad(true);
                const res = await transactionService.updateTransactionStatus(
                    token,
                    payload,
                    {aes_key: details.aes_key, aes_iv: details.aes_iv},
                );
                if (res) {
                    fetchTransaction();
                }
            } catch (err) {
                displayError(err, true);
            }
        }
    };

    const reverseTransaction = async () => {
        if (otp && otp.length === 6 && details.id && selectedIds.length > 0) {
            try {
                let payload =
                    selectedIds.length === 1
                        ? {
                              otp: otp,
                              transaction_id: selectedIds[0],
                              admin_user_id: details.id,
                          }
                        : {
                              otp: otp,
                              transactionIds: selectedIds,
                              admin_user_id: details.id,
                          };

                setOtpLoad(true);
                const res = await transactionService.reverseTransactionStatus(
                    token,
                    payload,
                    selectedIds.length === 1
                        ? 'single'
                        : selectedIds.length > 1 && 'multiple',
                    {aes_key: details.aes_key, aes_iv: details.aes_iv},
                );

                fetchTransaction();
                setOtp('');
                setOpenOtp(false);
                setSelectedIds([]);
                setOtpLoad(false);
            } catch (err: any) {
                displayError(err, true);
                setOtpLoad(false);
                setOtp('');
                fetchTransaction();
                setSelectedIds([]);
                setOpenOtp(false);

                if (err?.response?.status === 401) {
                    dispatch(logout());
                }
            }
        } else {
            alert('Please enter a valid OTP');
        }
    };

    const getName = (name: string) => {
        return name.replace(/ /g, '_');
    };

    return (
        <PageCover page="Transactions">
            <div>
                <BreadCrumbTitle
                    title="Transaction History"
                    dataCount={list?.allTransactions}
                />

                <div className="row mt-3">
                    <div className="col-lg-6 d-flex">
                        {selectedIds.length > 0 && (
                            <div className="filter">
                                <button
                                    className="main-btn danger"
                                    onClick={() => setReportModal(true)}>
                                    Report
                                </button>
                                <PageCover page="Reverse Transactions" button>
                                    <button
                                        className="main-btn"
                                        onClick={e =>
                                            updateStatus('completed')
                                        }>
                                        <span>Update to Completed</span>
                                    </button>
                                    <button
                                        className="main-btn"
                                        onClick={e => updateStatus('failed')}>
                                        <span>Update to Failed</span>
                                    </button>
                                    <button
                                        className="main-btn danger"
                                        onClick={() => setOpenOtp(true)}>
                                        <IoRefreshCircle />
                                        <span>Reverse</span>
                                    </button>{' '}
                                </PageCover>
                            </div>
                        )}{' '}
                    </div>

                    <div className="col-lg-6">
                        <div className="filter">
                            <button
                                onClick={refresh}
                                className="main-btn secondary">
                                <IoRefreshCircle />
                                <span>Refresh</span>
                            </button>

                            {list?.transactions && (
                                <button className="main-btn info">
                                    <CSVLink
                                        filename={'Transactions-file.csv'}
                                        data={list?.transactions}>
                                        <FaFileCsv />
                                        <span>Export CSV</span>
                                    </CSVLink>
                                </button>
                            )}

                            <button
                                className="main-btn"
                                onClick={() => setOpenFilter(true)}>
                                <MdFilterListAlt />
                                <span>Filter</span>
                            </button>
                            <button
                                className="main-btn danger"
                                onClick={() => resetTransaction()}>
                                <IoRefreshCircle />
                                <span>Reset</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="filtered-options">
                    {debitedAccount && (
                        <div>
                            <span>Account Number:</span>
                            <b>{debitedAccount}</b>
                        </div>
                    )}
                    <div>
                        <span>Start Date:</span>
                        <b>{dateFormat(startDate, 'mmm dd, yyyy, h:MM TT')}</b>
                    </div>
                    <div>
                        <span>End Date:</span>
                        <b> {dateFormat(endDate, 'mmm dd, yyyy, h:MM TT')}</b>
                    </div>
                </div>
                <div className="basic-card mt-3">
                    <div className="head"></div>
                    <div className="body">
                        <div className="table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>S/N</th>
                                            <th>Name/Debited Account</th>
                                            <th>Recipient Name</th>
                                            <th>Recipient Number</th>
                                            <th className="price">
                                                Amount (₦)
                                            </th>
                                            <th>TXN Service</th>
                                            <th>Status</th>
                                            <th>Reverse Transaction</th>
                                            <th>Recipient Bank</th>
                                            <th>Bank Logo</th>
                                            <th>TXN Reference</th>
                                            <th>Narration</th>
                                            <th>Transaction Date</th>
                                            <th>Transaction Type</th>
                                            <th>Inst. Transaction Ref</th>
                                            <th>App Version</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    {!load &&
                                        list?.transactions?.length > 0 && (
                                            <tbody>
                                                {list.transactions.map(
                                                    (tr: any, i: number) => (
                                                        <tr key={tr.id}>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={e =>
                                                                        handleRadioChange(
                                                                            tr.id,
                                                                        )
                                                                    }
                                                                    checked={
                                                                        selectedIds.find(
                                                                            f =>
                                                                                f ===
                                                                                tr.id,
                                                                        )
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                            </td>
                                                            <td>
                                                                <Link
                                                                    to={`/dashboard/transactions/${tr.id}/details`}
                                                                    state={{
                                                                        id: tr.id,
                                                                        name: 'transactions',
                                                                    }}>
                                                                    {limit *
                                                                        (page -
                                                                            1) +
                                                                        i +
                                                                        1}
                                                                </Link>
                                                            </td>
                                                            <td className="link">
                                                                <Link
                                                                    to={`/dashboard/customers/${getName(
                                                                        tr.user,
                                                                    )}`}
                                                                    state={
                                                                        tr.userId
                                                                    }>
                                                                    {tr.user} -{' '}
                                                                    {
                                                                        tr.debitedAccount
                                                                    }
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                {
                                                                    tr.recipientName
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    tr.recipientNumber
                                                                }
                                                            </td>
                                                            <td className="price">
                                                                {formatCurrency(
                                                                    tr.transactionAmount,
                                                                )}
                                                            </td>
                                                            <td>
                                                                {
                                                                    tr.transactionService
                                                                }
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className={`badge rounded-pill ${badgeColor(
                                                                        tr.transactionStatus,
                                                                    )}`}>
                                                                    {returnStatus(
                                                                        tr.transactionStatus,
                                                                    )}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className={`badge rounded-pill ${badgeColor(
                                                                        tr.internalStatus,
                                                                    )}`}>
                                                                    {returnStatus(
                                                                        tr.internalStatus,
                                                                    )}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {
                                                                    tr
                                                                        ?.additionalInfo
                                                                        ?.bank
                                                                }{' '}
                                                                {
                                                                    tr.recipientInstitutionName
                                                                }
                                                            </td>
                                                            <td>
                                                                <img
                                                                    src={
                                                                        tr.recipientInstitutionLogoUrl
                                                                    }
                                                                    alt=""
                                                                    width="30px"
                                                                    height="30px"
                                                                    // style={{ borderRadius: "50%" }}
                                                                />
                                                            </td>
                                                            <td>
                                                                {
                                                                    tr.transactionRef
                                                                }
                                                            </td>

                                                            <td>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip
                                                                            id={`button-${tr.id}`}>
                                                                            {
                                                                                tr.narration
                                                                            }
                                                                        </Tooltip>
                                                                    }>
                                                                    <span>
                                                                        {tr.narration.substring(
                                                                            0,
                                                                            20,
                                                                        )}
                                                                        ...
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td>
                                                                {dateFormat(
                                                                    tr.transactionDate,
                                                                    'mmm dd, yyyy | h:MM ss TT',
                                                                )}
                                                            </td>
                                                            <td>
                                                                {
                                                                    tr.transactionType
                                                                }
                                                            </td>
                                                            <td>
                                                                {tr.institutionTxnRef ||
                                                                    '--'}
                                                            </td>
                                                            <td>
                                                                {tr
                                                                    ?.additionalInfo
                                                                    ?.app_version ||
                                                                    '--'}
                                                            </td>

                                                            <td className="link d-flex gap-3">
                                                                <Link
                                                                    to={`/dashboard/transactions/${tr.id}/details`}
                                                                    state={{
                                                                        id: tr.id,
                                                                        name: 'transactions',
                                                                    }}>
                                                                    <IoIosEye />
                                                                </Link>
                                                                {tr.additionalInfo && (
                                                                    <Link
                                                                        to={`/dashboard/transactions/print-receipt`}
                                                                        state={
                                                                            tr
                                                                        }>
                                                                        Print
                                                                    </Link>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ),
                                                )}
                                            </tbody>
                                        )}
                                </table>
                            </div>
                            {!load && list?.lastPage > 1 && (
                                <Paginate
                                    currentPage={page}
                                    totalCount={list.allTransactions}
                                    pageSize={limit}
                                    onSelect={(p: number) => setPage(Number(p))}
                                    onNext={(p: number) => setPage(p)}
                                    onPrev={(p: number) => setPage(p)}
                                    changeLimit={(p: string) =>
                                        setLimit(Number(p))
                                    }
                                />
                            )}
                            {load && <LoadTable />}
                        </div>
                    </div>
                </div>
                <ModalComponent
                    open={openFilter}
                    close={() => setOpenFilter(false)}
                    size="lg">
                    <Filters
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        transactionRef={transactionRef}
                        setTransactionRef={setTransactionRef}
                        channel={channel}
                        setChannel={setChannel}
                        transactionStatus={transactionStatus}
                        setTransactionStatus={setTransactionStatus}
                        transactionAmount={transactionAmount}
                        setTransactionAmount={setTransactionAmount}
                        debitedAccount={debitedAccount}
                        setDebitedAccount={setDebitedAccount}
                        narration={narration}
                        setNarration={setNarration}
                        transactionType={transactionType}
                        setTransactionType={setTransactionType}
                        serviceCode={serviceCode}
                        setServiceCode={setServiceCode}
                        recipient={recipient}
                        setRecipient={setRecipient}
                        processor={processor}
                        setProcessor={setProcessor}
                        transService={transServ}
                        filter={() => {
                            setApplyFilter(!applyFilter);
                            setOpenFilter(false);
                        }}
                    />
                </ModalComponent>
                <ModalComponent
                    title="Enter your Otp"
                    open={openOtp}
                    close={() => setOpenOtp(false)}>
                    <SecurityOtp
                        otp={otp}
                        setOtp={setOtp}
                        handleSubmit={() => reverseTransaction()}
                        load={otpLoad}
                    />
                </ModalComponent>
                <ModalComponent
                    title="Report Transaction"
                    open={reportModal}
                    close={() => setReportModal(false)}>
                    <ReportTransaction
                        reason={reason}
                        setReason={setReason}
                        activity={activity}
                        setActivity={setActivity}
                        close={() => {
                            setReportModal(false);
                        }}
                        setOtpModal={setOpenOtp}
                    />
                </ModalComponent>
            </div>
        </PageCover>
    );
};

export default Transactions;
