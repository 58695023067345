import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../../redux/hooks';
import serviceService from '../../../../redux/features/service/serviceService';
import BreadCrumbTitle from '../../../../component/BreadCrumbTitle';
import {IoRefreshCircle} from 'react-icons/io5';
import {FaFileCsv} from 'react-icons/fa6';
import {MdFilterListAlt} from 'react-icons/md';
import {Link} from 'react-router-dom';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {IoIosEye} from 'react-icons/io';
import Paginate from '../../../../component/Paginate';
import LoadTable from '../../../../component/Loaders/LoadTable';
import ModalComponent from '../../../../component/ModalComponent';
import Filters from '../../../../component/Transaction/Filters';
import dateFormat from 'dateformat';
import {formatCurrency} from '../../../../utils/numberFormat';
import BankTransfersFilters from '../../../../component/Services/BankTransfersFilter';
import {displayError} from '../../../../utils/errors';
import {toast} from 'react-toastify';
import { CSVLink } from 'react-csv';

function BankTransactions() {
    const {token, details} = useAppSelector(state => state.auth);

    const [openFilter, setOpenFilter] = useState(false);
    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});

    // Filters
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const [startDate, setStartDate] = useState(
        new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1,
        ).toISOString(),
    );
    const [endDate, setEndDate] = useState(
        new Date(new Date().setDate(new Date().getDate() + 1)).toISOString(),
    );
    const [transactionRef, setTransactionRef] = useState('');
    const [amount, setAmount] = useState('');
    const [sourceAccountNumber, setSourceAccountNumber] = useState('');
    const [destinationAccountNumber, setDestinationAccountNumber] =
        useState('');
    const [status, setStatus] = useState('');
    const [sourceAccountName, setSourceAccountName] = useState('');
    const [applyFilter, setApplyFilter] = useState(false);
    // const [transServ, setTransServ] = useState<any>([]);

    const [loadQuery, setLoadQuery] = useState(false);

    let filters = `?page=${page}&per_page=${limit}`;

    let transactionFilters = `?from=${startDate}&to=${endDate}&transaction_ref=${transactionRef}&amount=${amount}&source_account_no=${sourceAccountNumber}&dest_account_no=${destinationAccountNumber}&status=${status}&source_account_name=${sourceAccountName}`;

    const fetchTransaction = async () => {
        const status = applyFilter ? transactionFilters : filters;
        try {
            setLoad(true);
            let res = await serviceService.getBankTransferTransactions(
                token,
                status,
            );
            setLoad(false);
            if (res?.data) {
                setList(res);
            }
        } catch (err) {
            setLoad(false);
            setList([]);
        }
    };

    const reQuery = async (trans_ref: any, status: any) => {
        if (status === 'Pending' || status === 'In Progress') {
            setLoadQuery(true);
            const payload = {
                admin_user_id: details.id,
                transaction_ref: trans_ref,
            };
            try {
                await serviceService.requery(token, payload, {
                    aes_key: details.aes_key,
                    aes_iv: details.aes_iv,
                });
                toast.success('Requery SuccessFul');
                setLoadQuery(false);
            } catch (err) {
                displayError(err, true);
                setLoadQuery(false);
            }
        } else {
            alert('nice');
        }
    };

    const resetTransaction = () => {
        setPage(1);
        setStartDate(
            new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                1,
            ).toISOString(),
        );
        setEndDate(
            new Date(
                new Date().setDate(new Date().getDate() + 1),
            ).toISOString(),
        );
        setTransactionRef('');
        setAmount('');
        setDestinationAccountNumber('');
        setSourceAccountNumber('');
        setStatus('');
        setSourceAccountName('');
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchTransaction();
    }, [page, limit, applyFilter]);

    const getName = (name: string) => {
        return name.replace(/ /g, '_');
    };

    return (
        <div>
            <BreadCrumbTitle
                title="Bank Transfer Transactions"
                dataCount={list?.total}
            />
            <div className="row mt-3">
                <div className="col-lg-6 d-flex"></div>
                <div className="col-lg-6">
                    <div className="filter">
                        <button
                            className="main-btn"
                            onClick={() => resetTransaction()}>
                            <IoRefreshCircle />
                            <span>Reset</span>
                        </button>
                        {list?.data && (
                            <button className="main-btn secondary">
                                <CSVLink
                                    filename={'Transactions-file.csv'}
                                    data={list?.data}>
                                    <FaFileCsv />
                                    <span>Export CSV</span>
                                </CSVLink>
                            </button>
                        )}
                        <button
                            className="main-btn info"
                            onClick={() => setOpenFilter(true)}>
                            <MdFilterListAlt />
                            <span>Filter</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="basic-card mt-3">
                <div className="head"></div>
                <div className="body">
                    <div className="table-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                        <th>Re-query/Reverse</th>
                                        <th>Transaction Reference</th>
                                        <th>Session Id</th>
                                        <th style={{textAlign: 'center'}}>
                                            Amount
                                        </th>
                                        <th>User Id</th>
                                        <th>Request Id</th>
                                        <th>Narration</th>
                                        <th>Source Account Number</th>
                                        <th>Response Code</th>
                                        <th>Destination Account Number</th>
                                        <th>Destination Account Name</th>
                                        <th>Destination Bank Code</th>
                                        <th>Channel Code</th>
                                        <th>value Given</th>
                                        <th>Source Account Name</th>
                                        <th>Institution Code</th>
                                        <th>Institution Name</th>
                                        <th>Bank Logo</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                {!load && list?.data?.length > 0 && (
                                    <tbody>
                                        {list.data.map((tr: any, i: number) => (
                                            <tr key={tr.id}>
                                                <td>
                                                    {limit * (page - 1) + i + 1}
                                                </td>
                                                <td>
                                                    {dateFormat(
                                                        tr.transactionDate,
                                                        'mmm dd, yyyy | h:MM ss TT',
                                                    )}
                                                </td>
                                                <td>{tr.status}</td>
                                                <td>
                                                    {' '}
                                                    {(tr.status === 'Pending' ||
                                                        tr.status ===
                                                            'In Progress') && (
                                                        <button
                                                            type="button"
                                                            className="btn btn-warning"
                                                            onClick={() =>
                                                                reQuery(
                                                                    tr.transaction_ref,
                                                                    tr.status,
                                                                )
                                                            }
                                                            style={{
                                                                padding:
                                                                    '.2em .5em',
                                                                color: '#fff',
                                                                borderRadius:
                                                                    '5px',
                                                            }}>
                                                            <span>
                                                                re-query
                                                            </span>
                                                        </button>
                                                    )}
                                                </td>
                                                <td
                                                    style={{
                                                        textDecoration:
                                                            'underline',
                                                        cursor: 'pointer',
                                                        color: '#0038ba',
                                                    }}>
                                                    {tr.transaction_ref}
                                                </td>
                                                <td>{tr.session_id}</td>
                                                <td
                                                    className="price"
                                                    style={{
                                                        textAlign: 'center',
                                                    }}>
                                                    {formatCurrency(tr.amount)}
                                                </td>
                                                <td className="link">
                                                    <Link
                                                        to={`/dashboard/customers/${getName(tr.source_account_name)}`}
                                                        state={tr.user_id}>
                                                        {tr.user_id}
                                                    </Link>
                                                </td>
                                                <td>{tr.request_id}</td>
                                                <td>{tr.narration}</td>
                                                <td>{tr.source_account_no}</td>
                                                <td>{tr.response_code}</td>
                                                <td>{tr.dest_account_no}</td>
                                                <td>{tr.dest_account_name}</td>
                                                <td>{tr.dest_bank_code}</td>
                                                <td>{tr.channel_code}</td>

                                                <td>
                                                    <span
                                                        className={
                                                            tr.value_given
                                                                ? 'btn btn-success'
                                                                : 'btn btn-danger'
                                                        }>
                                                        {tr.value_given ===
                                                        false
                                                            ? 'no value given'
                                                            : 'value given'}
                                                    </span>
                                                </td>
                                                <td>
                                                    {tr.source_account_name}
                                                </td>
                                                <td>
                                                    {tr.bank.InstitutionCode}
                                                </td>
                                                <td>
                                                    {tr.bank.InstitutionName}
                                                </td>
                                                <td>
                                                    <img
                                                        src={
                                                            tr.bank.BankLogoUrl
                                                        }
                                                        alt=""
                                                        width="30px"
                                                        height="30px"
                                                        // style={{ borderRadius: "50%" }}
                                                    />
                                                </td>
                                                <td className="link d-flex gap-3">
                                                    <Link
                                                        to={`/dashboard/transactions/${tr.id}/details`}
                                                        state={{id:tr.id, name:'bank-transfer' }}>
                                                        <IoIosEye />
                                                    </Link>
                                                    {tr.additionalInfo && (
                                                        <Link
                                                            to={`/dashboard/transactions/print-receipt`}
                                                            state={tr}>
                                                            Print
                                                        </Link>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        {!load && list?.last_page > 1 && (
                            <Paginate
                                currentPage={page}
                                totalCount={list.total}
                                pageSize={limit}
                                onSelect={(p: number) => setPage(Number(p))}
                                onNext={(p: number) => setPage(p)}
                                onPrev={(p: number) => setPage(p)}
                                changeLimit={(p: string) => setLimit(Number(p))}
                            />
                        )}
                        {load && <LoadTable />}
                    </div>
                </div>
            </div>
            <ModalComponent
                open={openFilter}
                close={() => setOpenFilter(false)}
                size="lg">
                <BankTransfersFilters
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    transactionRef={transactionRef}
                    setTransactionRef={setTransactionRef}
                    amount={amount}
                    setAmount={setAmount}
                    sourceAccountNumber={sourceAccountNumber}
                    setSourceAccountNumber={setSourceAccountNumber}
                    setSourceAccountName={setSourceAccountName}
                    sourceAccountName={sourceAccountName}
                    status={status}
                    setStatus={setStatus}
                    destinationAccountNumber={destinationAccountNumber}
                    setDestinationAccountNumber={setDestinationAccountNumber}
                    filter={() => {
                        setApplyFilter(!applyFilter);
                        setOpenFilter(false);
                    }}
                />
            </ModalComponent>
        </div>
    );
}

export default BankTransactions;
