import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import Paginate from '../../../component/Paginate';
import adminService from '../../../redux/features/admin/adminService';
import LoadTable from '../../../component/Loaders/LoadTable';
import ModalComponent from '../../../component/ModalComponent';
import AddReferral from '../../../component/Referrals/AddReferral';
import SecurityOtp from '../../../component/SecurityOtp';
import {displayError} from '../../../utils/errors';
import PageCover from '../../../component/PageCover';

function Referrals() {
    const {details} = useAppSelector(state => state.auth);

    const [displayModal, setDisplayModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState<any>({});

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);

    const [otp, setOtp] = useState('');
    const [otpModal, setOtpModal] = useState(false);
    const [otpLoad, setOtpLoad] = useState(false);

    //Modal
    const [code, setCode] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [totalUsers, setTotalUsers] = useState('');

    let filters = `?page=${page}&per_page=${limit}`;

    const getReferrals = async () => {
        try {
            setLoading(true);
            let res = await adminService.getReferrals(filters);
            setLoading(false);
            if (res) {
                setList(res);
            }
        } catch (err) {
            setLoading(false);
            setList([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getReferrals();
    }, [page, limit]);

    const handleSubmit = async () => {
        try {
            if (startDate && endDate && totalUsers) {
                let payload = {
                    start_date: startDate,
                    end_date: endDate,
                    total_user: totalUsers,
                    referral_code: code,
                    google_auth_code: otp,
                };
                setOtpLoad(true);
                await adminService.createReferrals(payload, {
                    aes_key: details.aes_key,
                    aes_iv: details.aes_iv,
                });
                setOtpLoad(false);
                setOtpModal(false);
                setOtp('');
                getReferrals();
            } else {
                alert('Please provide required fields');
            }
        } catch (err) {
            displayError(err, true);
            setOtpLoad(false);
            setOtp('');
        }
    };
    return (
        <PageCover page="Referrals">
            <div>
                <BreadCrumbTitle
                    title="Referrals"
                    dataCount={list?.total}
                    button="+ New"
                    buttonClick={() => setDisplayModal(true)}
                />

                <div className="basic-card mt-3">
                    <div className="head"></div>
                    <div className="body">
                        <div className="table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S/n</th>
                                            <th>Referral Code</th>
                                            <th>Total User</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                        </tr>
                                    </thead>
                                    {!loading && list?.data?.length > 0 && (
                                        <tbody>
                                            {list.data.map(
                                                (l: any, i: number) => (
                                                    <tr key={l.id}>
                                                        <td>
                                                            {limit *
                                                                (page - 1) +
                                                                i +
                                                                1}
                                                        </td>
                                                        <td>{l.code}</td>
                                                        <td>{l.total_user}</td>
                                                        <td>{l.start_date}</td>
                                                        <td>{l.end_date}</td>
                                                    </tr>
                                                ),
                                            )}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                            {!loading && list?.last_page > 1 && (
                                <Paginate
                                    currentPage={page}
                                    totalCount={list.total}
                                    pageSize={limit}
                                    onSelect={(p: number) => setPage(Number(p))}
                                    onNext={(p: number) => setPage(p)}
                                    onPrev={(p: number) => setPage(p)}
                                    changeLimit={(p: string) =>
                                        setLimit(Number(p))
                                    }
                                />
                            )}
                            {loading && <LoadTable />}
                        </div>
                    </div>
                </div>
                <ModalComponent
                    title="Add Referral"
                    open={displayModal}
                    close={() => setDisplayModal(false)}>
                    <AddReferral
                        code={code}
                        setCode={setCode}
                        totalUsers={totalUsers}
                        setTotalUsers={setTotalUsers}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        setOtpModal={setOtpModal}
                        close={() => {
                            setDisplayModal(false);
                        }}
                    />
                </ModalComponent>
                <ModalComponent
                    open={otpModal}
                    title="Enter Google Auth Code"
                    close={() => setOtpModal(false)}>
                    <SecurityOtp
                        otp={otp}
                        setOtp={setOtp}
                        load={otpLoad}
                        handleSubmit={() => {
                            handleSubmit();
                        }}
                    />
                </ModalComponent>
            </div>
        </PageCover>
    );
}

export default Referrals;
