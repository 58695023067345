import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import reportService from '../../../redux/features/reports/reportService';
import LoadTable from '../../../component/Loaders/LoadTable';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import PageCover from '../../../component/PageCover';

function CustomerReport() {
    const {token} = useAppSelector(state => state.auth);

    const [loading, setLoading] = useState(false);
    const [list, setList] = useState<any>({});

    let filters = ` `;
    let page = 1;
    let limit = 50;

    const getUsers = async () => {
        try {
            setLoading(true);
            let res = await reportService.getUserReports(token, filters);
            setLoading(false);
            if (res) {
                setList(res);
            }
        } catch (err) {
            setLoading(false);
            setList([]);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getUsers();
    }, []);

    return (
        <PageCover page="Customer Report">
            <div>
                <BreadCrumbTitle
                    title="Customers Report"
                    dataCount={list?.total}
                />

                <div className="basic-card mt-3">
                    <div className="head"></div>
                    <div className="body">
                        <div className="table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Months</th>
                                            <th>Total Referrals</th>
                                            <th>Total Registration</th>
                                            <th>Total Verified Users</th>
                                            <th>Total Un-verfied Users</th>
                                            <th>Registered by BVN</th>
                                            <th>Registered by no BVN</th>
                                            <th>Active Users</th>
                                            <th>Registration on Web</th>
                                            <th>Registration on Mobile</th>
                                        </tr>
                                    </thead>
                                    {!loading && (
                                        <tbody>
                                            {Object.entries(list).map(
                                                ([month, stats]) => {
                                                    const statsData =
                                                        stats as Record<
                                                            string,
                                                            any
                                                        >;

                                                    return (
                                                        <tr key={month}>
                                                            <td>{`${month
                                                                .charAt(0)
                                                                .toUpperCase()}${month.slice(
                                                                1,
                                                            )}`}</td>
                                                            <td>
                                                                {
                                                                    statsData.user_refferal
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    statsData.total_user
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    statsData.verify_user
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    statsData.un_verify_user
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    statsData.user_with_bvn
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    statsData.user_without_bvn
                                                                }
                                                            </td>
                                                            <td>
                                                                {statsData.active_user ??
                                                                    'N/A'}
                                                            </td>
                                                        </tr>
                                                    );
                                                },
                                            )}
                                        </tbody>
                                    )}
                                </table>
                                {loading && <LoadTable />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageCover>
    );
}

export default CustomerReport;
