import {getPayload} from '../../../utils/encrypt';
import {apiRequest} from '../../../utils/axiosInstance';

const fetchBeneficiaries = async () => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_beneficiaries`,
    );
    return data;
};
const addBeneficiaries = async (payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl').post(
        `/transaction/add_beneficiary`,
        passPayload,
    );
    return data.data;
};
const updateBeneficiaries = async (id: string, key: any) => {
    let passPayload = getPayload(key, {}, 'v3a');
    const {data} = await apiRequest('baseUrl').patch(
        `/transaction/toggle_beneficiary/${id}`,
        passPayload,
    );
    return data.data;
};
const deleteBeneficiaries = async (id: string) => {
    const {data} = await apiRequest('baseUrl').delete(
        `/transaction/delete_beneficiary/${id}`,
    );
    return data.data;
};

const beneficiariesService = {
    fetchBeneficiaries,
    updateBeneficiaries,
    deleteBeneficiaries,
    addBeneficiaries,
};

export default beneficiariesService;
