import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import {displayError} from '../../../utils/errors';
import serviceService from '../../../redux/features/service/serviceService';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import LoadTable from '../../../component/Loaders/LoadTable';
import {formatCurrency} from '../../../utils/numberFormat';
import PageCover from '../../../component/PageCover';

function NibbsReport() {
    const {token} = useAppSelector(state => state.auth);
    const [list, setList] = useState<any>([]);
    const [load, setLoad] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const filter = `transfe_credit_summary?startDate=${startDate}&endDate=${endDate}`;

    const getNibbs = async () => {
        if (startDate && endDate) {
            setLoad(true);
            try {
                const res = await serviceService.getNibbsService(token, filter);
                if (res) {
                    setList(res);
                    setLoad(false);
                }
            } catch (err) {
                displayError(err, true);
                setLoad(false);
            }
        } else {
            alert('Enter All Dates');
        }
    };

    const transIn = '5fd87a0848540b16941ecd42';
    const transOut = '5fd8792348540b16941ecd33';
    const defaultAmt = 40000000;

    const trans = list?.report?.map((p: any) => ({
        id: p?._id,
        count: p?.count,
        amount: p?.totalTransactionAmount,
    }));

    const transIdOut = trans?.find(
        (transaction: any) => transaction.id === transOut,
    );
    const transIdIn = trans?.find(
        (transaction: any) => transaction.id === transIn,
    );

    const transOutValue = transIdOut ? transIdOut?.amount : 0;
    const transInValue = transIdIn
        ? defaultAmt + transIdIn?.amount
        : defaultAmt;
    const transDefault = transInValue - defaultAmt;

    const transOutCount = transIdOut ? transIdOut?.count : 0;
    const transInCount = transIdIn ? transIdIn?.count : 0;

    const difference = transInValue - transOutValue;

    const theme =
        difference <= 4000000 || difference < 0
            ? 'red'
            : difference <= 8000000
            ? 'yellow'
            : 'green';

    return (
        <PageCover page="NIBBS Report">
            <div>
                <BreadCrumbTitle title="NIBBS Report" />
                <div className="row mt-4">
                    <div className="d-flex mb-5 justify-content-between align-items-center">
                        <div className="col-lg-6 row">
                            <div className=" basic-input col-lg-6  mb-3">
                                <label className="label">Start Date</label>
                                <input
                                    type="datetime-local"
                                    className="form-control"
                                    required
                                    disabled={load}
                                    value={startDate}
                                    onChange={e => setStartDate(e.target.value)}
                                />
                            </div>
                            <div className="col-lg-6 basic-input  mb-3">
                                <label className="label">End Date</label>
                                <input
                                    type="datetime-local"
                                    className="form-control"
                                    required
                                    disabled={load}
                                    value={endDate}
                                    onChange={e => setEndDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div onClick={getNibbs}>
                            <button type="submit" className="main-btn">
                                Filter
                            </button>
                        </div>
                    </div>
                </div>

                {load && <LoadTable />}
                {!load && list?.report?.length > 0 ? (
                    <div className="dash-stats">
                        <div className="row mb-3">
                            <div className="col-lg-4 mb-3">
                                <div className="box">
                                    <h6 className="d-flex align-items-center">
                                        Transaction Inward before P-Gold
                                        Guarantee
                                        <span className="count">
                                            {transInCount || '0'}
                                        </span>
                                    </h6>
                                    <h4>
                                        ₦{formatCurrency(transDefault) || '00'}
                                    </h4>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-3">
                                <div className="box">
                                    <h6 className="d-flex align-items-center">
                                        Transaction Inward
                                        <span className="count">
                                            {transInCount || '0'}
                                        </span>
                                    </h6>
                                    <h4>
                                        ₦{formatCurrency(transInValue) || '00'}
                                    </h4>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-3">
                                <div className="box">
                                    <h6 className="d-flex align-items-center">
                                        Transaction Outward
                                        <span className="count">
                                            {transOutCount || '0'}
                                        </span>
                                    </h6>
                                    <h4>
                                        ₦{formatCurrency(transOutValue) || '00'}
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 mb-3">
                                <div className="box">
                                    <h6>P-Gold Bank Guarantee</h6>
                                    <h4>₦40,000,000</h4>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-3">
                                <div
                                    className="box"
                                    style={{borderRight: `3px solid ${theme}`}}>
                                    <h6>Total Difference</h6>
                                    <h4 style={{color: theme}}>
                                        ₦{formatCurrency(difference)}
                                    </h4>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-3">
                                <div className="box">
                                    <h6 className="d-flex align-items-center">
                                        NIP mini Transaction Outward
                                        <span className="count">
                                            {list?.debitTransactionNipMini[0]
                                                ?.count || '0'}
                                        </span>
                                    </h6>
                                    <h4>
                                        ₦
                                        {formatCurrency(
                                            list?.debitTransactionNipMini[0]
                                                ?.totalTransactionAmount,
                                        ) || '00'}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        {!load && (
                            <div className=" text-center">No Report Found</div>
                        )}
                    </>
                )}
            </div>
        </PageCover>
    );
}

export default NibbsReport;
