import {authHeader} from '../../../utils/headers';
import {getPayload} from '../../../utils/encrypt';
import {apiRequest} from '../../../utils/axiosInstance';

const loadTransactions = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_all_txns${filters}`,
    );
    return data.data;
};
const transService = async () => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_txn_services`,
    );
    return data.data;
};

const getTransactionDetails = async (id: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_tnx/${id}`,
    );
    return data.data;
};

const updateTransactionStatus = async (payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl').post(
        `/transaction/update_status_multiple`,
        payload,
    );
    return data.data;
};

const reverseTransactionStatus = async (
    payload: any,
    status: any,
    key: any,
) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const state =
        status === 'single'
            ? '/acc_transaction/reverse'
            : '/transfers/reverse_multiple';

    const {data} = await apiRequest('baseUrl').post(`${state}`, passPayload);
    return data.data;
};

const reportTransaction = async (payload: any, key: any, otp: string) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('base').post(
        `https://api.test.getripay.com/api/v3/transaction/review_transaction`,
        payload,
        {
            headers: authHeader(otp),
        },
    );
    return data.data;
};

const transactionService = {
    loadTransactions,
    getTransactionDetails,
    transService,
    updateTransactionStatus,
    reverseTransactionStatus,
    reportTransaction,
};

export default transactionService;
