import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {displayError} from '../../../utils/errors';
import transactionService from '../../../redux/features/transaction/transactionService';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import dateFormat from 'dateformat';
import {formatCurrency} from '../../../utils/numberFormat';
import LoadTable from '../../../component/Loaders/LoadTable';
import {AiOutlineEye} from 'react-icons/ai';
import serviceService from '../../../redux/features/service/serviceService';

function TransactionDetails() {
    const transId = useLocation().state;

    const [id, setId] = useState<any>([]);
    const [load, setLoad] = useState(false);

    const fetchTransactionDetails = async () => {
        setLoad(true);
        try {
            if (transId === 'transactions') {
                const res = await transactionService.getTransactionDetails(
                    transId.id,
                );
                setLoad(false);
                setId(res);
            } else {
                const res = await serviceService.getOneBankTransferTransactions(
                    transId.id,
                );
                setLoad(false);
                setId(res);
            }
        } catch (err) {
            setLoad(false);
            displayError(err, true);
            setId([]);
        }
    };

    useEffect(() => {
        fetchTransactionDetails();
    }, [transId]);

    const badgeColor = (str: string) => {
        if (str) {
            let status = str.toLowerCase();
            if (status.includes('completed')) {
                return 'bg-success';
            } else if (status.includes('failed')) {
                return 'bg-danger';
            } else if (status.includes('pending')) {
                return 'bg-warning text-dark';
            } else if (status.includes('paid')) {
                return 'bg-primary';
            } else {
                return 'bg-info';
            }
        }
        return 'bg-info';
    };

    const returnStatus = (status: string) => {
        let split = status?.split('_');
        return split ? split[1] : status;
    };

    return (
        <div>
            <BreadCrumbTitle title="Transaction Details" />
            {load ? (
                <LoadTable />
            ) : (
                <div className="basic-card">
                    <div className="row  pb-4 m-lg-3 ">
                        <div className="col-lg-6 customer-details">
                            <div className="customer-details-content align-items-center">
                                <span>
                                    <p
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: '12px',
                                            color: 'lightgray',
                                            marginTop: '1em',
                                            marginBottom: '0em',
                                        }}>
                                        Amount
                                    </p>
                                    <p
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                            marginBottom: '0em',
                                        }}>
                                        NGN{' '}
                                        {formatCurrency(
                                            id?.transaction
                                                ?.transactionAmount ||
                                                id?.amount,
                                        )}
                                    </p>
                                </span>
                                <strong>
                                    <span
                                        className={`badge rounded-pill ${badgeColor(
                                            id.transaction?.transactionStatus ||
                                                id?.approval_status,
                                        )}`}>
                                        {returnStatus(
                                            id.transaction?.transactionStatus ||
                                                id?.status,
                                        )}
                                    </span>
                                </strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Reference</span>
                                <strong>
                                    {id.transaction?.transactionRef ||
                                        id?.transaction_ref}
                                </strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Institution Transaction Reference</span>
                                <strong>
                                    {id.transaction?.institutionTxnRef}
                                </strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Channel</span>
                                <strong>
                                    {id.transaction?.channelId ||
                                        id?.channel_code}
                                </strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Description</span>
                                <strong>
                                    {id.transaction?.narration || id?.narration}
                                </strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Transaction type</span>
                                <strong>
                                    {id.transaction?.transactionType}
                                </strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Transaction Category</span>
                                <strong>
                                    {id.transactionCategory
                                        ? id.transaction?.transactionCategory
                                              .name
                                        : id.transaction?.serviceCode}
                                </strong>
                            </div>{' '}
                            <div className="customer-details-content">
                                <span>Customer Type</span>
                                <strong>{'...'}</strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Transaction Date</span>
                                <strong>
                                    {dateFormat(
                                        id.transaction?.transactionDate,
                                    )}
                                </strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Debited Account</span>
                                <strong>
                                    {id.transaction?.debitedAccount ||
                                        id?.source_account_no}
                                </strong>
                            </div>
                            <div className="customer-details-content">
                                <span>User Name</span>
                                <strong>
                                    {id.transaction?.userObject
                                        ? id.transaction?.userObject.username
                                        : id.transaction?.userId ||
                                          id?.source_account_name}
                                </strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Full Name</span>
                                <strong>
                                    {id.transaction?.userObject
                                        ? `${id.transaction?.userObject.first_name} ${id.transaction?.userObject.last_name}`
                                        : id.transaction?.id ||
                                          id?.source_account_name}
                                </strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Email</span>
                                <strong>
                                    {id.transaction?.userObject
                                        ? id.transaction?.userObject.email
                                        : id.transaction?.id || '...'}
                                </strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Recipient Institution Name </span>
                                <strong>
                                    {id.transaction?.recipientInstitutionName ||
                                        id?.bank?.InstitutionName}
                                </strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Recipient Institution Logo</span>
                                <img
                                    src={
                                        id.transaction
                                            ?.recipientInstitutionLogoUrl ||
                                        id?.bank?.BankLogoUrl
                                    }
                                    alt=""
                                    width="40px"
                                    height="40px"
                                />
                            </div>
                            <div className="customer-details-content">
                                <span>Recipient Number</span>
                                <strong>
                                    {id.transaction?.recipientNumber ||
                                        id?.dest_account_no}
                                </strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Recipient Name</span>
                                <strong>
                                    {id?.transaction?.recipientName ||
                                        id?.dest_account_name}
                                </strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Token</span>
                                <strong>
                                    {id.transaction?.token != null
                                        ? id.transaction?.token
                                        : 'not given'}
                                </strong>
                            </div>
                        </div>

                        <div className="col-lg-6 customer-details">
                            <div className="row">
                                {id?.reversalTransaction && (
                                    <div>
                                        <div className="d-flex">
                                            <h5 className="col-lg-6">
                                                Reversed Transactions
                                            </h5>
                                            <div className="col-lg-6 text-center">
                                                <Link
                                                    to={`/dashboard/transactions/${id?.reversalTransaction?._id}/details`}
                                                    state={
                                                        id?.reversalTransaction
                                                            ?._id
                                                    }>
                                                    <button className="main-btn">
                                                        View Details
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <table className="table">
                                                <thead className="">
                                                    <th className="d-flex pl-3">
                                                        Reference
                                                    </th>
                                                    <th>Amount</th>
                                                    <th>Status</th>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {
                                                                id
                                                                    ?.reversalTransaction
                                                                    ?.transactionRef
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                id
                                                                    ?.reversalTransaction
                                                                    ?.transactionAmount
                                                            }
                                                        </td>
                                                        <td>
                                                            {' '}
                                                            <span
                                                                className={`badge rounded-pill ${badgeColor(
                                                                    id
                                                                        ?.reversalTransaction
                                                                        ?.transactionStatus,
                                                                )}`}>
                                                                {returnStatus(
                                                                    id
                                                                        ?.reversalTransaction
                                                                        ?.transactionStatus,
                                                                )}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                                {id?.feeTransaction?._id && (
                                    <div className="mt-5">
                                        <div className="d-flex">
                                            <h5 className="col-lg-6">
                                                Fee Transaction
                                            </h5>
                                            <div className="col-lg-6 text-center">
                                                <button className="main-btn">
                                                    View Details
                                                </button>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <table className="table">
                                                <thead className="">
                                                    <th className="d-flex pl-3">
                                                        Reference
                                                    </th>
                                                    <th>Amount</th>
                                                    <th>Status</th>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {
                                                                id
                                                                    ?.feeTransaction
                                                                    ?.transactionRef
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                id
                                                                    ?.feeTransaction
                                                                    ?.transactionAmount
                                                            }
                                                        </td>
                                                        <td>
                                                            {' '}
                                                            <span
                                                                className={`badge rounded-pill ${badgeColor(
                                                                    id
                                                                        ?.feeTransaction
                                                                        ?.transactionStatus,
                                                                )}`}>
                                                                {returnStatus(
                                                                    id
                                                                        ?.feeTransaction
                                                                        ?.transactionStatus,
                                                                )}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}

                                {id?.childTransactions &&
                                    id?.childTransactions?.length > 0 && (
                                        <div className="mt-5">
                                            <div className="d-flex">
                                                <h5 className="col-lg-6">
                                                    Child transactions
                                                </h5>
                                            </div>
                                            <div className="mt-3">
                                                <table className="table">
                                                    <thead className="">
                                                        <th className="d-flex pl-3">
                                                            Reference
                                                        </th>
                                                        <th>Amount</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </thead>
                                                    {id?.childTransactions &&
                                                        id?.childTransactions
                                                            ?.length > 0 &&
                                                        id?.childTransactions.map(
                                                            (e: any) => (
                                                                <tbody
                                                                    key={e._id}>
                                                                    <tr>
                                                                        <td>
                                                                            {
                                                                                e?.transactionRef
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                e?.transactionAmount
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {' '}
                                                                            <span
                                                                                className={`badge rounded-pill ${badgeColor(
                                                                                    e?.transactionStatus,
                                                                                )}`}>
                                                                                {returnStatus(
                                                                                    e?.transactionStatus,
                                                                                )}
                                                                            </span>
                                                                        </td>
                                                                        <td className="link">
                                                                            <Link
                                                                                to={`/dashboard/transactions/${e?._id}/details`}
                                                                                state={
                                                                                    e?._id
                                                                                }>
                                                                                <AiOutlineEye />
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            ),
                                                        )}
                                                </table>
                                            </div>
                                        </div>
                                    )}

                                {id?.relatedTransactions &&
                                    id?.relatedTransactions?.length > 0 && (
                                        <div className="mt-5">
                                            <div className="d-flex">
                                                <h5 className="col-lg-6">
                                                    Related Transactions
                                                </h5>
                                            </div>
                                            <div className="mt-3">
                                                <table className="table">
                                                    <thead className="">
                                                        <th className="d-flex pl-3">
                                                            Reference
                                                        </th>
                                                        <th>Amount</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </thead>
                                                    {id?.relatedTransactions &&
                                                        id?.relatedTransactions
                                                            ?.length > 0 &&
                                                        id?.relatedTransactions.map(
                                                            (e: any) => (
                                                                <tbody
                                                                    key={e._id}>
                                                                    <tr>
                                                                        <td>
                                                                            {
                                                                                e?.transactionRef
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {formatCurrency(
                                                                                e?.transactionAmount,
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {' '}
                                                                            <span
                                                                                className={`badge rounded-pill ${badgeColor(
                                                                                    e?.transactionStatus,
                                                                                )}`}>
                                                                                {
                                                                                    e?.transactionStatus
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                        <td className="link">
                                                                            <Link
                                                                                to={`/dashboard/transactions/${e?._id}/details`}
                                                                                state={
                                                                                    e?._id
                                                                                }>
                                                                                <AiOutlineEye />
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            ),
                                                        )}
                                                </table>
                                            </div>
                                        </div>
                                    )}

                                {id?.commissionSplits?._id && (
                                    <div className="mt-5">
                                        <div className="d-flex">
                                            <h5 className="col-lg-6">
                                                Commissions
                                            </h5>
                                            <div className="col-lg-6 text-center">
                                                <Link
                                                    to={`/dashboard/transactions/${id?.commissionSplits?._id}/details`}
                                                    state={
                                                        id?.commissionSplits
                                                            ?._id
                                                    }>
                                                    <button className="main-btn">
                                                        View Details
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <table className="table">
                                                <thead className="">
                                                    <th className="d-flex pl-3">
                                                        Reference
                                                    </th>
                                                    <th>Amount</th>
                                                    <th>Status</th>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {
                                                                id
                                                                    ?.commissionSplits
                                                                    ?.transactionRef
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                id
                                                                    ?.commissionSplits
                                                                    ?.transactionAmount
                                                            }
                                                        </td>
                                                        <td>
                                                            {' '}
                                                            <span
                                                                className={`badge rounded-pill ${badgeColor(
                                                                    id
                                                                        ?.commissionSplits
                                                                        ?.transactionStatus,
                                                                )}`}>
                                                                {returnStatus(
                                                                    id
                                                                        ?.commissionSplits
                                                                        ?.transactionStatus,
                                                                )}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default TransactionDetails;
