import {apiRequest} from '../../../utils/axiosInstance';

const getWalletHistories = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/cards/wallet_histories${filters}`,
    );
    return data.data;
};

const walletServices = {
    getWalletHistories,
};

export default walletServices;
