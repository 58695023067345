import React from 'react';
import {SelectInput} from '../BasicInputs';

function ApproveTerminalModal({
    agents,
    catId,
    setCatId,
    click,
    loading,
}: {
    agents: any;
    catId: string;
    loading: boolean;
    click: () => void;
    setCatId: (arg: string) => void;
}) {
    return (
        <div className="row">
            <div className="col-lg- mb-3">
                <SelectInput
                    option={
                        agents &&
                        agents?.length > 0 &&
                        agents?.map((i: any) => ({
                            label: i.name,
                            value: i.id,
                        }))
                    }
                    val={catId}
                    changeVal={setCatId}
                    label="Select Agent Category"
                    placeholder="Choose One"
                    required
                />
            </div>
            <div className="col-lg-12 text-center">
                <button className="main-btn" onClick={click}>
                    {loading ? 'Please wait...' : 'Assign'}
                </button>
            </div>
        </div>
    );
}

export default ApproveTerminalModal;
