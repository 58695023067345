import React, {useEffect, useState} from 'react';
import terminalService from '../../../redux/features/terminals/terminalService';
import {useAppSelector} from '../../../redux/hooks';
import {displayError} from '../../../utils/errors';
import LoadTable from '../../../component/Loaders/LoadTable';
import Paginate from '../../../component/Paginate';
import {SearchInput} from '../../../component/BasicInputs';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import ModalComponent from '../../../component/ModalComponent';
import Features from '../../../component/Terminals/Features';
import {UseDebounce} from '../../../utils/hooks';
import PageCover from '../../../component/PageCover';

function AgentTerminals() {
    //Filters
    const [search, setSearch] = useState('');
    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});
    const {token, details: data} = useAppSelector(state => state.auth);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [details, setDetails] = useState<any>({});
    //features
    const [open, setOpen] = useState(false);

    const filters = `?page=${
        page - 1
    }&userType=admin&size=${limit}&search=${search}`;

    const debouncedSearch = UseDebounce(search);

    const fetchAgentTerminals = async () => {
        try {
            setLoad(true);
            const res = await terminalService.AgentTerminals(token, filters);
            setLoad(false);
            setList(res);
        } catch (err) {
            setLoad(false);
            setList([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        fetchAgentTerminals();
    }, [filters, debouncedSearch]);

    const withdrawal = async (e: any, detail: any) => {
        e.preventDefault();
        if (
            window.confirm(
                'Are you sure want to proceed? This will stop an agent from using the terminal.',
            )
        ) {
            let payload = {
                agentId: detail.agentId,
                terminalId: detail.terminalId,
            };
            try {
                setLoad(true);
                const res = await terminalService.setWithdraw(token, payload);
                if (res) {
                    setLoad(false);
                }
            } catch (err) {
                setLoad(false);
                displayError(err, true);
            }
        }
    };

    return (
        <PageCover page="Agent Terminals">
            <div>
                <BreadCrumbTitle
                    title="Agent Categories"
                    dataCount={list?.totalElements}
                />
                <div className="row mt-3">
                    <div className="col-lg-6">
                        <SearchInput val={search} changeVal={setSearch} />
                    </div>
                </div>
                <div className="basic-card mt-3">
                    <div className="head"></div>
                    <div className="body">
                        <div className="table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Agent</th>
                                            <th>Super Agent In Charge</th>
                                            <th>Terminal Serial No</th>
                                            <th>Terminal Type</th>
                                            <th>Account Number</th>
                                            <th>Location</th>
                                            <th>Features</th>
                                        </tr>
                                    </thead>
                                    {!load && list?.content?.length > 0 && (
                                        <tbody>
                                            {list.content.map(
                                                (tr: any, i: number) => (
                                                    <tr key={tr.terminalId}>
                                                        <td>
                                                            {tr.agent &&
                                                                tr.agent
                                                                    .businessName}
                                                        </td>
                                                        <td>
                                                            {tr?.superAgent
                                                                ?.businessName ||
                                                                '---'}
                                                        </td>
                                                        <td>
                                                            {tr.terminal &&
                                                                tr.terminal
                                                                    .serialNumber}
                                                        </td>
                                                        <td>
                                                            {tr.terminal &&
                                                                tr.terminal.os}
                                                        </td>
                                                        <td>
                                                            {tr.accountNumber}
                                                        </td>
                                                        <td>{tr.location}</td>
                                                        <td>
                                                            <span
                                                                role="button"
                                                                onClick={() => {
                                                                    setOpen(
                                                                        true,
                                                                    );
                                                                    setDetails(
                                                                        tr,
                                                                    );
                                                                }}
                                                                className="pr-3 text-primary"
                                                                style={{
                                                                    borderRight:
                                                                        '1px solid',
                                                                    paddingRight:
                                                                        '15px',
                                                                }}>
                                                                Features
                                                            </span>
                                                            <span
                                                                onClick={e =>
                                                                    withdrawal(
                                                                        e,
                                                                        tr,
                                                                    )
                                                                }
                                                                role="button"
                                                                className="pl-3 link"
                                                                style={{
                                                                    paddingLeft:
                                                                        '15px',
                                                                }}>
                                                                Withdraw
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ),
                                            )}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                            {!load && list?.totalPages > 1 && (
                                <Paginate
                                    currentPage={page}
                                    totalCount={list.totalElements}
                                    pageSize={limit}
                                    lastPage={list.totalPages}
                                    onSelect={(p: number) => setPage(Number(p))}
                                    onNext={(p: number) => setPage(p)}
                                    onPrev={(p: number) => setPage(p)}
                                    changeLimit={(p: string) =>
                                        setLimit(Number(p))
                                    }
                                />
                            )}
                            {load && <LoadTable />}
                        </div>
                    </div>
                </div>
                <ModalComponent
                    open={open}
                    close={() => setOpen(false)}
                    title="Terminal Features">
                    <Features
                        features={details.disableFeature}
                        terminalId={details.terminalId}
                        refresh={() => {
                            fetchAgentTerminals();
                            setOpen(false);
                        }}
                    />
                </ModalComponent>
            </div>
        </PageCover>
    );
}

export default AgentTerminals;
