import React from 'react';
import {TextInput, SelectInput} from '../BasicInputs';

function ReportTransaction({
    reason,
    setReason,
    activity,
    setActivity,
    close,
    setStateName,
    conditions,
    condition,
    setCondition,
}: {
    reason: string;
    setReason: (arg: string) => void;
    activity: string;
    setActivity: (arg: string) => void;
    setStateName: (arg: string) => void;
    close: () => void;
    conditions: string[];
    condition: any;
    setCondition: (arg: any) => void;
}) {
    const handleRadioChange = (id: number | string) => {
        setCondition((prevSelectedIds: (number | string)[]) => {
            return prevSelectedIds.includes(id)
                ? prevSelectedIds.filter(selectedId => selectedId !== id)
                : [...prevSelectedIds, id];
        });
    };
    return (
        <div className="row">
            <div className="col-lg-12 mb-3">
                <SelectInput
                    option={[
                        {label: 'Suspicious', value: 'suspicious'},
                        {label: 'BlackList', value: 'blacklist'},
                        {label: 'Okay', value: 'okay'},
                    ]}
                    val={activity}
                    changeVal={setActivity}
                    label="Activity"
                />
            </div>
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={reason}
                    changeVal={setReason}
                    placeholder="Enter Reason"
                    label="Reason..."
                />
            </div>
            <div className="col-lg-12 mb-3">
                <label>Condition</label>
            </div>

            <div className="col-lg-12 mb-3">
                {conditions.map((t: any) => (
                    <div
                        key={t}
                        style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            height: '25px',
                        }}>
                        <input
                            type="checkbox"
                            checked={
                                condition.find((s: any) => s === t)
                                    ? true
                                    : false
                            }
                            onChange={e => {
                                handleRadioChange(t);
                            }}
                        />
                        <span>{t}</span>
                    </div>
                ))}
            </div>
            <div className="col-lg-12 text-center">
                <button
                    onClick={() => {
                        setStateName('otp');
                    }}
                    className="main-btn">
                    {'Report Transaction'}
                </button>
            </div>
        </div>
    );
}

export default ReportTransaction;
