import dateFormat from 'dateformat';
import React from 'react';

function CorporateBankAccounts({list}: {list: any}) {
    const page = 1;
    const limit = 20;
    return (
        <div>
            <div className="table-body">
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>S/N</th>
                                <th>Account Name</th>
                                <th>Account Number</th>
                                <th>Account Type</th>
                                <th>Created At</th>
                            </tr>
                        </thead>
                        {list?.length > 0 && (
                            <tbody>
                                {list?.map((tr: any, i: number) => (
                                    <tr key={tr?.id}>
                                        <td> {limit * (page - 1) + i + 1}</td>

                                        <td>{tr.account_name}</td>
                                        <td>{tr.account_number}</td>
                                        <td>{tr.account_type}</td>
                                        <td>{dateFormat(tr?.created_at)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </div>
    );
}

export default CorporateBankAccounts;
