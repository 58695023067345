import React, {useState} from 'react';
import dateFormat from 'dateformat';
import ModalComponent from '../../ModalComponent';
import SecurityOtp from '../../SecurityOtp';
import {displayError} from '../../../utils/errors';
import {toast} from 'react-toastify';
import KYCService from '../../../redux/features/kyc/kycService';
import {useAppSelector} from '../../../redux/hooks';

const Documents = ({documents, call}: {documents: any; call: () => void}) => {
    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    const page = 1;
    const limit = 50;

    const {details} = useAppSelector(state => state.auth);

    const [otp, setOtp] = useState('');
    const [otpLoad, setOtpLoad] = useState(false);
    const [openOtp, setOpenOtp] = useState(false);
    const [data, setData] = useState<any>({});

    const approveId = async (id: any, user: string, comment: string) => {
        const payload = {
            user_id: user,
            approved_by: details.id,
            user_document_id: id,
            comment,
        };
        setOtpLoad(true);
        try {
            await KYCService.approveRequest(
                
                payload,
                {
                    aes_key: details.aes_key,
                    aes_iv: details.aes_iv,
                },
                comment,
                otp,
            );
            setOtpLoad(false);
            toast.success(comment);
            setOtp('');
            setOpenOtp(false);
            call();
        } catch (err) {
            setOtpLoad(false);
            displayError(err, true);
            setOtp('');
        }
    };
    return (
        <div>
            <div className="body">
                <div className="table-body">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>S/N</th>
                                    <th>Customer Name</th>
                                    <th>Document Name</th>
                                    <th>Document URL</th>
                                    <th>Approved By</th>
                                    <th>Document Status</th>
                                    <th>Comment</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            {documents?.length > 0 && (
                                <tbody>
                                    {documents.map((props: any, i: any) => (
                                        <tr key={props.id}>
                                            <td>
                                                {' '}
                                                {limit * (page - 1) + i + 1}
                                            </td>
                                            <td>
                                                {props.user.first_name.toUpperCase()}{' '}
                                                {props?.user?.last_name.toUpperCase()}
                                            </td>
                                            <td>
                                                {props?.document_url &&
                                                    props?.document?.name}
                                            </td>
                                            <td>
                                                {props?.document_url &&
                                                props?.document_url.substr(
                                                    0,
                                                    4,
                                                ) === 'http' ? (
                                                    <button
                                                        onClick={() =>
                                                            openInNewTab(
                                                                props?.document_url,
                                                            )
                                                        }
                                                        className="btn btn-primary">
                                                        View
                                                    </button>
                                                ) : props?.document_url &&
                                                  props?.document?.name ===
                                                      'BVN' ? (
                                                    <p>
                                                        {props?.document_url &&
                                                            props?.document_url.substr(
                                                                0,
                                                                4,
                                                            )}
                                                        *******
                                                    </p>
                                                ) : (
                                                    <p>
                                                        {props?.document_url &&
                                                            props?.document_url}
                                                    </p>
                                                )}
                                            </td>
                                            <td>
                                                {
                                                    props?.approval_details
                                                        ?.username
                                                }
                                                {/* {props?.approval_details?.first_name} {props?.approval_details?.last_name} */}
                                            </td>
                                            <td>
                                                {props?.approval_status.toUpperCase()}
                                            </td>
                                            <td>{props?.comment}</td>
                                            <td>
                                                {dateFormat(props?.created_at)}
                                            </td>
                                            <td className="d-flex gap-2">
                                                {props?.approval_status ===
                                                'Pending' ? (
                                                    <>
                                                        <button
                                                            onClick={() => {
                                                                setData({
                                                                    id: props?.id,
                                                                    user: props?.user_id,
                                                                    state: 'approve',
                                                                });
                                                                setOpenOtp(
                                                                    true,
                                                                );
                                                            }}
                                                            type="button"
                                                            className="btn btn-primary">
                                                            Accept
                                                        </button>
                                                        <button
                                                            onClick={() => {
                                                                setData({
                                                                    id: props?.id,
                                                                    user: props?.user_id,
                                                                    state: 'reject',
                                                                });
                                                                setOpenOtp(
                                                                    true,
                                                                );
                                                            }}
                                                            type="button"
                                                            className="btn btn-danger">
                                                            Reject
                                                        </button>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            </div>
            <ModalComponent
                title="Enter your Otp"
                open={openOtp}
                close={() => setOpenOtp(false)}>
                <SecurityOtp
                    otp={otp}
                    setOtp={setOtp}
                    handleSubmit={() =>
                        approveId(data?.id, data?.user, data?.state)
                    }
                    load={otpLoad}
                />
            </ModalComponent>
        </div>
    );
};

export default Documents;
