import axios from 'axios';
import {apiRequest} from '../../../utils/axiosInstance';
import {authHeader} from '../../../utils/headers';
import config from '../../../utils/config';

const cardSettings = async (token: string) => {
    const {data} = await apiRequest('posUrl2').get(`/card_settings`);
    return data.data;
};

const cardChannels = async (token: string) => {
    const {data} = await apiRequest('posUrl2').get(`/card_channels`);
    return data.data;
};

const bankChannels = async (token: string) => {
    const {data} = await apiRequest('posUrl2').get(`/bank_channels`);
    return data.data;
};

const bankBins = async (token: string) => {
    const {data} = await apiRequest('posUrl2').get(`/bank_bins`);
    return data.data;
};

const updateCardSettings = async (token: string, id: number, obj: any) => {
    const {data} = await apiRequest('posUrl2').put(`/card_settings/${id}`, obj);
    return data.data;
};

const updateBankChannels = async (token: string, id: number, obj: any) => {
    const {data} = await apiRequest('posUrl2').put(`/bank_channels/${id}`,obj)

    return data.data;
};

const updateCardChannels = async (token: string, id: number, obj: any) => {
    const {data} = await apiRequest('posUrl2').put(`/card_channels/${id}`,
        obj,
    );
    return data.data;
};

const updateBankBin = async (token: string, id: number, obj: any) => {
    const {data} = await apiRequest('posUrl2').put(`/bank_bins/${id}`,
        obj,
    );
    return data.data;
};

const posService = {
    cardSettings,
    cardChannels,
    bankChannels,
    bankBins,
    updateCardSettings,
    updateBankChannels,
    updateCardChannels,
    updateBankBin
};

export default posService;
