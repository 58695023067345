import React, {useEffect, useState} from 'react';
import {displayError} from '../../../utils/errors';
import savingsService from '../../../redux/features/savings/savingsService';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import {SearchInput} from '../../../component/BasicInputs';
import LoadTable from '../../../component/Loaders/LoadTable';
import Paginate from '../../../component/Paginate';
import dateFormat from 'dateformat';
import {GoPencil} from 'react-icons/go';
import ModalComponent from '../../../component/ModalComponent';
import EditSavingsPenality from '../../../component/savings/EditSavingsPenality';
import PageCover from '../../../component/PageCover';

function SavingsPenality() {
    // PENALITY
    const [amount, setAmount] = useState('20');
    const [amountType, setAmountType] = useState('');
    const [capAmount, setCapAmount] = useState('');
    const [capBase, setCapBase] = useState('');

    //filters
    const [list, setList] = useState<any>([]);
    const [load, setLoad] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [open, setOpen] = useState(false);

    const filters = '';

    const fetchSavingsWallet = async () => {
        try {
            setLoad(true);
            const res = await savingsService.getSavingsPenality();
            setLoad(false);
            setList(res);
        } catch (err) {
            setLoad(false);
            setList([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        fetchSavingsWallet();
    }, [filters]);

    return (
        <PageCover page="Savings Penality">
            <div>
                <div>
                    <BreadCrumbTitle
                        title="Savings Penality"
                        dataCount={list?.totalElement}
                        button="Add"
                        buttonClick={() => setOpen(true)}
                    />
                    <div className="row">
                        <div className="col-lg-6 mt-3">
                            <SearchInput val={search} changeVal={setSearch} />
                        </div>
                    </div>
                </div>

                <div className="basic-card mt-3">
                    <div className="head"></div>
                    <div className="body">
                        <div className="table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Amount</th>
                                            <th>Amount type</th>
                                            <th>Cap anount</th>
                                            <th>cap base</th>
                                            <th>created at</th>
                                            <th>action</th>
                                        </tr>
                                    </thead>
                                    {!load && list?.length > 0 && (
                                        <tbody>
                                            {list.map((tr: any, i: number) => (
                                                <tr key={tr.id}>
                                                    <td>
                                                        {limit * (page - 1) +
                                                            i +
                                                            1}
                                                    </td>
                                                    <td>{tr.amount}</td>
                                                    <td>{tr.amount_type}</td>
                                                    <td>{}</td>
                                                    <td>{}</td>
                                                    <td>
                                                        {dateFormat(
                                                            tr.created_at,
                                                        )}
                                                    </td>
                                                    <td>
                                                        <span
                                                            className=""
                                                            onClick={() =>
                                                                setOpen(true)
                                                            }>
                                                            <GoPencil />
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                            {!load && list?.totalPages > 1 && (
                                <Paginate
                                    currentPage={page}
                                    totalCount={list.totalElements}
                                    pageSize={limit}
                                    lastPage={list.totalPages}
                                    onSelect={(p: number) => setPage(Number(p))}
                                    onNext={(p: number) => setPage(p)}
                                    onPrev={(p: number) => setPage(p)}
                                    changeLimit={(p: string) =>
                                        setLimit(Number(p))
                                    }
                                />
                            )}
                            {load && <LoadTable />}
                        </div>
                    </div>
                </div>
                <ModalComponent
                    open={open}
                    close={() => setOpen(false)}
                    title="Savings penality">
                    <EditSavingsPenality
                        amount={amount}
                        setAmount={setAmount}
                        amountType={amountType}
                        setAmountType={setAmountType}
                        capBase={capBase}
                        setCapBase={setCapBase}
                        capAmount={capAmount}
                        setCapAmount={setCapAmount}
                    />
                </ModalComponent>
            </div>
        </PageCover>
    );
}

export default SavingsPenality;
