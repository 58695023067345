import React from 'react';

function ApproveCustomer({details}: {details: any}) {
    return (
        <div className="row">
            <div className="col-lg-6">
              <h4>New Data</h4>
                {Object.entries(details.new_data).map(([key, value]: any) => (
                    <div className="d-flex">
                        <p>{key}</p>:{'  '}
                        <strong>{value}</strong>
                    </div>
                ))}
            </div>
            <div className="col-lg-6">
            <h4>Old Data</h4>
                {Object.entries(details.old_data).map(([key, value]: any) => {
                    if (
                        key === 'id' ||
                        key === 'hasSetupGA' ||
                        key === 'profileUpdateRequired' ||
                        typeof value === 'object'
                    ) {
                        return null;
                    }
                    return (
                        <div className="d-flex">
                            <p>{key}</p>: <strong>{value}</strong>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default ApproveCustomer;
