import React from 'react';
import {useAppSelector} from '../redux/hooks';
import {roles} from '../utils/roles';
import {Navigate} from 'react-router-dom';

const PageCover = ({
    children,
    page,
    isPage,
    button,
}: {
    children: React.ReactNode;
    page: string;
    isPage?: boolean;
    button?: boolean;
}) => {
    const {details} = useAppSelector(state => state.auth);

    const getRole = roles.find(role => role.page === page);

    return (
        <div>
            {getRole?.roles.includes(details.role_id) ? (
                <>{children}</>
            ) : isPage ? (
                <Navigate to={'/'} />
            ) : button ? (
                <></>
            ) : (
                <div className="mt-5 text-center">
                    <button className="main-btn danger">
                        You don't have permission to view this page
                    </button>
                </div>
            )}
        </div>
    );
};

export default PageCover;
