import {apiRequest} from '../../../utils/axiosInstance';

const cardSettings = async () => {
    const {data} = await apiRequest('posUrl2').get(`/card_settings`);
    return data.data;
};

const cardChannels = async () => {
    const {data} = await apiRequest('posUrl2').get(`/card_channels`);
    return data.data;
};

const bankChannels = async () => {
    const {data} = await apiRequest('posUrl2').get(`/bank_channels`);
    return data.data;
};

const bankBins = async () => {
    const {data} = await apiRequest('posUrl2').get(`/bank_bins`);
    return data.data;
};

const updateCardSettings = async (id: number, obj: any) => {
    const {data} = await apiRequest('posUrl2').put(`/card_settings/${id}`, obj);
    return data.data;
};

const updateBankChannels = async (id: number, obj: any) => {
    const {data} = await apiRequest('posUrl2').put(`/bank_channels/${id}`, obj);

    return data.data;
};

const updateCardChannels = async (id: number, obj: any) => {
    const {data} = await apiRequest('posUrl2').put(`/card_channels/${id}`, obj);
    return data.data;
};

const updateBankBin = async (id: number, obj: any) => {
    const {data} = await apiRequest('posUrl2').put(`/bank_bins/${id}`, obj);
    return data.data;
};

const posService = {
    cardSettings,
    cardChannels,
    bankChannels,
    bankBins,
    updateCardSettings,
    updateBankChannels,
    updateCardChannels,
    updateBankBin,
};

export default posService;
