import React from 'react';
import dateFormat from 'dateformat';

function CorporateProfiles({list}: {list: any}) {
    const page = 1;
    const limit = 20;

    return (
        <div>
            <div className="table-body">
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>S/N</th>
                                <th>Name</th>
                                <th>phoneNumber</th>
                                <th>email</th>
                                <th>Created At</th>
                            </tr>
                        </thead>
                        {list && (
                            <tbody>
                                {list?.map((tr: any, i: number) => (
                                    <tr key={tr?.id}>
                                        <td> {limit * (page - 1) + i + 1}</td>

                                        <td>
                                            {tr.user?.first_name}{' '}
                                            {tr.user?.last_name}
                                        </td>
                                        <td>{tr.user?.phone_number}</td>

                                        <td> {tr.user?.email}</td>
                                        <td>{dateFormat(tr?.created_at)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </div>
    );
}

export default CorporateProfiles;
