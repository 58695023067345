import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import agentService from '../../../redux/features/agent/agentService';
import {displayError} from '../../../utils/errors';
import Paginate from '../../../component/Paginate';
import LoadTable from '../../../component/Loaders/LoadTable';
import {Link, useNavigate} from 'react-router-dom';
import {IoIosEye} from 'react-icons/io';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import {GoPencil} from 'react-icons/go';
import ModalComponent from '../../../component/ModalComponent';
import ViewAgent from '../../../component/Agents/ViewAgent';
import AssignPos from '../../../component/Agents/AssignPos';
import dateFormat from 'dateformat';
import PageCover from '../../../component/PageCover';

function AllAgents() {
    const navigate = useNavigate();

    //Assign POS
    const [machine, setMachine] = useState('');
    const [location, setLocation] = useState<any>('');
    const [city, setCity] = useState<any>('');
    const [state, setState] = useState<any>('');
    const [viewPos, setViewPos] = useState(false);
    const [det, setDet] = useState<any>({});

    //filters
    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});
    const {token,details:data} = useAppSelector(state => state.auth);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(15);

    //Details
    const [details, setDetails] = useState<any>(null);
    const [view, setView] = useState(false);

    const filters = `?page=${page - 1}&size=${limit}&userType=admin`;

    const fetchAllAgents = async () => {
        try {
            setLoad(true);
            const res = await agentService.AllAgents(token, filters);
            setLoad(false);
            setList(res);
        } catch (err) {
            setLoad(false);
            setList([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchAllAgents();
    }, [filters]);

    const assignHandler = async (e: any, id: any, type: any) => {
        e.preventDefault();
        if (window.confirm('Are you sure want to proceed?')) {
            try {
                await agentService.agentActivation(token, id, type);
                fetchAllAgents();
            } catch (err) {
                displayError(err, true);
                fetchAllAgents();
            }
        }
    };
    const roles = data?.role_id;
    return (
        <PageCover page="All Agents">
        <div>
            <BreadCrumbTitle
                title="All Agents"
                dataCount={list?.totalElements}
                button="Add + "
                buttonClick={() =>
                    navigate('/dashboard/agents/new') }
            />

            <div className="basic-card mt-3">
                <div className="head"></div>
                <div className="body">
                    <div className="table-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Name</th>
                                        <th>Business Name</th>
                                        <th>Email</th>
                                        <th>Mobile </th>
                                        <th>Status </th>
                                        <th>Date Created</th>
                                        <th>More </th>
                                    </tr>
                                </thead>
                                {!load && list?.content?.length > 0 && (
                                    <tbody>
                                        {list.content.map(
                                            (tr: any, i: number) => (
                                                <tr key={tr.id}>
                                                    <td>
                                                        {' '}
                                                        {limit * (page - 1) +
                                                            i +
                                                            1}
                                                    </td>

                                                    <td>{` ${tr.lastName}`}</td>
                                                    <td>{tr.businessName}</td>
                                                    <td>{tr.email}</td>
                                                    <td>{tr.phone}</td>
                                                    <td>{tr.status}</td>
                                                    <td>
                                                        {dateFormat(
                                                            tr.createdAt,
                                                            'mmm dd, yyyy | h:MM TT',
                                                        )}
                                                    </td>
                                                    <td className="d-flex text-primary gap-3">
                                                        <span
                                                            role="button"
                                                            onClick={() => {
                                                                setDetails(tr);
                                                                setView(true);
                                                            }}>
                                                            <IoIosEye />
                                                        </span>
                                                        {(roles === '1' ||
                                                            roles === '2') && (
                                                            <Link
                                                                to={`/dashboard/agents/${tr.id}`}
                                                                state={tr}>
                                                                <GoPencil />
                                                            </Link>
                                                        )}

                                                        <span
                                                            onClick={() => {
                                                                setViewPos(
                                                                    true,
                                                                );
                                                                setDet(tr);
                                                            }}
                                                            role="button"
                                                            className="text-primary">
                                                            {' '}
                                                            Assign Pos
                                                        </span>
                                                        <span
                                                            onClick={e =>
                                                                assignHandler(
                                                                    e,
                                                                    tr.id,
                                                                    tr.status,
                                                                )
                                                            }
                                                            role="button"
                                                            className="text-primary">
                                                            {tr.status ===
                                                            'Active'
                                                                ? 'Deactivate'
                                                                : 'Activate'}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        {!load && list?.totalPages > 1 && (
                            <Paginate
                                currentPage={page}
                                totalCount={list.totalElements}
                                pageSize={limit}
                                lastPage={list.totalPages}
                                onSelect={(p: number) => setPage(Number(p))}
                                onNext={(p: number) => setPage(p)}
                                onPrev={(p: number) => setPage(p)}
                                changeLimit={(p: string) => setLimit(Number(p))}
                            />
                        )}
                        {load && <LoadTable />}
                    </div>
                </div>
            </div>
            <ModalComponent
                open={view}
                title="Agent Details"
                close={() => setView(false)}>
                <ViewAgent {...details} />
            </ModalComponent>

            <ModalComponent
                open={viewPos}
                title="Assign POS"
                close={() => setViewPos(false)}>
                <AssignPos
                    details={det}
                    machine={machine}
                    setMachine={setMachine}
                    location={location}
                    setLocation={setLocation}
                    city={city}
                    setCity={setCity}
                    state={state}
                    setState={setState}
                    close={() => {
                        setViewPos(false);
                        fetchAllAgents();
                    }}
                />
            </ModalComponent>
        </div></PageCover>
    );
}
export default AllAgents;
