
import {getPayload} from '../../../utils/encrypt';
import {apiRequest} from '../../../utils/axiosInstance';

const getBankTransferTransactions = async (filter: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/bank_transfer_transactions${filter}`,
    );
    return data.data;
};
const getOneBankTransferTransactions = async (id: number) => {
    const {data} = await apiRequest('baseUrl').get(
        `/bank_transfer_transactions/${id}`,
    );
    return data.data;
};

const getBankTransferTransactionsFilter = async (filter: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/bank_transfer_transactions${filter}`,
    );
    return data.data;
};

const getBillTransactions = async (filter: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/biller/biller_transaction${filter}`,
    );
    return data;
};

const getServiceCode = async (filter: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/biller/switch_codes${filter}`,
    );
    return data.data;
};

const getProviders = async (filter: any) => {
    const {data} = await apiRequest('baseUrl').get(`/biller/providers`);
    return data;
};

const updateServiceCode = async (id: number, payload: any) => {
    const {data} = await apiRequest('baseUrl').put(
        `/biller/switch_codes/${id}`,
        payload,
    );
    return data.data;
};

const getNibbsService = async (filter: any) => {
    const {data} = await apiRequest('baseUrl').get(`/transaction/${filter}`);
    return data.data;
};
const requery = async (payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl').post(
        `/transfers/requery_transaction
`,
        passPayload,
    );
    return data.data;
};

const serviceService = {
    getBankTransferTransactions,
    getBankTransferTransactionsFilter,
    getBillTransactions,
    updateServiceCode,
    getNibbsService,
    getProviders,
    getServiceCode,
    requery,
    getOneBankTransferTransactions,
};

export default serviceService;
