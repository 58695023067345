import React, {useEffect, useState} from 'react';
import reportService from '../../../redux/features/reports/reportService';
import {displayError} from '../../../utils/errors';
import dateFormat from 'dateformat';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import LoadTable from '../../../component/Loaders/LoadTable';
import {DateSelect} from '../../../component/BasicInputs';
import {formatCurrency} from '../../../utils/numberFormat';
import PageCover from '../../../component/PageCover';

export function InstitutionReport() {
    const [startDate, setStartDate] = useState(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    );
    const [endDate, setEndDate] = useState(
        new Date(new Date().setDate(new Date().getDate() + 1)),
    );

    const [institution, setInstitution] = useState<any>([]);

    const [load, setLoad] = useState(false);

    const filter = `?startDate=${dateFormat(
        startDate,
        'yyyy-mm-dd',
    )}&endDate=${dateFormat(endDate, 'yyyy-mm-dd')}`;

    // const filter = `?startDate=2022-01-01&endDate=2024-11-21`;

    const getInstitutionReport = async () => {
        setLoad(true);
        try {
            const res = await reportService.getInstitutionReport(filter);
            if (res) {
                setInstitution(res);
            }
            setLoad(false);
        } catch (err) {
            displayError(err, true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getInstitutionReport();
    }, [filter]);

    return (
        <PageCover page="Institution Report">
            <div>
                <BreadCrumbTitle title="Institution Report" />
                <div className="row">
                    <div className="col-md-6 mb-3"></div>
                    <div className="col-md-6 mb-3">
                        <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                                {' '}
                                <DateSelect
                                    dateVal={startDate}
                                    changeDateVal={setStartDate}
                                    label="Start Date"
                                />
                            </div>
                            <div className="col-md-4">
                                {' '}
                                <DateSelect
                                    dateVal={endDate}
                                    changeDateVal={setEndDate}
                                    label="End Date"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="basic-card mt-3">
                    <div className="head"></div>
                    <div className="body">
                        <div className="table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Institution</th>
                                            <th style={{color: 'green'}}>
                                                Completed
                                            </th>
                                            <th style={{color: 'red'}}>
                                                Failed
                                            </th>
                                            <th style={{color: 'yellow'}}>
                                                Pending
                                            </th>
                                        </tr>
                                    </thead>
                                    {!load && (
                                        <tbody>
                                            {institution?.transactionsArr?.map(
                                                (tr: any) => {
                                                    const statusAmounts: {
                                                        [key: string]: {
                                                            count: number;
                                                            amount: number;
                                                        };
                                                    } = {
                                                        TS_COMPLETED: {
                                                            count: 0,
                                                            amount: 0,
                                                        },
                                                        TS_FAILED: {
                                                            count: 0,
                                                            amount: 0,
                                                        },
                                                        TS_PENDING: {
                                                            count: 0,
                                                            amount: 0,
                                                        },
                                                    };

                                                    tr?.statusTransactions?.forEach(
                                                        (er: any) => {
                                                            const status =
                                                                er?.status as keyof typeof statusAmounts;
                                                            if (
                                                                status in
                                                                statusAmounts
                                                            ) {
                                                                statusAmounts[
                                                                    status
                                                                ].count +=
                                                                    er.count;
                                                                statusAmounts[
                                                                    status
                                                                ].amount +=
                                                                    er.amount;
                                                            }
                                                        },
                                                    );

                                                    const success =
                                                        statusAmounts
                                                            .TS_COMPLETED.count;
                                                    const failed =
                                                        statusAmounts.TS_FAILED
                                                            .count;
                                                    const total =
                                                        success + failed;
                                                    const successPercentage =
                                                        total > 0
                                                            ? (
                                                                  (success /
                                                                      total) *
                                                                  100
                                                              ).toFixed(2)
                                                            : '0.00';

                                                    return (
                                                        <tr key={tr.id}>
                                                            <td>{tr?._id}</td>
                                                            <td
                                                                style={{
                                                                    color: 'green',
                                                                }}>
                                                                {statusAmounts
                                                                    .TS_COMPLETED
                                                                    .count ||
                                                                    0}{' '}
                                                                <span
                                                                    style={{
                                                                        color: '#808080',
                                                                    }}>
                                                                    (₦
                                                                    {formatCurrency(
                                                                        statusAmounts.TS_COMPLETED.amount.toFixed(
                                                                            2,
                                                                        ),
                                                                    )}
                                                                    )
                                                                </span>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    color: 'red',
                                                                }}>
                                                                {statusAmounts
                                                                    .TS_FAILED
                                                                    .count ||
                                                                    0}{' '}
                                                                <span
                                                                    style={{
                                                                        color: '#808080',
                                                                    }}>
                                                                    (₦
                                                                    {formatCurrency(
                                                                        statusAmounts.TS_FAILED.amount.toFixed(
                                                                            2,
                                                                        ),
                                                                    )}
                                                                    )
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {statusAmounts
                                                                    .TS_PENDING
                                                                    .count ||
                                                                    0}{' '}
                                                                <span
                                                                    style={{
                                                                        color: '#808080',
                                                                    }}></span>
                                                                (₦
                                                                {formatCurrency(
                                                                    statusAmounts.TS_PENDING.amount.toFixed(
                                                                        2,
                                                                    ),
                                                                )}
                                                                )
                                                            </td>
                                                            <td
                                                                style={{
                                                                    fontWeight:
                                                                        'bold',
                                                                }}>
                                                                {
                                                                    successPercentage
                                                                }
                                                                % (Success Rate)
                                                            </td>
                                                        </tr>
                                                    );
                                                },
                                            )}
                                        </tbody>
                                    )}
                                </table>

                                {load && <LoadTable />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageCover>
    );
}
