import {apiRequest} from '../../../utils/axiosInstance';
import {getPayload} from '../../../utils/encrypt';
import {authHeader} from '../../../utils/headers';

const corporateAccount = async (token: string, filters: any) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/corporate_profiles${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const createCorporateAccount = async (
    token: string,
    payload: any,
    key: any,
) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/corporate_profiles`,
        passPayload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const corporateProfileUsers = async (token: string, id: number) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/corporate-profile-users?corporate_profile_id=${id}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};
const corporateAccountUsers = async (token: string, id: number) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/corporate-profile-account-users?corporate_profile_id=${id}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const corporateBankAccount = async (token: string, id: number) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/corporate-profiles/${id}/bank_accounts`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const corporateService = {
    corporateAccount,
    createCorporateAccount,
    corporateAccountUsers,
    corporateBankAccount,
    corporateProfileUsers,
};

export default corporateService;
