import {getPayload} from '../../../utils/encrypt';
import {apiRequest} from '../../../utils/axiosInstance';
import {headers} from '../../../utils/headers';

const login = async (obj: any) => {
    let passPayload = getPayload(null, obj, 'v3');
    const {data} = await apiRequest('baseUrl', true).post(
        `/user/admin/login`,
        passPayload,
        {
            headers: headers,
        },
    );
    return data.data;
};

const loadQr = async (obj: any) => {
    let passPayload = getPayload(null, obj, 'v3');
    const {data} = await apiRequest('baseUrl', true).post(
        `/user/admin/initiate_2fa`,
        passPayload,
    );
    return data.data;
};

const googleAuthLogin = async (obj: any) => {
    let passPayload = getPayload(null, obj, 'v3');
    const {data} = await apiRequest('baseUrl', true).post(
        `/user/admin/verify_2fa`,
        passPayload,
    );
    return data.data;
};

const authService = {
    login,
    loadQr,
    googleAuthLogin,
};

export default authService;
