import {useEffect, useState} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import {IoRefreshCircle} from 'react-icons/io5';
import customerServices from '../../../redux/features/customer/customerService';
import {Link} from 'react-router-dom';
import {IoIosEye} from 'react-icons/io';
import Paginate from '../../../component/Paginate';
import LoadTable from '../../../component/Loaders/LoadTable';
import ModalComponent from '../../../component/ModalComponent';
import dateFormat from 'dateformat';
import {displayError} from '../../../utils/errors';
import {UseDebounce} from '../../../utils/hooks';
import {SearchInput} from '../../../component/BasicInputs';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import PickAgent from '../../../component/Customer/PickAgent';
import CreateCustomer from '../../../component/Customer/CreateCustomer';
import ToggleCustomer from '../../../component/Customer/ToggleCustomer';
import SecurityOtp from '../../../component/SecurityOtp';
import {toast} from 'react-toastify';
import PushNotificationModal from '../../../component/Customer/PushNotificationModal';

function AllCustomers() {
    const {details: data} = useAppSelector(state => state.auth);

    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});

    //pick agent
    const [agentId, setAgentId] = useState('');
    const [lockData, setLockData] = useState<any>([]);

    //handling otp events
    const [callName, setCallName] = useState('');
    const [otp, setOtp] = useState('');
    const [state, setState] = useState('');
    const [otpLoad, setOtpLoad] = useState(false);

    const [modal, setModal] = useState(false);

    //CREATE CUSTOMERS
    const [bvn, setBvn] = useState('');
    const [username, setUsername] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');

    //FILTERS
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const [applyFilter, setApplyFilter] = useState(false);
    const [active, setActive] = useState('active');

    //Toggle Customer
    const [comment, setComment] = useState('');

    const filters = `?page=${page}&per_page=${limit}&active=${active}`;

    const debouncedSearch = UseDebounce(search);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (debouncedSearch) {
            searchUsers();
        } else {
            fetchUsers();
        }
    }, [page, limit, applyFilter, debouncedSearch]);

    // get users
    const fetchUsers = async () => {
        try {
            setLoad(true);
            let res = await customerServices.AllCustomers(filters, {
                aes_key: data.aes_key,
                aes_iv: data.aes_iv,
            });
            setLoad(false);
            if (res?.data) {
                setList(res);
            }
        } catch (err: any) {
            setLoad(false);
            setList([]);
            displayError(err, true);
        }
    };

    const searchUsers = async () => {
        try {
            setLoad(true);
            let res = await customerServices.searchCustomers(
                debouncedSearch,
                limit,
                page,
                {
                    aes_key: data.aes_key,
                    aes_iv: data.aes_iv,
                },
            );
            setLoad(false);
            if (res?.data) {
                setList(res);
            }
        } catch (err) {
            setLoad(false);
            setList([]);
        }
    };

    //reset users
    const resetUsers = () => {
        setPage(1);
        setApplyFilter(false);
        setLimit(0);
        setSearch('');
        setId([]);
    };

    // reset google auth
    const resetGAuth = async () => {
        setOtpLoad(true);
        try {
            await customerServices.resetGoogleAuth(lockData, otp, {
                aes_key: data.aes_key,
                aes_iv: data.aes_iv,
            });
            setOtpLoad(false);
            fetchUsers();
            setModal(false);
            setOtp('');
            toast.success('Google Auth Reset Successfully');
        } catch (err) {
            displayError(err, true);
            setOtpLoad(false);
            setOtp('');
        }
    };

    //create users
    const createCustomer = async () => {
        let payload = {
            bvn,
            username,
            phone_number: phoneNumber,
            address,
            email,
            google_auth_code: otp,
        };
        setOtpLoad(true);
        try {
            await customerServices.createCustomer(payload, {
                aes_key: data.aes_key,
                aes_iv: data.aes_iv,
            });

            setOtpLoad(false);
            fetchUsers();
            setModal(false);
            setOtp('');
            toast.success('User Created Successfully');
            setBvn('');
            setEmail('');
            setAddress('');
            setUsername('');
            setPhoneNumber('');
        } catch (err) {
            displayError(err, true);
            setOtpLoad(false);
            setOtp('');
        }
    };

    //Refresh customer

    const refresh = () => {
        fetchUsers();
    };

    const filter = `?reason=${comment}`;

    const toggleHandler = async () => {
        setOtpLoad(true);
        try {
            await customerServices.toggleCustomer(
                lockData.locked_status,
                lockData.id,
                filter,
                otp,
            );
            setOtpLoad(false);
            fetchUsers();
            setModal(false);
            setOtp('');
            setComment('');
            toast.success(
                `User ${
                    lockData.locked_status === '0' ? 'Disable' : 'Enable'
                } Successfully`,
            );
        } catch (err) {
            displayError(err, true);
            setOtpLoad(false);
            setOtp('');
        }
    };

    const getName = (name: string) => {
        return name.replace(/ /g, '_');
    };

    const roles = data?.role_id;

    //Push notification logic
    const [id, setId] = useState<any>([]);

    const handleRadioChange = (tokens: {value: string}[]) => {
        if (roles !== '4') {
            if (tokens !== undefined && tokens.length > 0) {
                setId((prevId: string[]) => {
                    const ids = tokens.map(token => token.value);
                    const newIdSet = new Set(prevId);
                    ids.forEach(id => {
                        if (newIdSet.has(id)) {
                            newIdSet.delete(id);
                        } else {
                            newIdSet.add(id);
                        }
                    });

                    return Array.from(newIdSet);
                });
            } else {
                toast.warning('No tokens found!');
            }
        }
    };

    //To handle all Modal Components

    const getModalTitle = () => {
        switch (callName) {
            case 'add-user':
                return 'Create User';
            case 'toggle-user':
                return lockData.locked_status === '0'
                    ? 'Disable Customer'
                    : 'Enable Customer';
            case 'pick agent':
                return 'Pick Agent';
            case 'otp':
                return 'Enter your security OTP';
            case 'push notification':
                return 'Push Notification';
            default:
                return '';
        }
    };

    const getModalContent = () => {
        switch (callName) {
            case 'add-user':
                return (
                    <CreateCustomer
                        bvn={bvn}
                        setBvn={setBvn}
                        setPhoneNumber={setPhoneNumber}
                        phoneNumber={phoneNumber}
                        email={email}
                        setEmail={setEmail}
                        address={address}
                        setAddress={setAddress}
                        auth={otp}
                        setAuth={setOtp}
                        username={username}
                        setUsername={setUsername}
                        setOtpModal={() => setCallName('otp')}
                        close={() => setModal(false)}
                    />
                );
            case 'toggle-user':
                return (
                    <ToggleCustomer
                        close={() => setModal(false)}
                        comment={comment}
                        setComment={setComment}
                        setOtpModal={() => {
                            setCallName('otp');
                        }}
                    />
                );
            case 'pick agent':
                return <PickAgent agentId={agentId} setAgentId={setAgentId} />;
            case 'otp':
                return (
                    <SecurityOtp
                        otp={otp}
                        setOtp={setOtp}
                        load={otpLoad}
                        handleSubmit={() => {
                            if ((state as string) === 'add-user') {
                                createCustomer();
                            } else if ((state as string) === 'toggle-user') {
                                toggleHandler();
                            } else {
                                resetGAuth();
                            }
                        }}
                    />
                );
            case 'push notification':
                return (
                    <PushNotificationModal
                        id={id}
                        close={() => setModal(false)}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div>
            <BreadCrumbTitle title="All Customers" dataCount={list?.total} />
            <div className="row">
                <div className="col-lg-6 mb-3"></div>
                <div className="col-lg-6 mb-3">
                    <div className="filter">
                        {/* <button
                            onClick={() => setLimit(list?.total)}
                            className="main-btn secondary">
                            <IoRefreshCircle />
                            <span>All users</span>
                        </button>
                        <button
                            onClick={selectAllUsers}
                            className="main-btn secondary">
                            <IoRefreshCircle />
                            <span>select</span>
                        </button> */}
                        <button
                            onClick={refresh}
                            className="main-btn secondary">
                            <IoRefreshCircle />
                            <span>Refresh</span>
                        </button>
                        <button
                            className="main-btn info"
                            onClick={() => {
                                setModal(true);
                                setCallName('add-user');
                                setState('add-user');
                            }}>
                            + Add User
                        </button>
                        <button
                            onClick={() => resetUsers()}
                            className="main-btn ">
                            <IoRefreshCircle />
                            <span>Reset</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <SearchInput val={search} changeVal={setSearch} />
                </div>
                <div className="col-lg-4"></div>
                <div className="col-lg-2">
                    {id.length > 0 && (
                        <button
                            onClick={() => {
                                setModal(true);
                                setCallName('push notification');
                            }}
                            className="main-btn ">
                            <span>Send Message</span>
                        </button>
                    )}
                </div>
            </div>
            <div className="basic-card mt-3">
                <div className="head"></div>
                <div className="body">
                    <div className="table-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>S/N</th>
                                        <th>Email</th>
                                        <th>Name</th>
                                        <th>Account Number</th>
                                        <th>Phone Number</th>
                                        <th>BVN</th>
                                        <th>DOB</th>
                                        <th>User Name</th>
                                        <th>Date Created</th>
                                        <th>Lock</th>
                                        <th>Reset Google Auth</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                {!load && list?.data?.length > 0 && (
                                    <tbody>
                                        {list.data.map((tr: any, i: number) => (
                                            <tr key={tr.id}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        onChange={() =>
                                                            handleRadioChange(
                                                                tr?.user_tokens?.map(
                                                                    (t: {
                                                                        device_token: string;
                                                                    }) => ({
                                                                        value: t.device_token,
                                                                    }),
                                                                ),
                                                            )
                                                        }
                                                        checked={
                                                            tr?.user_tokens?.some(
                                                                (t: {
                                                                    device_token: string;
                                                                }) =>
                                                                    id?.includes(
                                                                        t.device_token,
                                                                    ),
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    {limit * (page - 1) + i + 1}
                                                </td>
                                                <td>{tr.email}</td>

                                                <td>
                                                    {tr.first_name}{' '}
                                                    {tr.last_name}
                                                </td>
                                                <td>
                                                    {tr?.bank_account?.length >
                                                    0
                                                        ? tr.bank_account[0]
                                                              .account_number
                                                        : '--'}
                                                </td>
                                                <td>{tr.phone_number}</td>
                                                <td>{tr.bvn}</td>
                                                <td>{tr.date_of_birth}</td>
                                                {/* <td
                                                    role="button"
                                                    className=" text-primary"
                                                    onClick={() =>
                                                        setPickAgent(true)
                                                    }>
                                                    Pick Agent
                                                </td> */}
                                                <td>{tr.username}</td>
                                                <td>
                                                    {tr?.bank_account?.length >
                                                    0
                                                        ? dateFormat(
                                                              tr.date_join,
                                                              'mmm dd, yyyy | h:MM TT',
                                                          )
                                                        : '--'}
                                                </td>
                                                <td>
                                                    <div className="form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            onChange={e => {
                                                                if (
                                                                    roles ===
                                                                        '1' ||
                                                                    roles ===
                                                                        '3'
                                                                ) {
                                                                    setLockData(
                                                                        tr,
                                                                    );
                                                                    setModal(
                                                                        true,
                                                                    );
                                                                    setCallName(
                                                                        'toggle-user',
                                                                    );
                                                                    setState(
                                                                        'toggle-user',
                                                                    );
                                                                }
                                                            }}
                                                            checked={
                                                                tr.locked_status ===
                                                                '0'
                                                                    ? false
                                                                    : true
                                                            }
                                                        />
                                                    </div>
                                                </td>

                                                <td className="link">
                                                    <div
                                                        className="link"
                                                        role="button"
                                                        onClick={() => {
                                                            if (
                                                                roles === '1' ||
                                                                roles === '3'
                                                            ) {
                                                                setLockData(
                                                                    tr.id,
                                                                );
                                                                setModal(true);
                                                                setCallName(
                                                                    'otp',
                                                                );
                                                                setState('otp');
                                                            }
                                                        }}>
                                                        Reset
                                                    </div>
                                                </td>
                                                <td className="link">
                                                    <Link
                                                        to={`/dashboard/customers/${getName(
                                                            `${tr.first_name} ${tr.last_name}`,
                                                        )}`}
                                                        state={tr.id}>
                                                        <IoIosEye />
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        {!load && list?.last_page > 1 && (
                            <Paginate
                                currentPage={page}
                                totalCount={list.total}
                                pageSize={limit}
                                onSelect={(p: number) => setPage(Number(p))}
                                onNext={(p: number) => setPage(p)}
                                onPrev={(p: number) => setPage(p)}
                                changeLimit={(p: string) => setLimit(Number(p))}
                            />
                        )}
                        {load && <LoadTable />}
                    </div>
                </div>
            </div>

            {/* Modal Components*/}

            <ModalComponent
                open={modal}
                close={() => setModal(false)}
                title={getModalTitle()}>
                {getModalContent()}
            </ModalComponent>
        </div>
    );
}

export default AllCustomers;
