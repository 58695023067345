import {getPayload} from '../../../utils/encrypt';
import {apiRequest} from '../../../utils/axiosInstance';

const login = async (obj: any) => {
    let passPayload = getPayload(null, obj, 'v3');
    const {data} = await apiRequest('baseUrl').post(
        `/user/admin/login`,
        passPayload,
    );
    console.log(data.data)
    return data.data;
};

const loadQr = async (obj: any) => {
    let passPayload = getPayload(null, obj, 'v3');
    const {data} = await apiRequest('baseUrl').post(
        `/user/admin/initiate_2fa`,
        passPayload,
    );
    return data.data;
};

const googleAuthLogin = async (obj: any) => {
    let passPayload = getPayload(null, obj, 'v3');
    const {data} = await apiRequest('baseUrl').post(
        `/user/admin/verify_2fa`,
        passPayload,
    );
    return data.data;
};

const authService = {
    login,
    loadQr,
    googleAuthLogin,
};

export default authService;
