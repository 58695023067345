import {apiRequest} from '../../../utils/axiosInstance';

const getUserReports = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/summary-of-customer-details${filters}`,
    );
    return data.data;
};

const getReportSummary = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/summary-of-customer-details${filters}`,
    );
    return data.data;
};
const getTransactionReport = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/status_by_months${filters}`,
    );
    return data.data;
};
const getSavingsInvestmentReport = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/summary-of-customer-details${filters}`,
    );
    return data.data;
};

const getSubscriptionReport = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_promo_subscriptions${filters}`,
    );
    return data;
};

// STATS

const getSavingsAndInvestments = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/savinvest/savings_and_investment_analysis${filters}`,
    );
    return data;
};

const getUserSavingsAnalysis = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/savinvest/user_savings_analysis${filters}`,
    );
    return data;
};

const getTotalTransactions = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/total_daily_txns${filters}`,
    );
    return data;
};

const getAllTimeCommission = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/all_time_commissions${filters}`,
    );
    return data;
};

const getInstitutionReport = async (filters: string) => {
    const {data} = await apiRequest('url').get(
        `/transaction/get_institution_status_sum${filters}`,
    );
    return data;
};

const getUserReport = async (filters: string) => {
    const {data} = await apiRequest('url').get(
        `/transaction/users/txn_service/vol/report?startDate=2024-01-01&endDate=2024-11-19`,
    );
    return data;
};

const getServiceReport = async (filters: string) => {
    const {data} = await apiRequest('url').get(
        `/transaction/services_status_sum${filters}`,
    );
    return data;
};

const reportService = {
    getUserReports,
    getReportSummary,
    getTransactionReport,
    getSavingsInvestmentReport,
    getSubscriptionReport,
    getSavingsAndInvestments,
    getAllTimeCommission,
    getTotalTransactions,
    getUserSavingsAnalysis,

    getInstitutionReport,
    getUserReport,
    getServiceReport,
};

export default reportService;
