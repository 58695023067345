import dateFormat from 'dateformat';
import React from 'react';
import {Link} from 'react-router-dom';
import { getName } from '../../pages/Admin/CorporateAccount/Index';

function CorporateStaffs({list}: {list: any}) {
    const page = 1;
    const limit = 20;
    return (
        <div>
            <div className="table-body">
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>S/N</th>
                                <th>Account Name</th>
                                <th>Account Number</th>
                                <th>name</th>
                                <th>Role</th>
                                <th>Phone Number</th>
                                <th>Address</th>
                                <th>email</th>
                                <th>Created At</th>
                                {/* <th>Actions</th> */}
                            </tr>
                        </thead>
                        {list?.length > 0 && (
                            <tbody>
                                {list?.map((tr: any, i: number) => (
                                    <tr key={tr?.id}>
                                        <td>{limit * (page - 1) + i + 1}</td>
                                        <td>{tr.bank_account?.account_name}</td>
                                        <td>
                                            {tr.bank_account?.account_number}
                                        </td>
                                        <td className="link">
                                            <Link
                                                to={`/dashboard/customers/${getName(
                                                    `${tr.user?.first_name} ${tr.user?.last_name}`,
                                                )}`}
                                                state={tr.user?.id}>
                                                {tr.user?.first_name}{' '}
                                            {tr.user?.last_name}
                                            </Link>
                                        </td>
                                        <td>
                                          
                                        </td>{' '}
                                        <td>{tr?.corporate_role?.name}</td>
                                        <td>{tr.user?.phone_number}</td>
                                        <td>{tr?.user?.address}</td>
                                        <td>{tr?.user?.email}</td>
                                        <td>{dateFormat(tr?.created_at)}</td>
                                        {/* <td className="d-flex justify-content-evenly">
                                                            <span
                                                                role="button"
                                                                onClick={() => {
                                                                    setOpen(
                                                                        true,
                                                                    );
                                                                    setDetails(
                                                                        tr,
                                                                    );
                                                                }}>
                                                                {' '}
                                                                <LidescriptionaEyeSolid />
                                                            </span>
                                                        </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </div>
    );
}

export default CorporateStaffs;
