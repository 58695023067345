import React, {useState} from 'react';
import {TextInput} from '../BasicInputs';
import terminalService from '../../redux/features/terminals/terminalService';
import {displayError} from '../../utils/errors';
import {useAppSelector} from '../../redux/hooks';
import BasicLoad from '../Loaders/BasicLoad';

interface props {
    terminalSerialNumber?: string;
    pin: string;
    confirmPin: string;
    setPin: (arg: string) => void;
    setConfirmPin: (arg: string) => void;
    serialNo: any;
    refresh: () => void;
}

function ResetPin({
    terminalSerialNumber,
    pin,
    confirmPin,
    setPin,
    setConfirmPin,
    serialNo,
    refresh,
}: props) {
    const {token} = useAppSelector(state => state.auth);

    const [load, setLoad] = useState(false);

    const ResetPin = async (e: any) => {
        e.preventDefault();
        const payload = {
            serialNo,
            confirmPin,
            pin,
        };
        try {
            setLoad(true);
            await terminalService.resetPin(token, payload);
            setLoad(false);
            refresh();
        } catch (e) {
            setLoad(false);
            displayError(e, true);
        }
    };

    return (
        <div className="row">
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={serialNo}
                    changeVal={() => terminalSerialNumber}
                    label="Terminal Serial Number"
                    type="number"
                    disabled
                />
            </div>
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={pin}
                    changeVal={setPin}
                    label="Pin"
                    type="number"
                    placeholder="Enter your pin"
                />
            </div>
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={confirmPin}
                    changeVal={setConfirmPin}
                    label="Confirm PIN"
                    type="number"
                    placeholder="Enter your pin"
                />
            </div>
            <div className="col-lg-12 text-center">
                <button className="main-btn" onClick={ResetPin}>
                    {load ? <BasicLoad /> : 'Set Pin'}
                </button>
            </div>
        </div>
    );
}

export default ResetPin;
