import React, {useEffect, useState} from 'react';
import dateFormat from 'dateformat';
import customerServices from '../../../redux/features/customer/customerService';
import {displayError} from '../../../utils/errors';
import {toast} from 'react-toastify';
import ModalComponent from '../../../component/ModalComponent';
import ApproveCustomer from '../../../component/Customer/Details/ApproveCustomers';
import SecurityOtp from '../../../component/SecurityOtp';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import LoadTable from '../../../component/Loaders/LoadTable';
import EditCustomer from '../../../component/Customer/Details/EditCustomer';

function ApproveCustomers() {
    const [approvals, setApprovals] = useState<any>([]);
    const [otp, setOtp] = useState('');
    const [modal, setModal] = useState(false);
    const [modalState, setModalState] = useState<any>({});
    const [otpLoad, setOtpLoad] = useState(false);
    const [load, setLoad] = useState(false);

    const [state, setState] = useState<any>({});

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gender, setGender] = useState('');
    const [number, setNumber] = useState('');
    const [otherName, setOtherName] = useState('');
    const [address, setAddress] = useState('');
    const [maritalStatus, setMaritalStatus] = useState('');
    const [dob, setDob] = useState(new Date());

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(30);

    const filter = `?page=${page}&per_page=${limit}`;

    useEffect(() => {
        setEmail(state?.new_data?.email);
        setFirstName(state?.new_data?.first_name);
        setLastName(state?.new_data?.last_name);
        setOtherName(state?.new_data?.others_name);
        setEmail(state?.new_data?.email);
        setAddress(state?.new_data?.address);
        setMaritalStatus(state?.new_data?.marital_status);
        setGender(state?.new_data?.gender);
        setNumber(state?.new_data?.phone_number);
        setDob(state?.new_data?.date_of_birth);
    }, [state?.id]);

    const getApprovals = async () => {
        setLoad(true);
        try {
            const res = await customerServices.getProfileRequest(filter);
            setApprovals(res);
            setLoad(false);
        } catch (err) {
            displayError(err, true);
        }
    };

    const deleteApproval = async (id: number) => {
        try {
            await customerServices.deleteUpgrade(id);
            getApprovals();
            setOtpLoad(false);
            toast.success('Deleted Request');
            setOtp('');
            setModal(false);
        } catch (err) {
            displayError(err, true);
            setOtpLoad(false);
            setOtp('');
        }
    };

    const editApproval = async (id: number) => {
        const payload = {
            user_id: state.user_id,
            email,
            phone_number: number,
            first_name: firstName,
            last_name: lastName,
            others_name: otherName,
            address,
            date_of_birth: dob,
            gender,
            marital_status: maritalStatus,
        };
        try {
            await customerServices.editProfileRequest(id, payload, otp);
            getApprovals();
            setOtpLoad(false);
            setOtp('');
            toast.success('Edited Request');
            setModal(false);
        } catch (err) {
            displayError(err, true);
            setOtpLoad(false);
            setOtp('');
        }
    };

    const approve = async (id: number) => {
        try {
            await customerServices.approveUpgrade(id);
            setOtpLoad(false);
            setOtp('');
            setModal(false);
            toast.success('Approved Request');
            getApprovals();
        } catch (err) {
            displayError(err, true);
            setOtpLoad(false);
            setOtp('');
        }
    };

    useEffect(() => {
        getApprovals();
    }, []);

    return (
        <div className="">
            <BreadCrumbTitle
                title="Customers Approval"
                dataCount={approvals?.total}
            />
            <div className="basic-card mt-3">
                <div className="body">
                    <div className="table-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>account name</th>
                                        <th>Email</th>
                                        <th>Requested By</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                {!load && approvals?.data?.length > 0 && (
                                    <tbody>
                                        {approvals?.data?.map(
                                            (props: any, i: number) => (
                                                <tr key={props.id}>
                                                    <td> {i + 1} </td>
                                                    <td>
                                                        {
                                                            props.old_data
                                                                ?.first_name
                                                        }{' '}
                                                        {
                                                            props.old_data
                                                                ?.last_name
                                                        }
                                                    </td>
                                                    <td>
                                                        {props.old_data?.email}
                                                    </td>
                                                    <td>
                                                        {
                                                            props.requested_by
                                                                .username
                                                        }
                                                    </td>

                                                    <td>
                                                        {dateFormat(
                                                            props.created_at,
                                                        )}
                                                    </td>
                                                    <td>
                                                        <button
                                                            className="main-btn info"
                                                            onClick={() => {
                                                                setModal(true);
                                                                setModalState({
                                                                    state: 'view',
                                                                });
                                                                setState(props);
                                                            }}>
                                                            View
                                                        </button>
                                                    </td>
                                                    {props.status ===
                                                        'pending' && (
                                                        <td className="d-flex gap-3">
                                                            <button
                                                                className="main-btn"
                                                                onClick={() => {
                                                                    setModal(
                                                                        true,
                                                                    );
                                                                    setModalState(
                                                                        {
                                                                            function:
                                                                                'edit',
                                                                            state: 'edit',
                                                                            id: props.id,
                                                                        },
                                                                    );
                                                                    setState(
                                                                        props,
                                                                    );
                                                                }}>
                                                                Edit
                                                            </button>
                                                            <button
                                                                className="main-btn danger"
                                                                onClick={() => {
                                                                    setModalState(
                                                                        {
                                                                            function:
                                                                                'delete',
                                                                            state: 'otp',
                                                                            id: props.id,
                                                                        },
                                                                    );
                                                                    setModal(
                                                                        true,
                                                                    );
                                                                }}>
                                                                Delete
                                                            </button>
                                                            <button
                                                                className="main-btn"
                                                                onClick={() => {
                                                                    setModalState(
                                                                        {
                                                                            function:
                                                                                'approve',
                                                                            state: 'otp',
                                                                            id: props.id,
                                                                        },
                                                                    );
                                                                    setModal(
                                                                        true,
                                                                    );
                                                                }}>
                                                                Approve
                                                            </button>
                                                        </td>
                                                    )}
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        {load && <LoadTable />}
                    </div>
                </div>
            </div>
            <ModalComponent
                open={modal}
                close={() => setModal(false)}
                size={modalState.state === 'otp' ? undefined : 'lg'}
                title={
                    modalState.state === 'view' ? 'View Details' : 'Enter Otp'
                }>
                {modalState.state === 'view' ? (
                    <ApproveCustomer details={state} />
                ) : modalState.state === 'edit' ? (
                    <EditCustomer
                        maritalStatus={maritalStatus}
                        setMaritalStatus={setMaritalStatus}
                        firstName={firstName}
                        setFirstName={setFirstName}
                        lastName={lastName}
                        setLastName={setLastName}
                        otherName={otherName}
                        setOtherName={setOtherName}
                        email={email}
                        setEmail={setEmail}
                        address={address}
                        setAddress={setAddress}
                        number={number}
                        setNumber={setNumber}
                        gender={gender}
                        setGender={setGender}
                        dob={dob}
                        setDob={setDob}
                        setModalState={setModalState}
                    />
                ) : (
                    <SecurityOtp
                        load={otpLoad}
                        otp={otp}
                        setOtp={setOtp}
                        handleSubmit={() => {
                            modalState.function === 'edit'
                                ? editApproval(modalState.id)
                                : modalState.function === 'delete'
                                ? deleteApproval(modalState.id)
                                : approve(modalState.id);
                        }}
                    />
                )}
            </ModalComponent>
        </div>
    );
}

export default ApproveCustomers;
