import dateFormat from 'dateformat';
import React from 'react';

function CorporateDetail({details}: {details: any}) {
    console.log(details)
    return (
        <div>
            <div className="">
                <div className="row">
                    <div className="col-lg-6 customer-details">
                        <div className="customer-details-content">
                            <span>Business Name</span>
                            <strong>{details?.business_name}</strong>
                        </div>
                        <div className="customer-details-content">
                            <span>Business Address</span>
                            <strong>{details?.business_address}</strong>
                        </div>
                        <div className="customer-details-content">
                            <span>Business Email</span>
                            <strong>{details?.business_email}</strong>
                        </div>
                        <div className="customer-details-content">
                            <span>Business Phone</span>
                            <strong>{details?.business_phone}</strong>
                        </div>
                        <div className="customer-details-content">
                            <span>Business Type</span>
                            <strong>{details?.business_type || '--'}</strong>
                        </div>
                        <div className="customer-details-content">
                            <span>Industry Sector</span>
                            <strong>{details?.industry_sector}</strong>
                        </div>
                        <div className="customer-details-content">
                            <span>Business Start Date</span>
                            <strong>
                                {dateFormat(details?.business_start_date)}
                            </strong>
                        </div>
                        <div className="customer-details-content">
                            <span>CAC</span>
                            <strong>{details?.cac_reg_no || '--'}</strong>
                        </div>
                        <div className="customer-details-content">
                            <span>Tin</span>
                            <strong>{details?.tin || '--'}</strong>
                        </div>
                        <h3 className='pt-3'>Directors Detail</h3>
                        <div className="customer-details-content">
                            <span>Name</span>
                            <strong>{details?.admin?.first_name} {details?.admin?.last_name}</strong>
                        </div>
                        <div className="customer-details-content">
                            <span>Username</span>
                            <strong>{details?.admin?.username}</strong>
                        </div>
                        <div className="customer-details-content">
                            <span>Phone Number</span>
                            <strong>{details?.admin?.phone_number}</strong>
                        </div>
                        <div className="customer-details-content">
                            <span>Address</span>
                            <strong>{details?.admin?.address}</strong>
                        </div>
                        <div className="customer-details-content">
                                <span>BVN</span>
                                <strong>
                                    {details?.admin?.bvn?.substr(0, 4)}*******
                                </strong>
                            </div>

                        <div className="customer-details-content">
                            <span> Status</span>
                            <strong>
                                {details?.admin?.locked_status === '0'
                                    ? 'Active'
                                    : 'Deactivated'}
                            </strong>
                        </div>
                    </div>
                    <div className="col-lg-6"></div>
                </div>
            </div>
        </div>
    );
}

export default CorporateDetail;
