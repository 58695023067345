import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import {displayError} from '../../../utils/errors';
import loginHistoryService from '../../../redux/features/login/loginHistoryService';
import Paginate from '../../../component/Paginate';
import LoadTable from '../../../component/Loaders/LoadTable';
import dateFormat from 'dateformat';
import {Link} from 'react-router-dom';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import { SearchInput } from '../../../component/BasicInputs';
import { UseDebounce } from '../../../utils/hooks';

function LoginHistory() {
    const {token} = useAppSelector(state => state.auth);

   

    const [list, setList] = useState<any>([]);
    const [load, setLoad] = useState(false);

    //filters
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const [search, setSearch] = useState(''); 
    
    const debounce = UseDebounce(search)

    const filters = `?page=${page}&per_page=${limit}&search=${search}`

    const fetchHistories = async () => {
        setLoad(true);
        try {
            const res = await loginHistoryService.getLoginHistories(token,filters);
            setList(res);
            setLoad(false);
        } catch (err) {
            displayError(err, true);
            setLoad(false);
        }
    };

    useEffect(() => {
        fetchHistories();
    }, [page, limit, debounce]);

    const getName = (name: string) => {
        return name.replace(/ /g, '_');
    };
    return (
        <div>
            <BreadCrumbTitle title="Login Histories" dataCount={list?.total} />
<div className='row'>
<div className="col-lg-4 mt-4 ">
                    <SearchInput val={search} changeVal={setSearch} />
                </div>
</div>

            <div className="body">
                <div className="table-body">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>S/N</th>
                                    <th>User</th>
                                    <th>Email</th>
                                    <th>Platform</th>
                                    <th>Device</th>
                                    <th>IP Address</th>
                                    <th>Date Logged In</th>
                                    <th>Channel</th>
                                </tr>
                            </thead>

                            {!load && list?.data?.length > 0 && (
                                <tbody>
                                    {list?.data?.map(
                                        (props: any, i: number) => (
                                            <tr key={props.id}>
                                                <td>
                                                    {' '}
                                                    {limit * (page - 1) + i + 1}
                                                </td>
                                                <td>
                                                    <Link
                                                        to={`/dashboard/customers/${getName(
                                                            `${props?.user?.first_name} ${props?.user?.last_name}`,
                                                        )}`}
                                                        state={props?.user_id}>
                                                        {props?.user?.first_name}{' '}
                                                        {props?.user?.last_name}
                                                    </Link>
                                                </td>

                                                <td>{props?.user?.email}</td>
                                                <td>{props?.platform}</td>
                                                <td>{props?.device}</td>
                                                <td>{props?.ip}</td>
                                                <td>
                                                    {dateFormat(
                                                        props?.created_at,
                                                    )}
                                                </td>
                                                <td>{props?.channel}</td>
                                            </tr>
                                        ),
                                    )}
                                </tbody>
                            )}
                        </table>
                      
                    </div>
                    {!load && list?.last_page > 1 && (
                            <Paginate
                                currentPage={page}
                                totalCount={list.total}
                                pageSize={limit}
                                onSelect={(p: number) => setPage(Number(p))}
                                onNext={(p: number) => setPage(p)}
                                onPrev={(p: number) => setPage(p)}
                                changeLimit={(p: string) => setLimit(Number(p))}
                            />
                        )}
                        {load && <LoadTable />}
                </div>
            </div>
        </div>
    );
}

export default LoginHistory;
