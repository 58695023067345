import {apiRequest} from '../../../utils/axiosInstance';

const getFees = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/fee_profiles${filters}`,
    );
    return data.data;
};

const createFees = async (payload: any) => {
    const {data} = await apiRequest('baseUrl').post(
        `transaction/update_fee_profile`,
        payload,
    );
    return data.data;
};

const feeService = {
    getFees,
    createFees,
};

export default feeService;
