import React, {useEffect, useState} from 'react';
import {TextInput} from '../BasicInputs';
import terminalService from '../../redux/features/terminals/terminalService';
import {displayError} from '../../utils/errors';
import {useAppSelector} from '../../redux/hooks';
import BasicLoad from '../Loaders/BasicLoad';
import {toast} from 'react-toastify';

interface props {
    terminalSerialNumber?: string;
    pin: string;
    confirmPin: string;
    setPin: (arg: string) => void;
    setConfirmPin: (arg: string) => void;
    serialNo: any;
    refresh: () => void;
}

function ResetPin({
    terminalSerialNumber,
    pin,
    confirmPin,
    setPin,
    setConfirmPin,
    serialNo,
    refresh,
}: props) {
    const [load, setLoad] = useState(false);

    const [pinError, setPinError] = useState(false);

    useEffect(() => {
        if (pin?.length === 4) {
            if (isValidPIN(pin)) {
                setPinError(false);
            } else {
                setPinError(true);
            }
        }
    }, [pin]);

    const isValidPIN = (val: string) => {
        const weakPINs = [
            '0000',
            '1234',
            '1111',
            '2222',
            '3333',
            '4444',
            '5555',
            '6666',
            '7777',
            '8888',
            '9999',
        ];

        if (weakPINs.includes(val)) {
            return false;
        }

        for (let i = 0; i < pin.length - 2; i++) {
            if (val[i] === val[i + 1] && val[i + 1] === val[i + 2]) {
                return false;
            }
        }

        return true;
    };

    const changePin = (val: string, confirm: boolean) => {
        if (!isNaN(Number(val))) {
            if (val.length <= 4) {
                if (confirm) {
                    setConfirmPin(val);
                } else {
                    setPin(val);
                }
            }
        }
    };

    const ResetPin = async (e: any) => {
        e.preventDefault();
        if (pin && pin === confirmPin) {
            const payload = {
                serialNo,
                confirmPin,
                pin,
            };
            try {
                setLoad(true);
                await terminalService.resetPin(payload);
                setLoad(false);
                toast.success('Pin reset Successful');
                refresh();
            } catch (e) {
                setLoad(false);
                displayError(e, true);
            }
        } else {
            toast.error('PIN mismatch.');
        }
    };

    return (
        <div className="row">
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={serialNo}
                    changeVal={() => terminalSerialNumber}
                    label="Terminal Serial Number"
                    type="number"
                    disabled
                />
            </div>
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={pin}
                    changeVal={val => changePin(val, false)}
                    label="Pin"
                    type="password"
                    placeholder="Enter your pin"
                />
            </div>
            {pinError && (
                <div
                    style={{
                        color: 'red',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                    }}>
                    PIN too weak
                </div>
            )}
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={confirmPin}
                    changeVal={val => changePin(val, true)}
                    label="Confirm PIN"
                    type="password"
                    placeholder="Enter your pin"
                />
            </div>
            <div className="col-lg-12 text-center">
                <button className="main-btn" onClick={ResetPin}>
                    {load ? <BasicLoad /> : 'Set Pin'}
                </button>
            </div>
        </div>
    );
}

export default ResetPin;
