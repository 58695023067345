import {apiRequest} from '../../../utils/axiosInstance';

const getSavingsWallet = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/savinvest/user_wallets?${filters}`,
    );
    return data.data;
};
const getUserSavings = async () => {
    const {data} = await apiRequest('baseUrl').get(`/savinvest/user_savings`);
    return data.data;
};
const getSavingsPenality = async () => {
    const {data} = await apiRequest('baseUrl').get(
        `/savinvest/savings_penalties`,
    );
    return data.data;
};
const getwalletTransactions = async () => {
    const {data} = await apiRequest('baseUrl').get(
        `/savinvest/wallet_transactions`,
    );
    return data.data;
};
const getwithdrawalDate = async () => {
    const {data} = await apiRequest('baseUrl').get(
        `/savinvest/withdrawal_dates`,
    );
    return data.data;
};

const savingsService = {
    getSavingsWallet,
    getUserSavings,
    getSavingsPenality,
    getwalletTransactions,
    getwithdrawalDate,
};
export default savingsService;
