import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import disputeService from '../../../redux/features/dispute/disputeService';
import {UseDebounce} from '../../../utils/hooks';
import {SearchInput, SelectInput} from '../../../component/BasicInputs';
import LoadTable from '../../../component/Loaders/LoadTable';
import Paginate from '../../../component/Paginate';
import {Link} from 'react-router-dom';
import {IoIosEye} from 'react-icons/io';
import dateFormat from 'dateformat';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import PageCover from '../../../component/PageCover';

function Dispute() {
    const {token} = useAppSelector(state => state.auth);
    const [openFilter, setOpenFilter] = useState(false);
    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});
    //FILTERS
    const [search, setSearch] = useState('');
    const [type, setType] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [status, setStatus] = useState('');

    const debouncedSearch = UseDebounce(search);

    const filters = `?page=${
        page - 1
    }&size=${limit}&userType=admin&search=${search}&type=${type}&status=${status}`;

    const fetchDispute = async () => {
        try {
            setLoad(true);
            let res = await disputeService.allDispute(token, filters);
            setLoad(false);
            if (res) {
                setList(res);
            }
        } catch (err) {
            setLoad(false);
            setList([]);
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        if (debouncedSearch) {
            fetchDispute();
        } else {
            fetchDispute();
        }
    }, [filters, debouncedSearch]);
    return (
        <PageCover page="Dispute">
            <div>
                <BreadCrumbTitle
                    title="Disputes"
                    dataCount={list?.totalElements}
                />
                <div className="d-flex align-items-center gap-5 mt-3">
                    <div className="col-lg-4 mb-3">
                        <SelectInput
                            option={[
                                {label: 'Pending', value: 'Pending'},
                                {label: 'Open', value: 'Open'},
                                {label: 'Replied', value: 'Replied'},
                                {
                                    label: 'Customer Replied',
                                    value: 'Customer_Replied',
                                },
                                {label: 'Close', value: 'Closed'},
                            ]}
                            val={status}
                            changeVal={setStatus}
                            label="Filter Status"
                        />
                    </div>
                    <div className="row">
                        <div className="">
                            <SearchInput val={search} changeVal={setSearch} />
                        </div>
                    </div>

                    <div>
                        <input
                            type="checkbox"
                            value={type}
                            onChange={() => setType('last7days')}
                        />{' '}
                        Last 7 days
                    </div>
                </div>

                <div className="basic-card mt-3">
                    <div className="head"></div>
                    <div className="body">
                        <div className="table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>From</th>
                                            <th>ticket id</th>
                                            <th>transaction ref</th>
                                            <th>transaction date</th>
                                            <th>last updated </th>
                                            <th>status</th>
                                            <th>View</th>
                                        </tr>
                                    </thead>

                                    {!load && list?.content?.length > 0 && (
                                        <tbody>
                                            {list.content.map(
                                                (tr: any, i: number) => (
                                                    <tr key={tr.id}>
                                                        <td>{tr.username}</td>
                                                        <td>{tr?.id}</td>
                                                        <td>
                                                            {tr?.transactionRef}
                                                        </td>
                                                        <td>
                                                            {dateFormat(
                                                                tr?.createdAt,
                                                                'mmm dd, yyyy | h:MM TT',
                                                            )}
                                                        </td>
                                                        <td>
                                                            {' '}
                                                            {dateFormat(
                                                                tr?.updatedAt,
                                                                'mmm dd, yyyy | h:MM TT',
                                                            )}
                                                        </td>
                                                        <td>{tr.status}</td>

                                                        <td className="link">
                                                            <Link
                                                                to={`/dashboard/disputes/${tr?.id}`}>
                                                                <IoIosEye />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ),
                                            )}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                            {!load && list?.totalPages > 1 && (
                                <Paginate
                                    currentPage={page}
                                    totalCount={list.totalElements}
                                    pageSize={limit}
                                    lastPage={list.totalPages}
                                    onSelect={(p: number) => setPage(Number(p))}
                                    onNext={(p: number) => setPage(p)}
                                    onPrev={(p: number) => setPage(p)}
                                    changeLimit={(p: string) =>
                                        setLimit(Number(p))
                                    }
                                />
                            )}
                            {load && <LoadTable />}
                        </div>
                    </div>
                </div>
            </div>
        </PageCover>
    );
}

export default Dispute;
