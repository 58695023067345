import {useEffect, useState} from 'react';
import {TextInput} from '../BasicInputs';
import {useAppSelector} from '../../redux/hooks';
import kycService from '../../redux/features/kyc/kycService';
import {displayError} from '../../utils/errors';
interface props {
    details: any;
    level_name?: any;
    setClosed: () => void;
}
function AddEditKycLevel({details, setClosed}: props) {
    const {details: data} = useAppSelector(state => state.auth);

    const [load, setLoad] = useState(false);
    const [levelName, setLevelName] = useState('');
    const [description, setDescription] = useState('');
    const [level, setLevel] = useState('');

    useEffect(() => {
        if (details?.level) {
            setLevel(details.level);
            setLevelName(details.level_name);
            setDescription(details.description);
        }
    }, [details]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const payload = {
            level_name: levelName,
            description: description,
            level: level,
        };

        try {
            setLoad(true);
            if (details?.level) {
                await kycService.updateKycLevels(payload, details.level, {
                    aes_key: data.aes_key,
                    aes_iv: data.aes_iv,
                });
                setLoad(false);
                setClosed();
            } else {
                await kycService.setKycLevels(payload, {
                    aes_key: data.aes_key,
                    aes_iv: data.aes_iv,
                });
                setLoad(false);
                setClosed();
            }
        } catch (err) {
            displayError(err, true);
        }
    };
    return (
        <div className="row">
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={levelName}
                    changeVal={setLevelName}
                    label="Level Name"
                    placeholder="level name"
                />
            </div>
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={description}
                    changeVal={setDescription}
                    label="Description"
                    placeholder="Description"
                />
            </div>
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={level}
                    changeVal={setLevel}
                    label="KYC Level"
                    placeholder="level"
                />
            </div>

            <div className="col-lg-12 text-center">
                <button onClick={handleSubmit} className="main-btn">
                    {load ? 'loading' : `${details?.level ? 'Edit' : 'Create'}`}
                </button>
            </div>
        </div>
    );
}

export default AddEditKycLevel;
