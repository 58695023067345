import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../../redux/hooks';
import InterestService from '../../../../redux/features/interests/interestService';
import {displayError} from '../../../../utils/errors';
import BreadCrumbTitle from '../../../../component/BreadCrumbTitle';
import {LiaEyeSolid} from 'react-icons/lia';
import {GoPencil} from 'react-icons/go';
import {AiOutlineDelete} from 'react-icons/ai';
import ModalComponent from '../../../../component/ModalComponent';
import InterestConfigDetails from '../../../../component/Interest/InterestConfigDetails';
import dateFormat from 'dateformat';
import {formatCurrency} from '../../../../utils/numberFormat';
import PageCover from '../../../../component/PageCover';

function Interests() {
    const {token} = useAppSelector(state => state.auth);

    const [openFilter, setOpenFilter] = useState(false);
    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});
    const [details, setDetails] = useState<any>({});

    //FILTERS
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [startDate, setStartDate] = useState(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    );
    const [account, setAccount] = useState('');
    const [endDate, setEndDate] = useState(
        new Date(new Date().setDate(new Date().getDate() + 1)),
    );
    const [applyFilter, setApplyFilter] = useState(false);

    let filters = `?page=${page}&per_page=${limit} `;

    const fetchInterestConfig = async () => {
        try {
            setLoad(true);
            let res = await InterestService.getInterestPayoutsConfig(
                token,
                filters,
            );

            setLoad(false);
            setList(res);
        } catch (err) {
            setLoad(false);
            setList([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchInterestConfig();
    }, [page, limit, applyFilter]);

    // const resetTransaction = () => {
    //     setPage(1);
    //     setApplyFilter(false);
    //     setStartDate(
    //         new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    //     );
    //     setEndDate(new Date(new Date().setDate(new Date().getDate() + 1)));
    //     setAccount('');
    // };

    return (
        <PageCover page="Interests">
            <div>
                <BreadCrumbTitle
                    title="Interest Config"
                    dataCount={list?.length}
                />

                <div className="basic-card mt-3">
                    <div className="head"></div>
                    <div className="body">
                        <div className="table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Annual Interest</th>
                                            <th>Minimum Amount(ngn)</th>
                                            <th>maximum amount (ngn)</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    {!load && list?.length > 0 && (
                                        <tbody>
                                            {list?.map((tr: any, i: number) => (
                                                <tr key={tr.id}>
                                                    <td>
                                                        {limit * (page - 1) +
                                                            i +
                                                            1}
                                                    </td>
                                                    <td>{tr.name}</td>
                                                    <td>{tr.description}</td>
                                                    <td>
                                                        {tr.annual_interest}
                                                    </td>
                                                    <td>
                                                        {formatCurrency(
                                                            tr.minimum_amount,
                                                        )}
                                                    </td>

                                                    <td>
                                                        {formatCurrency(
                                                            tr.maximum_amount,
                                                        )}
                                                    </td>

                                                    <td>
                                                        <span
                                                            onClick={() => {
                                                                setOpenFilter(
                                                                    true,
                                                                );
                                                                setDetails(tr);
                                                            }}>
                                                            {' '}
                                                            <LiaEyeSolid />
                                                        </span>
                                                        <span>
                                                            {' '}
                                                            <GoPencil />
                                                        </span>
                                                        <span>
                                                            {' '}
                                                            <AiOutlineDelete />
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalComponent
                    open={openFilter}
                    close={() => setOpenFilter(false)}
                    title="Interest Config Details">
                    <InterestConfigDetails {...details} />
                </ModalComponent>
            </div>
        </PageCover>
    );
}

export default Interests;
