import {useEffect, useState} from 'react';
import DashBoardPie from '../../component/Charts/DashBoardPie';
import DashboardBar from '../../component/Charts/DashboardBar';
import dateFormat from 'dateformat';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {
    getTransactionStats,
    getUserStats,
} from '../../redux/features/basic/basicSlice';
import {formatCurrency, numberWithCommas} from '../../utils/numberFormat';
import {DateSelect} from '../../component/BasicInputs';
import PageCover from '../../component/PageCover';

const Dashboard = () => {
    const dispatch = useAppDispatch();

    const {userStats, transactionStats} = useAppSelector(state => state.basic);

    const [startDate, setStartDate] = useState(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    );
    const [endDate, setEndDate] = useState(
        new Date(new Date().setDate(new Date().getDate() + 1)),
    );

    useEffect(() => {
        dispatch(
            getUserStats({
                startDate: dateFormat(startDate, 'yyyy-mm-dd'),
                endDate: dateFormat(endDate, 'yyyy-mm-dd'),
            }),
        );
        dispatch(
            getTransactionStats({
                startDate: dateFormat(startDate, 'yyyy-mm-dd'),
                endDate: dateFormat(endDate, 'yyyy-mm-dd'),
            }),
        );
    }, [startDate, endDate]);
    return (
        <PageCover page="Dashboard">
            <div className="dash-stats">
                <div className="filter">
                    <DateSelect
                        label="Start Date"
                        dateVal={startDate}
                        changeDateVal={setStartDate}
                    />
                    <DateSelect
                        label="End Date"
                        dateVal={endDate}
                        changeDateVal={setEndDate}
                    />
                </div>
                <h5 className="title">Users</h5>
                <div className="row mb-3">
                    <div className="col-lg-4 mb-3">
                        <div className="box">
                            <h6>Total Users</h6>
                            <h4>{userStats?.users_total || '0'}</h4>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <div className="box">
                            <h6>Verified Users</h6>
                            <h4>
                                {userStats?.users_that_have_verify_account ||
                                    '0'}
                            </h4>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <div className="box">
                            <h6>Unverified Users</h6>
                            <h4>
                                {userStats?.users_that_have_not_verify_account ||
                                    '0'}
                            </h4>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <div className="box">
                            <h6>Active Users</h6>
                            <h4>{userStats?.active_user || '0'}</h4>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <div className="box">
                            <h6>Users with BVN</h6>
                            <h4>{userStats?.users_with_bvn || '0'}</h4>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <div className="box">
                            <h6>Users without BVN</h6>
                            <h4>{userStats?.users_without_bvn || '0'}</h4>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <div className="box">
                            <h6>Signed up with Referral</h6>
                            <h4>
                                {userStats?.users_that_signUp_with_referral ||
                                    '0'}
                            </h4>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <div className="box">
                            <h6>With Biometric</h6>
                            <h4>{userStats?.users_with_device_code || '0'}</h4>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <div className="box">
                            <h6>Without Biometrics</h6>
                            <h4>
                                {userStats?.users_without_device_code || '0'}
                            </h4>
                        </div>
                    </div>
                </div>
                <h5 className="title">Transactions</h5>
                <div className="row mb-3">
                    <div className="col-lg-4 mb-3">
                        <div className="box green">
                            <h6>Successful Total</h6>
                            <h4>
                                ₦
                                {formatCurrency(
                                    transactionStats?.userTransactionReport
                                        ?.completed?.total,
                                )}
                            </h4>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <div className="box green">
                            <h6>Successful Count</h6>
                            <h4>
                                {numberWithCommas(
                                    transactionStats?.userTransactionReport
                                        ?.completed?.count,
                                )}
                            </h4>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <div className="box red">
                            <h6>Failed Total</h6>
                            <h4>
                                ₦
                                {formatCurrency(
                                    transactionStats?.userTransactionReport
                                        ?.failed?.total,
                                )}
                            </h4>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <div className="box red">
                            <h6>Failed Count</h6>
                            <h4>
                                {numberWithCommas(
                                    transactionStats?.userTransactionReport
                                        ?.failed?.count,
                                )}
                            </h4>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <div className="box yellow">
                            <h6>Pending Total</h6>
                            <h4>
                                ₦
                                {formatCurrency(
                                    transactionStats?.userTransactionReport
                                        ?.pending?.total,
                                )}
                            </h4>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <div className="box yellow">
                            <h6>Pending Count</h6>
                            <h4>
                                {numberWithCommas(
                                    transactionStats?.userTransactionReport
                                        ?.pending?.count,
                                )}
                            </h4>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <div className="box blue">
                            <h6>Fee Total</h6>
                            <h4>
                                ₦
                                {formatCurrency(
                                    transactionStats?.userTransactionReport?.fee
                                        ?.total,
                                )}
                            </h4>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <div className="box blue">
                            <h6>Fee Count</h6>
                            <h4>
                                {numberWithCommas(
                                    transactionStats?.userTransactionReport?.fee
                                        ?.count,
                                )}
                            </h4>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <div className="box">
                            <h6>Commission</h6>
                            <h4>
                                ₦
                                {formatCurrency(
                                    transactionStats?.userTransactionReport
                                        ?.commission?.total,
                                )}
                            </h4>
                        </div>
                    </div>
                </div>
                <h5 className="title">Summary</h5>
                <div className="row mb-3">
                    <div className="col-lg-4 mb-3">
                        <div className="box">
                            <h6>BVN Summary</h6>
                            <DashBoardPie
                                arr={[
                                    userStats?.users_with_bvn || 0,
                                    userStats?.users_without_bvn || 0,
                                ]}
                                labels={['Users With BVN', 'Users Without BVN']}
                            />
                        </div>
                    </div>
                    <div className="col-lg-8 mb-3">
                        <div className="box">
                            <h6>This is a default data.</h6>
                            <DashboardBar />
                        </div>
                    </div>
                </div>
            </div>
        </PageCover>
    );
};

export default Dashboard;
