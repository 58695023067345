import {apiRequest} from '../../../utils/axiosInstance';

const getLoginHistories = async (filters: string) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/login_histories${filters}`,
    );
    return data.data;
};

const loginHistoryService = {
    getLoginHistories,
};

export default loginHistoryService;
