import React, {useEffect, useState} from 'react';
import {SelectInput, TextInput} from '../BasicInputs';
import {useAppSelector} from '../../redux/hooks';
import agentService from '../../redux/features/agent/agentService';
import {displayError} from '../../utils/errors';

interface props {
    machine: string;
    setMachine: (arg: string) => void;
    location: string;
    setLocation: (arg: String) => void;
    city: string;
    setCity: (arg: String) => void;
    state: string;
    setState: (arg: string) => void;
    details: any;
    close: () => void;
}

function AssignPos({
    machine,
    setMachine,
    location,
    setLocation,
    city,
    setCity,
    state,
    setState,
    details,
    close,
}: props) {
    const [load, setLoad] = useState(false);
    const {token, details: data} = useAppSelector(state => state.auth);
    const [det, setDet] = useState<any>({});

    const filterTerminals = (arr: any) => {
        return arr.filter(
            (a: any) => a.status !== 'Active' && a.label !== null,
        );
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        let payload = {
            terminalId: machine,
            location,
            city,
            state,
            agentId: details.id,
        };
        try {
            setLoad(true);
             await agentService.assignPos(token, payload);
            setLoad(false);
            close();
        } catch (err) {
            setLoad(false);
            displayError(err, true);
        }
    };

    const filter= `/in-active`
    const terminal = async () => {
        try {
            const res = await agentService.getTerminal(token,filter);
            setDet(res);
        } catch (err) {
            setDet([]);
            displayError(err, true);
        }
    };

    console.log(det)

    useEffect(() => {
        terminal();
    }, []);

    return (
        <div className="row">
            <div className="col mb-3">
                <SelectInput
                    option={
                        det &&
                        det?.length > 0 &&
                        det?.map((i: any) => ({
                            label: i.serialNo,
                            value: i.id,
                            status: i.status,
                        }))
                    }
                    val={machine}
                    changeVal={setMachine}
                    label="Select Pos Machine"
                    placeholder="Choose One"
                    required
                />
            </div>
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={location}
                    changeVal={setLocation}
                    label="Location"
                    type="text"
                    placeholder="Address"
                    required
                />
            </div>
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={city}
                    changeVal={setCity}
                    label="City"
                    type="text"
                    placeholder="City"
                    required
                />
            </div>
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={state}
                    changeVal={setState}
                    label="State"
                    type="text"
                    placeholder="State"
                    required
                />
            </div>

            <div className="col-lg-12 text-center">
                <button className="main-btn" onClick={handleSubmit}>
                    {load ? 'Please wait...' : 'Assign'}
                </button>
            </div>
        </div>
    );
}

export default AssignPos;
