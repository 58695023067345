import React, {useEffect, useState} from 'react';
import {SelectInput} from '../BasicInputs';
import {useAppSelector} from '../../redux/hooks';
import agentService from '../../redux/features/agent/agentService';
import {displayError} from '../../utils/errors';

function ApproveTerminalModal({
    agents,
    catId,
    setCatId,
    click,
    loading,
    terminal,
    setTerminal,
}: {
    agents: any;
    catId: string;
    loading: boolean;
    click: () => void;
    setCatId: (arg: string) => void;
    setTerminal: (arg: string) => void;
    terminal: string;
}) {
    const [det, setDet] = useState<any>([]);

    const filter = `/in-active`;
    const fetchTerminal = async () => {
        try {
            const res = await agentService.getTerminal( filter);
            setDet(res);
        } catch (err) {
            setDet([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        fetchTerminal();
    }, []);

    return (
        <div className="row">
            <div className="col-lg- mb-3">
                <SelectInput
                    option={
                        agents &&
                        agents?.length > 0 &&
                        agents?.map((i: any) => ({
                            label: i.name,
                            value: i.id,
                        }))
                    }
                    val={catId}
                    changeVal={setCatId}
                    label="Select Agent Category"
                    placeholder="Choose One"
                    required
                />
            </div>
            <div className="col mb-3">
                <SelectInput
                    option={
                        det &&
                        det?.length > 0 &&
                        det?.map((i: any) => ({
                            label: i.serialNo,
                            value: i.id,
                            status: i.status,
                        }))
                    }
                    val={terminal}
                    changeVal={setTerminal}
                    label="Select Pos Machine"
                    placeholder="Choose One"
                    required
                />
            </div>
            <div className="col-lg-12 text-center">
                <button className="main-btn" onClick={click}>
                    {loading ? 'Please wait...' : 'Assign'}
                </button>
            </div>
        </div>
    );
}

export default ApproveTerminalModal;
