import React from 'react';

function ListingModal({details, submit}: {details: any; submit: () => void}) {
    return (
        <div>
            <div className="row">
                <div className="col-lg-4">
                    <h5>Agent Details</h5>
                    <div className="bvn-details">
                        {' '}
                        <strong>
                            {details?.agent?.firstName} {details?.agent?.lastName}
                        </strong>
                        <strong>{details?.agent?.email}</strong>
                        <strong>{details?.agent?.phone}</strong>
                        <strong>{details?.agent?.businessName}</strong>
                        <strong>{details?.agent?.businessAddress}</strong>
                    </div>
                </div>
                <div className="col-lg-4">
                    <h5>Nin Details</h5>
                    <div className="bvn-details">
                        {' '}
                        <strong>
                            {details?.nin_data?.data?.firstName} {details?.nin_data?.data?.lastName}
                        </strong>
                        <strong>{details?.nin_data?.data?.email}</strong>
                        <strong>{details?.nin_data?.data?.phoneNumber}</strong>
                        <img
                            className="right"
                            src={`data:image/jpeg;base64,${details?.nin_data?.data?.photo}`}
                            alt={''}
                            style={{
                                width: '100px',
                                height: '100px',
                            }}
                        />
                    </div>
                </div>
                <div className="col-lg-4">
                    <h5>Bvn Details</h5>
                    <div className="bvn-details">
                        {' '}
                        <strong>
                            {details?.bvn_data?.FirstName}{' '}
                            {details?.bvn_data?.LastName}
                        </strong>
                        <strong>{details?.bvn_data?.Email}</strong>
                        <strong>{details?.bvn_data?.PhoneNumber1}</strong>
                        <img
                            className="right"
                            src={`data:image/jpeg;base64,${details?.bvn_data?.Base64Image}`}
                            alt={''}
                            style={{
                                width: '100px',
                                height: '100px',
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="col-lg-12 mt-4 text-center">
                <button className="main-btn" onClick={submit}>
                    {'Approve'}
                </button>
            </div>
        </div>
    );
}

export default ListingModal;
