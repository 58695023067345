import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import cardService from '../../../redux/features/card/cardService';
import {displayError} from '../../../utils/errors';
import LoadTable from '../../../component/Loaders/LoadTable';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import dateFormat from 'dateformat';
import Paginate from '../../../component/Paginate';
import {BiTrash} from 'react-icons/bi';
import PageCover from '../../../component/PageCover';

export default function CardRequest() {
    const [openFilter, setOpenFilter] = useState(false);
    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);

    let filters = `?page=${page}&per_page=${limit} `;

    const getCardRequests = async () => {
        try {
            setLoad(true);
            let res = await cardService.getCardRequests(filters);
            setLoad(false);
            setList(res);
        } catch (err) {
            setLoad(false);
            setList([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getCardRequests();
    }, []);

    const deleteRequest = async (id: any) => {
        if (window.confirm('Are you sure you want to delete this request?')) {
            id.preventDefault();
            try {
                const res = await cardService.deleteBatch('card_requests', id);
                if (res) {
                    getCardRequests();
                }
            } catch (err) {
                displayError(err, true);
            }
        }
    };

    const submitBatch = async () => {
        alert('function not working');
    };

    return (
        <PageCover page="Card Request">
            <div>
                <BreadCrumbTitle
                    title="Card Requests"
                    dataCount={list?.meta?.total}
                    button="Submit Batch"
                    buttonClick={() => submitBatch}
                />

                <div className="basic-card mt-3">
                    <div className="head"></div>
                    <div className="body">
                        <div className="table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Customer Name</th>
                                            <th>Status</th>
                                            <th>Account Number</th>
                                            <th>Request Reference</th>
                                            <th>Account Name</th>
                                            <th>Account Type</th>
                                            <th>Batch Id</th>
                                            <th>Expiration Date</th>
                                            <th>Phone Number</th>
                                            <th>Address</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    {!load && list?.data?.length > 0 && (
                                        <tbody>
                                            {list?.data?.map(
                                                (tr: any, i: number) => (
                                                    <tr key={tr.id}>
                                                        <td>
                                                            {limit *
                                                                (page - 1) +
                                                                i +
                                                                1}
                                                        </td>
                                                        <td>
                                                            {tr.user
                                                                ? tr.user
                                                                      .username
                                                                : 'not given'}
                                                        </td>
                                                        <td>{tr.status}</td>
                                                        <td>
                                                            {tr.account_number}
                                                        </td>
                                                        <td>{tr['req-ref']}</td>
                                                        <td>
                                                            {tr.account_name
                                                                ? tr.account_name
                                                                : 'not given'}
                                                        </td>
                                                        <td>
                                                            {tr.account_type
                                                                ? tr.account_type
                                                                : 'not given'}
                                                        </td>
                                                        <td>
                                                            {tr.batch_id
                                                                ? tr.batch_id
                                                                : 'not given'}
                                                        </td>
                                                        <td>
                                                            {tr.expiry_date
                                                                ? tr.expiry_date
                                                                : 'not given'}
                                                        </td>
                                                        <td>
                                                            {tr.user
                                                                ? tr.user
                                                                      .phone_number
                                                                : 'not given'}
                                                        </td>
                                                        <td>
                                                            {tr.user
                                                                ? tr.user
                                                                      .address
                                                                : 'not given'}
                                                        </td>
                                                        <td>
                                                            <i
                                                                className="fa fa-trash-o"
                                                                style={{
                                                                    padding:
                                                                        '0 .25em',
                                                                }}
                                                                onClick={() =>
                                                                    deleteRequest(
                                                                        tr.id,
                                                                    )
                                                                }>
                                                                <BiTrash />
                                                            </i>
                                                        </td>
                                                    </tr>
                                                ),
                                            )}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                            {!load && list?.meta?.last_page > 1 && (
                                <Paginate
                                    currentPage={page}
                                    totalCount={list?.meta?.total}
                                    pageSize={limit}
                                    lastPage={list?.meta?.last_page}
                                    onSelect={(p: number) => setPage(Number(p))}
                                    onNext={(p: number) => setPage(p)}
                                    onPrev={(p: number) => setPage(p)}
                                    changeLimit={(p: string) =>
                                        setLimit(Number(p))
                                    }
                                />
                            )}
                            {load && <LoadTable />}
                        </div>
                    </div>
                </div>
            </div>
        </PageCover>
    );
}
