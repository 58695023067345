import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import LoadTable from '../../Loaders/LoadTable';
import dateFormat from 'dateformat';
import {FiPrinter} from 'react-icons/fi';
import CustomersDetails from './CustomersDetails';
import {useReactToPrint} from 'react-to-print';

const Details = ({
    acc,
    details,
    load,
    name,
    documents,
    setModalState,
    setModal,
}: {
    acc: string;
    details: any;
    load: boolean;
    name: String;
    documents: any;
    setModalState: (arg: any) => void;
    setModal: (arg: boolean) => void;
}) => {
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const contentRef = useRef(null);

    const reactToPrintFn = useReactToPrint({
        content: () => contentRef.current,
        onAfterPrint: () => {
            setShow(false);
            setLoading(false);
        },
    });

    useEffect(() => {
        if (show) {
            reactToPrintFn();
        }
    }, [show]);

    const handlePrint = () => {
        setLoading(true);
        setShow(true);
    };

    return (
        <div className="">
            <div className="d-flex justify-content-between">
                <button
                    className="main-btn"
                    onClick={() =>
                        navigate('/dashboard/transactions', {
                            state: {accNo: acc},
                        })
                    }>
                    View Transactions
                </button>
                <div className="d-flex gap-3">
                    {/* <button
                        className="main-btn"
                        onClick={() => {
                            setModalState({function: 'edit', state: 'edit'});
                            setModal(true);
                        }}>
                        Edit Profile
                    </button> */}
                    <button className="main-btn" onClick={handlePrint}>
                        <span className="d-flex mr-2">
                            {loading
                                ? 'Loading...'
                                : "Generate Customer's Details"}
                        </span>{' '}
                        <div className="mr-2"></div>
                        <FiPrinter />
                    </button>
                </div>
            </div>
            {load ? (
                <div className="mt-4">
                    {' '}
                    <LoadTable />
                </div>
            ) : (
                <div className="">
                    <div className="row">
                        <div className="col-lg-6 customer-details">
                            <div className="customer-details-content">
                                <span>Full Name</span>
                                <strong>
                                    {details?.first_name} {details?.last_name}
                                </strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Account Name</span>
                                <strong>{name}</strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Account Number</span>
                                <strong>{acc}</strong>
                            </div>
                            <div className="customer-details-content">
                                <span>User Name</span>
                                <strong>{details?.username}</strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Email</span>
                                <strong>{details?.email}</strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Phone Number</span>
                                <strong>{details?.phone_number}</strong>
                            </div>
                            <div className="customer-details-content">
                                <span>BVN</span>
                                <strong>
                                    {details?.bvn?.substr(0, 4)}*******
                                </strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Date of Birth</span>
                                <strong>{details?.date_of_birth}</strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Gender</span>
                                <strong>{details?.gender}</strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Referral ID</span>
                                <strong>{details?.referral_id}</strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Last Login</span>
                                <strong>
                                    {dateFormat(details?.last_login)}
                                </strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Customer Type</span>
                                <strong>
                                    {details?.customer_type || '--'}
                                </strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Category</span>
                                <strong>{details?.category || '--'}</strong>
                            </div>
                            <div className="customer-details-content">
                                <span> Status</span>
                                <strong>
                                    {details?.locked_status === '0'
                                        ? 'Active'
                                        : 'Deactivated'}
                                </strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Activity Status</span>
                                <strong>{details?.activity || '--'}</strong>
                            </div>
                            <div className="customer-details-content">
                                <span>KYC Level</span>
                                <strong>
                                    {details?.kyc_level?.level_name} (Level{' '}
                                    {details.kyc_level?.level})
                                </strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Marital Status</span>
                                <strong>{details?.marital_status}</strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Address</span>
                                <strong>{details?.address}</strong>
                            </div>
                            <div className="customer-details-content">
                                <span>Date Joined</span>
                                <strong>
                                    {dateFormat(details?.date_join)}
                                </strong>
                            </div>
                        </div>
                        <div className="col-lg-6"></div>
                    </div>
                </div>
            )}
            {show && (
                <div style={{position: 'absolute', left: '-9999px', top: '0'}}>
                    <CustomersDetails
                        contentRef={contentRef}
                        details={details}
                        document={documents}
                    />
                </div>
            )}
        </div>
    );
};

export default Details;
