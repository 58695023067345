import React from 'react';
import dateFormat from 'dateformat';

function LockHistories({lock}: {lock: any}) {
    return (
        <div>
            <div className="basic-card">
                <div className="body">
                    <div className="table-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Locked by</th>
                                        <th>Reason</th>
                                        <th>Remark</th>
                                        <th>Action Performed</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>

                                {lock.length > 0 && (
                                    <tbody>
                                        {lock?.map((props: any, i: number) => (
                                            <tr key={props.id}>
                                                <td> {i + 1} </td>
                                                <td>
                                                    {props.action_by?.username}{' '}
                                                    ({props.action_by?.email})
                                                </td>
                                                <td>{props?.reason}</td>
                                                <td>{props?.remark}</td>
                                                <td>
                                                    {props?.action === 1
                                                        ? 'Locked'
                                                        : 'Unlock'}
                                                </td>

                                                <td>
                                                    {dateFormat(
                                                        props?.action?.date,
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LockHistories;
