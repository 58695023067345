import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../redux/hooks';
import {displayError} from '../../utils/errors';
import KYCService from '../../redux/features/kyc/kycService';

function KycDetails({props,close}: {props: any,close:()=>void}) {
    const {token} = useAppSelector(state => state.auth);
    const [list, setList] = useState<any>([]);

    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const fetchDetails = async () => {
        try {
            const res = await KYCService.getKycDetails(token, props.user_id);
            setList(res);
        } catch (err) {
            displayError(err, true);
        }
    };

    useEffect(() => {
        fetchDetails();
    }, []);


    if (list?.document?.length < 1) {
        return (
            <>
                <div className="px-5">
                    <h5>
                        Name: {props?.user?.first_name.toUpperCase()}{' '}
                        {props?.user?.last_name.toUpperCase()}
                    </h5>
                </div>
                <div className="px-5 py-4">
                    <h5>No document found</h5>
                </div>
            </>
        );
    } else {
        return (
            <div>
                <div className="px-5">
                    <h5>
                        Name: {props?.user?.first_name.toUpperCase()}{' '}
                        {props?.user?.last_name.toUpperCase()}
                    </h5>
                </div>
                <div className="row px-5">
                    <div className="col-md-6">
                        <h6>
                            Current Level:{' '}
                            <span style={{color: 'red'}}>
                                {props?.current_level?.level_name.toUpperCase()}
                            </span>
                        </h6>
                    </div>
                    <div className="col-md-6">
                        <h6>
                            Proposed Level:{' '}
                            <span style={{color: 'green', fontSize: '18px'}}>
                                {props?.new_level?.level_name.toUpperCase()}
                            </span>
                        </h6>
                    </div>
                </div>
                <div className="px-3 py-4">
                    <div>
                        {list?.map((content: any) => (
                            <div
                                key={content?.document?.name}
                                className="py-3 d-flex justify-content-between align-items-center">
                                <div style={{width: '30% important'}}>
                                    <h5>Document Name</h5>
                                    <h6 className="">
                                        {content?.document?.name}
                                    </h6>
                                </div>
                                <div style={{width: '30% important'}}>
                                    <h5>Approved By</h5>
                                    {content?.approval_details ? (
                                        <h6>
                                            {
                                                content?.approval_details
                                                    ?.username
                                            }
                                        </h6>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div style={{width: '30%'}} className=" ">
                                    <h6
                                        className="pr-3"
                                        style={{
                                            color:
                                                content.approval_status ===
                                                'Pending'
                                                    ? 'blue'
                                                    : content.approval_status ===
                                                      'Approved'
                                                    ? 'green'
                                                    : 'red',
                                        }}>
                                        {content.approval_status}
                                    </h6>
                                    {content.document_url.substr(0, 4) ===
                                    'http' ? (
                                        <button
                                            onClick={() =>
                                                openInNewTab(
                                                    content.document_url,
                                                )
                                            }
                                            className="btn btn-primary">
                                            View
                                        </button>
                                    ) : (
                                        <p>{content.document_url}</p>
                                    )}
                                </div>
                            </div>
                        ))}

                        {list?.numOfPending &&
                        (list?.numOfPending?.total > 0 ||
                            list?.status === 'Approved') ? (
                            <></>
                        ) : (
                            <button
                                type="button"
                                onClick={close}
                                className="btn btn-primary mt-3 mx-5">
                                {'Submit Approval'}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default KycDetails;
