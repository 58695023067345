import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import customerServices from '../../../redux/features/customer/customerService';
import {useAppSelector} from '../../../redux/hooks';
import {displayError} from '../../../utils/errors';
import {SelectInput} from '../../../component/BasicInputs';
import TabComponent from '../../../component/TabComponent';
import Details from '../../../component/Customer/Details/Details';
import Documents from '../../../component/Customer/Details/Documents';
import LoginHistory from '../../../component/Customer/Details/LoginHistory';
import KycUpgradeRequest from '../../../component/Customer/Details/KycUpgradeRequest';
import UploadDocuments from '../../../component/Customer/Details/UploadDocuments';
import Verification from '../../../component/Customer/Details/Verification';
import NinVerificationDetails from '../../../component/Customer/Details/NinVerificationDetails';
import FaceVerificationDetails from '../../../component/Customer/Details/FaceVerificationDetails';
import Devices from '../../../component/Customer/Details/Devices';

function TransCustomersDetail() {
    const {token} = useAppSelector(state => state.auth);
    const [load, setLoad] = useState(false);
    const [details, setDetails] = useState<any>([]);
    const [acc, setAcc] = useState('');
    const [device, setDevice] = useState<any>([]);

    const [login, setLogin] = useState<any>([]);
    const [kyc, setKyc] = useState<any>([]);
    const [documents, setDocuments] = useState<any>([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const [name, setName] = useState('');

    const [key, setKey] = useState('details');

    let id = useLocation().state;

    const filter = `?page=${page}&per_page=${limit}`;

    const fetchUser = async () => {
        try {
            setLoad(true);
            let res = await customerServices.getOneCustomer(token, id);
            setLoad(false);

            if (res?.data) {
                setDetails(res.data);
                setAcc(res.data?.bank_account[0]?.account_number);
            }
        } catch (err) {
            setLoad(false);
            setDetails([]);
            displayError(err, true);
        }
    };

    const userDevice = async () => {
        setLoad(true);
        try {
            let res = await customerServices.userDevices(token, id);
            setLoad(false);
            setDevice(res);
        } catch (err) {
            displayError(err, true);
            setLoad(false);
        }
    };

    const fetchLogin = async () => {
        setLoad(true);
        try {
            let res = await customerServices.userLoginHistory(
                token,
                id,
                filter,
            );
            setLoad(false);
            if (res?.data) {
                setLogin(res);
            }
        } catch (err) {
            setDetails([]);
            displayError(err, true);
            setLoad(false);
        }
    };

    const fetchDocuments = async () => {
        try {
            let res = await customerServices.userDocument(token, id);

            if (res) {
                setDocuments(res);
            }
        } catch (err) {
            setDetails([]);
            displayError(err, true);
        }
    };

    const fetchKyc = async () => {
        try {
            let res = await customerServices.userKycDocument(token, id);

            if (res) {
                setKyc(res);
            }
        } catch (err) {
            setDetails([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchUser();
        userDevice();
        fetchDocuments();
        fetchKyc();
    }, [id]);

    useEffect(() => {
        fetchLogin();
    }, [filter]);

    useEffect(() => {
        if (acc) {
            setName(
                details?.bank_account.find((e: any) => e.account_number === acc)
                    ?.account_name,
            );
        }
    }, [acc]);

    return (
        <div>
            <BreadCrumbTitle
                title={
                    details?.first_name
                        ? `${details?.first_name} ${details?.last_name}`
                        : 'Customer Details'
                }
            />

            {details?.bank_account?.length !== 1 && (
                <div className="detail-card-align">
                    <SelectInput
                        val={acc}
                        changeVal={setAcc}
                        option={details?.bank_account?.map((a: any) => {
                            return {
                                label: a.account_number,
                                value: a.account_number,
                            };
                        })}
                        placeholder="Select Account"
                        label="Select Account"
                    />
                </div>
            )}

            <div className="mt-4">
                <TabComponent
                    arr={[
                        {
                            id: 1,
                            eventKey: 'details',
                            title: 'Customer Details',
                            child: (
                                <Details
                                    acc={acc}
                                    details={details}
                                    load={load}
                                    name={name}
                                    documents={documents}
                                />
                            ),
                        },
                        {
                            id: 2,
                            eventKey: 'documents',
                            title: 'Documents',
                            child: <Documents documents={documents} />,
                        },
                        {
                            id: 3,
                            eventKey: 'login-history',
                            title: 'Login History',
                            child: (
                                <LoginHistory
                                    login={login}
                                    page={page}
                                    setPage={setPage}
                                    setLimit={setLimit}
                                    limit={limit}
                                    load={load}
                                />
                            ),
                        },
                        {
                            id: 4,
                            eventKey: 'kyc-upgrade-request',
                            title: 'KYC Upgrade Request',
                            child: <KycUpgradeRequest kyc={kyc} />,
                        },
                        {
                            id: 5,
                            eventKey: 'upload-document',
                            title: 'Upload Document',
                            child: <UploadDocuments details={details} />,
                        },
                        {
                            id: 6,
                            eventKey: 'verification',
                            title: 'Verification',
                            child: <Verification details={details} />,
                        },
                        {
                            id: 7,
                            eventKey: 'nin-details',
                            title: 'Nin Details',
                            child: (
                                <NinVerificationDetails
                                    details={details}
                                    load={load}
                                />
                            ),
                        },
                        {
                            id: 8,
                            eventKey: 'face-details',
                            title: 'Face Details',
                            child: (
                                <FaceVerificationDetails details={details} />
                            ),
                        },
                        {
                            id: 9,
                            eventKey: 'device',
                            title: 'Devices',
                            child: (
                                <Devices
                                    device={device}
                                    page={page}
                                    setPage={setPage}
                                    setLimit={setLimit}
                                    limit={limit}
                                    load={load}
                                />
                            ),
                        },
                    ]}
                    activeKey={key}
                    setKey={setKey}
                />
            </div>
        </div>
    );
}

export default TransCustomersDetail;
