import axios from 'axios';
import config from './config';
import {decryptToken} from './encrypt';

const apiRequest = (
    service: 'baseUrl3Admin' | 'baseUrl' | 'posUrl' | 'base' | 'url'|'posUrl2',
) => {
    const axiosInstance = axios.create({
        baseURL: `${config[service]}`,
    });

    axiosInstance.interceptors.request.use(
        async config => {
            const token =
                process.env.REACT_APP_ENV === 'production'
                    ? decryptToken(
                          sessionStorage.getItem('@apptoken'),
                          process.env.REACT_APP_AES_KEY,
                          process.env.REACT_APP_AES_IV,
                      )
                    : '';
            config.headers['Content-Type'] = 'application/json';
            config.headers.Accept = 'application/json';
            config.headers['f-x-token'] = token;
            config.headers['Adminauthkey'] =
                process.env.REACT_APP_ADMIN_AUTH_KEY;
            return config;
        },
        error => Promise.reject(error),
    );

    return axiosInstance;
};

export {apiRequest};
