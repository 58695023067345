import {authHeader} from '../../../utils/headers';
import {apiRequest} from '../../../utils/axiosInstance';

const allDispute = async (token: string, filters: string) => {
    const {data} = await apiRequest('posUrl').get(`/dispute${filters}`, {
        headers: authHeader(token),
    });
    return data.data;
};

const getTicketDetails = async (token: string, id: number) => {
    const {data} = await apiRequest('posUrl').get(
        `/dispute/get-dispute-replies/${id}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const postComment = async (token: string, payload:any, ) => {
    const {data} = await apiRequest('posUrl').post(
        `/dispute-comment`,
        payload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const closeTicket = async (token: string, payload: any) => {
    const {data} = await apiRequest('posUrl').post(
        `/dispute/update`,
        payload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const disputeService = {
    allDispute,
    getTicketDetails,
    postComment,
    closeTicket,
};

export default disputeService;
