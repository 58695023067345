import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import reportService from '../../../redux/features/reports/reportService';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {displayError} from '../../../utils/errors';
import {formatCurrency} from '../../../utils/numberFormat';
import {
    getTransactionStats,
    getUserStats,
} from '../../../redux/features/basic/basicSlice';
import dateFormat from 'dateformat';
import PageCover from '../../../component/PageCover';

function ReportSummary() {
    const {token} = useAppSelector(state => state.auth);
    const {userStats, transactionStats} = useAppSelector(state => state.basic);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [startDate] = useState(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    );
    const [endDate] = useState(
        new Date(new Date().setDate(new Date().getDate() + 1)),
    );

    useEffect(() => {
        dispatch(
            getUserStats({
                startDate: dateFormat(startDate, 'yyyy-mm-dd'),
                endDate: dateFormat(endDate, 'yyyy-mm-dd'),
            }),
        );
        dispatch(
            getTransactionStats({
                startDate: dateFormat(startDate, 'yyyy-mm-dd'),
                endDate: dateFormat(endDate, 'yyyy-mm-dd'),
            }),
        );
    }, [startDate, endDate]);

    const [commission, setCommission] = useState<any>([]);
    const [trans, setTrans] = useState<any>([]);
    const [saveInvest, setSaveInvest] = useState<any>([]);
    const [saveAna, setSaveAna] = useState<any>([]);

    const filter = `?from=${startDate}&to=${endDate}`;

    const getCommission = async () => {
        try {
            const res = await reportService.getAllTimeCommission(token, filter);
            if (res) {
                setCommission(res);
            }
        } catch (err) {
            displayError(err, true);
        }
    };
    const getTrans = async () => {
        try {
            const res = await reportService.getTotalTransactions(token, filter);
            if (res) {
                setTrans(res);
            }
        } catch (err) {
            displayError(err, true);
        }
    };

    const getSaveInvest = async () => {
        try {
            const res = await reportService.getSavingsAndInvestments(
                token,
                filter,
            );
            if (res) {
                setSaveInvest(res);
            }
        } catch (err) {
            displayError(err, true);
        }
    };

    const getSaveAna = async () => {
        try {
            const res = await reportService.getUserSavingsAnalysis(
                token,
                filter,
            );
            if (res) {
                setSaveAna(res);
            }
        } catch (err) {
            displayError(err, true);
        }
    };

    const getUserReport = async () => {
        try {
            const res = await reportService.getUserReport(token, filter);
            if (res) {
                setSaveAna(res);
            }
        } catch (err) {
            displayError(err, true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getCommission();
        getTrans();
        getSaveAna();
        getSaveInvest();
        getUserReport();
    }, []);

    return (
        <PageCover page="Report Summary">
            <div className="dash-stats">
                <div>
                    <div className="d-flex mb-3 justify-content-between">
                        <h5 className="title">
                            User Management Summary Reports
                        </h5>
                        <button
                            onClick={() =>
                                navigate('/dashboard/report/customer-report')
                            }
                            className="main-btn">
                            View Report Summary
                        </button>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-4 mb-3">
                            <div className="box">
                                <h6>Verified Users</h6>
                                <h4>
                                    {userStats?.users_that_have_verify_account ||
                                        '--'}
                                </h4>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="box">
                                <h6>Unverified Users</h6>
                                <h4>
                                    {userStats?.users_that_have_not_verify_account ||
                                        '--'}
                                </h4>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="box">
                                <h6>All Users</h6>
                                <h4>{userStats?.users_total || '--'}</h4>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="box">
                                <h6>Referrals Sign ups</h6>
                                <h4>
                                    {userStats?.users_that_signUp_with_referral ||
                                        '--'}
                                </h4>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="box">
                                <h6>Users with BVN</h6>
                                <h4>{userStats?.users_with_bvn || '--'}</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="d-flex mb-3 justify-content-between">
                        <h5 className="title">Transactions Summary Reports</h5>
                        <button
                            onClick={() =>
                                navigate('/dashboard/report/transaction-report')
                            }
                            className="main-btn">
                            View Report Summary
                        </button>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-4 mb-3">
                            <div className="box">
                                <h6>Daily Transactions</h6>
                                <h4>
                                    N
                                    {formatCurrency(
                                        trans?.data?.summedAmount,
                                    ) || '--'}
                                </h4>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="box">
                                <h6>Total Transaction</h6>
                                <h4>
                                    {trans?.data?.totalTransactions || '--'}
                                </h4>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 mb-3">
                        <div className="box">
                            <h6>Unverified Users</h6>
                            <h4>
                                {userStats?.users_that_have_not_verify_account ||
                                    '--'}
                            </h4>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <div className="box">
                            <h6>Active Users</h6>
                            <h4>{userStats?.active_user || '--'}</h4>
                        </div>
                    </div>*/}
                    </div>
                </div>

                <div>
                    <div className="d-flex mb-3">
                        <h5 className="title">Group Savings Analysis</h5>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-4 mb-3">
                            <div className="box">
                                <h6>Total Group</h6>
                                <h4>{userStats?.users_total || '--'}</h4>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="box">
                                <h6>Total Closed Group</h6>
                                <h4>
                                    {userStats?.users_that_have_verify_account ||
                                        '--'}
                                </h4>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="box">
                                <h6>Total Open Group</h6>
                                <h4>
                                    {userStats?.users_that_have_not_verify_account ||
                                        '--'}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="d-flex mb-3 justify-content-between">
                        <h5 className="title">
                            Commissions / Fees Summary Reports
                        </h5>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-4 mb-3">
                            <div className="box">
                                <h6>All Time Commission Paid</h6>
                                <h4>{commission?.data?.totalCommissions}</h4>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="box">
                                <h6>All Time Referral Bonuses</h6>
                                <h4>{commission?.data?.summedAmount}</h4>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="box">
                                <h6>All Time Sign Up Bonuses</h6>
                                <h4>
                                    {userStats?.users_that_have_not_verify_account ||
                                        '--'}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className=" mb-3  ">
                        <h5 className="title">
                            Saving and Investment Analysis
                        </h5>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-4 mb-3">
                            <div className="box">
                                <h6>Total Investments</h6>
                                <h4>{saveInvest?.data?.total_investment}</h4>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="box">
                                <h6>Total Subscription</h6>
                                <h4>
                                    {saveInvest?.data?.total_subscription ||
                                        '--'}
                                </h4>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="box">
                                <h6>Total Savings</h6>
                                <h4>
                                    {saveInvest?.data?.total_savings || '--'}
                                </h4>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="box">
                                <h6>Total Merchant</h6>
                                <h4>
                                    {saveInvest?.data?.total_merchant || '--'}
                                </h4>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="box">
                                <h6>Total Savings Group</h6>
                                <h4>
                                    {saveInvest?.data?.total_savings_group ||
                                        '--'}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className=" mb-3  ">
                        <h5 className="title">User Savings Analysis</h5>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-4 mb-3">
                            <div className="box">
                                <h6>Total Savings</h6>
                                <h4>{saveAna?.data?.total_savings || '--'}</h4>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="box">
                                <h6>Total Active Savings</h6>
                                <h4>
                                    {saveAna?.data?.total_active_savings ||
                                        '--'}
                                </h4>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="box">
                                <h6>Total Inactive Savings</h6>
                                <h4>
                                    {saveAna?.data?.total_in_active_savings ||
                                        '--'}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageCover>
    );
}

export default ReportSummary;
