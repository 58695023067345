import React, {useEffect, useState} from 'react';
import {displayError} from '../../../../utils/errors';
import LoadTable from '../../../../component/Loaders/LoadTable';
import dateFormat from 'dateformat';
import BreadCrumbTitle from '../../../../component/BreadCrumbTitle';
import ModalComponent from '../../../../component/ModalComponent';
import {toast} from 'react-toastify';
import Paginate from '../../../../component/Paginate';
import PageCover from '../../../../component/PageCover';
import posService from '../../../../redux/features/pos/posService';
import PopModal from '../../../../component/PosSettings/PopModal';
import {BiPencil} from 'react-icons/bi';

function BankChannels() {
    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>([]);

    const [details, setDetails] = useState<any>([]);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [option, setOption] = useState<any>([]);

    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);

    const filters = `?page=${page}&limit=${limit}`;

    const cardSettingList = async () => {
        setLoad(true);
        try {
            const res = await posService.bankChannels();
            setList(res);
            setLoad(false);
        } catch (err) {
            displayError(err, true);
            setLoad(false);
        }
    };

    const cardChannelsList = async () => {
        try {
            const res = await posService.cardChannels();
            setOption(res);
        } catch (err) {
            displayError(err, true);
        }
    };

    const Approval = async () => {
        setLoading(true);
        const payload = {channel: value};
        try {
            await posService.updateBankChannels(details, payload);
            cardSettingList();
            setLoading(false);
            toast.success('Approved');
            setOpen(false);
        } catch (err) {
            displayError(err, true);
            setLoading(false);
        }
    };

    useEffect(() => {
        cardSettingList();
        window.scrollTo(0, 0);
        cardChannelsList();
    }, [filters]);

    return (
        <PageCover page="Bank Channels">
            <div>
                <BreadCrumbTitle
                    title="Pos Bvn Approval"
                    dataCount={list?.total}
                />
                <div className="basic-card mt-3">
                    <div className="head"></div>
                    <div className="body">
                        <div className="table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Description</th>
                                            <th>Name</th>
                                            <th>Channel</th>
                                            <th>Created At</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    {!load && list?.length > 0 && (
                                        <tbody>
                                            {list.map((tr: any, i: number) => (
                                                <tr key={tr?.id}>
                                                    <td>
                                                        {' '}
                                                        {limit * (page - 1) +
                                                            i +
                                                            1}
                                                    </td>

                                                    <td>{tr.description}</td>
                                                    <td>{tr.name}</td>
                                                    <td>{tr.channel}</td>
                                                    <td>
                                                        {dateFormat(
                                                            tr?.created_at,
                                                        )}
                                                    </td>
                                                    <td className="d-flex">
                                                        <span
                                                            role="button"
                                                            onClick={() => {
                                                                setOpen(true);
                                                                setDetails(
                                                                    tr.id,
                                                                );
                                                            }}>
                                                            {' '}
                                                            <BiPencil />
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                            {!load && list?.last_page > 1 && (
                                <Paginate
                                    currentPage={page}
                                    totalCount={list.total}
                                    pageSize={limit}
                                    onSelect={(p: number) => setPage(Number(p))}
                                    onNext={(p: number) => setPage(p)}
                                    onPrev={(p: number) => setPage(p)}
                                    changeLimit={(p: string) =>
                                        setLimit(Number(p))
                                    }
                                />
                            )}
                        </div>
                    </div>
                    {load && <LoadTable />}
                </div>

                <ModalComponent
                    open={open}
                    close={() => setOpen(false)}
                    title="Update Card Channel">
                    <PopModal
                        value={value}
                        loading={loading}
                        setValue={setValue}
                        options={option}
                        approve={() => {
                            Approval();
                        }}
                    />
                </ModalComponent>
            </div>
        </PageCover>
    );
}

export default BankChannels;
