import React, {useState} from 'react';
import {TextInput} from '../BasicInputs';
import cardService from '../../redux/features/card/cardService';
import {useAppSelector} from '../../redux/hooks';
import {displayError} from '../../utils/errors';

interface props {
    setClose: () => void;
}

function AddCardBatch({setClose}: props) {
    const { details} = useAppSelector(state => state.auth);

    const [batchReference, setBatchReference] = useState('');
    const [adminId, setAdminId] = useState('');
    const [region, setRegion] = useState('');

    const submitHandler = async (e: any) => {
        e.preventDefault();
        let payload = {
            batch_ref: batchReference,
            status: 'pending',
            region,
            admin_id: adminId,
        };
        try {
            const res = await cardService.createCardBatchConfig(
                
                payload,
                {aes_key: details.aes_key, aes_iv: details.aes_iv},
            );
            if (res) {
                setClose();
            }
        } catch (err) {
            displayError(err, true);
        }
    };

    return (
        <div className="row">
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={batchReference}
                    changeVal={setBatchReference}
                    label="Batch Reference"
                    placeholder="Batch reference"
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={adminId}
                    changeVal={setAdminId}
                    label="Admin Id"
                    placeholder="Admin Id"
                />
            </div>
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={region}
                    changeVal={setRegion}
                    label="Region"
                    placeholder="Region"
                />
            </div>
            <div className="col-lg-12 text-center">
                <button onClick={submitHandler} className="main-btn">
                    Create
                </button>
            </div>
        </div>
    );
}

export default AddCardBatch;
