import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import customerServices from '../../../redux/features/customer/customerService';
import {displayError} from '../../../utils/errors';
import {SelectInput, TextInput} from '../../../component/BasicInputs';
import TabComponent from '../../../component/TabComponent';
import Details from '../../../component/Customer/Details/Details';
import Documents from '../../../component/Customer/Details/Documents';
import LoginHistory from '../../../component/Customer/Details/LoginHistory';
import KycUpgradeRequest from '../../../component/Customer/Details/KycUpgradeRequest';
import UploadDocuments from '../../../component/Customer/Details/UploadDocuments';
import Verification from '../../../component/Customer/Details/Verification';
import NinVerificationDetails from '../../../component/Customer/Details/NinVerificationDetails';
import FaceVerificationDetails from '../../../component/Customer/Details/FaceVerificationDetails';
import Devices from '../../../component/Customer/Details/Devices';
import LockHistories from '../../../component/Customer/Details/LockHistories';
import EditCustomer from '../../../component/Customer/Details/EditCustomer';
import ModalComponent from '../../../component/ModalComponent';
import SecurityOtp from '../../../component/SecurityOtp';
import {toast} from 'react-toastify';
import {useAppSelector} from '../../../redux/hooks';
import Accounts from '../../../component/Customer/Details/Accounts';

function TransCustomersDetail() {
    const id = useLocation().state;

    const {details: data} = useAppSelector(state => state.auth);

    const [load, setLoad] = useState(false);
    const [details, setDetails] = useState<any>([]);
    const [acc, setAcc] = useState('');
    const [device, setDevice] = useState<any>([]);

    const [login, setLogin] = useState<any>([]);
    const [kyc, setKyc] = useState<any>([]);
    const [documents, setDocuments] = useState<any>([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const [name, setName] = useState('');
    const [lock, setLock] = useState<any>([]);

    const [key, setKey] = useState('details');

    const [otp, setOtp] = useState('');
    const [modal, setModal] = useState(false);
    const [modalState, setModalState] = useState<any>({});
    const [otpLoad, setOtpLoad] = useState(false);

    // Edit CUSTOMER
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gender, setGender] = useState('');
    const [number, setNumber] = useState('');
    const [otherName, setOtherName] = useState('');
    const [address, setAddress] = useState('');
    const [maritalStatus, setMaritalStatus] = useState('');
    const [dob, setDob] = useState(new Date());

    //Edit Account
    const [editAccount, setEditAccount] = useState('');
    const [call, setCall] = useState(0);
    const [listAccounts, setListAccounts] = useState<any>([]);

    const filter = `?page=${page}&per_page=${limit}`;

    const fetchUser = async () => {
        try {
            setLoad(true);
            let res = await customerServices.getOneCustomer(id);
            setLoad(false);

            if (res?.data) {
                setDetails(res.data);
                setAcc(res.data?.bank_account[0]?.account_number);
            }
        } catch (err: any) {
            setLoad(false);
            setDetails([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        setEmail(details?.email);
        setFirstName(details?.first_name);
        setLastName(details?.last_name);
        setOtherName(details?.others_name);
        setEmail(details?.email);
        setAddress(details?.address);
        setMaritalStatus(details?.marital_status);
        setGender(details?.gender);
        setNumber(details?.phone_number);
        setDob(details?.date_of_birth);
    }, [details?.id]);

    const createProfileRequest = async () => {
        const payload = {
            user_id: details?.id,
            email,
            phone_number: number,
            first_name: firstName,
            last_name: lastName,
            others_name: otherName,
            address,
            date_of_birth: dob,
            gender,
            marital_status: maritalStatus,
        };
        try {
            setOtpLoad(true);
            await customerServices.createProfileRequest(payload, otp, {
                aes_key: data.aes_key,
                aes_iv: data.aes_iv,
            });
            setOtpLoad(false);
            toast.success('Submitted for Approval');
            setOtp('');
            setModal(false);
        } catch (err: any) {
            setOtpLoad(false);
            displayError(err, true);
            setOtp('');
        }
    };

    const changeAccount = async () => {
        setOtpLoad(true);
        try {
            await customerServices.editBankAccounts(
                call,
                {account_name: editAccount},
                otp,
                {
                    aes_key: data.aes_key,
                    aes_iv: data.aes_iv,
                },
            );
            setOtpLoad(false);
            setOtp('');
            toast.success('Account Name Changed Successfully');
            getAccounts();
            setModal(false);
        } catch (err) {
            displayError(err, true);
            setOtpLoad(false);
            setOtp('');
        }
    };

    const getAccounts = async () => {
        try {
            const res = await customerServices.getBankAccounts(id);
            setListAccounts(res);
        } catch (err) {
            displayError(err, true);
        }
    };

    const userDevice = async () => {
        setLoad(true);
        try {
            let res = await customerServices.userDevices(id);
            setLoad(false);
            setDevice(res);
        } catch (err) {
            displayError(err, true);
            setLoad(false);
        }
    };

    const fetchLogin = async () => {
        setLoad(true);
        try {
            let res = await customerServices.userLoginHistory(id, filter);
            setLoad(false);
            if (res?.data) {
                setLogin(res);
            }
        } catch (err) {
            setDetails([]);
            displayError(err, true);
            setLoad(false);
        }
    };

    const fetchDocuments = async () => {
        try {
            let res = await customerServices.userDocument(id);
            if (res) {
                setDocuments(res);
            }
        } catch (err) {
            setDetails([]);
            displayError(err, true);
        }
    };

    const getLockHistory = async () => {
        try {
            let res = await customerServices.lockHistories(id);
            setLock(res);
        } catch (err) {
            setLock([]);
            displayError(err, true);
        }
    };

    const fetchKyc = async () => {
        try {
            let res = await customerServices.userKycDocument(id);

            if (res) {
                setKyc(res);
            }
        } catch (err) {
            setDetails([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchUser();
        userDevice();
        fetchDocuments();
        fetchKyc();
        getLockHistory();
        getAccounts();
    }, [id]);

    useEffect(() => {
        fetchLogin();
    }, [filter]);

    useEffect(() => {
        if (acc) {
            setName(
                details?.bank_account.find((e: any) => e.account_number === acc)
                    ?.account_name,
            );
        }
    }, [acc]);

    const getModalTitle = () => {
        switch (modalState.state) {
            case 'edit':
                return 'Edit User Profile';
            case 'account':
                return 'Edit Account Name';
            case 'otp':
                return 'Enter your security OTP';
            default:
                return '';
        }
    };

    const getModalContent = () => {
        switch (modalState.state) {
            case 'edit':
                return (
                    <EditCustomer
                        maritalStatus={maritalStatus}
                        setMaritalStatus={setMaritalStatus}
                        firstName={firstName}
                        setFirstName={setFirstName}
                        lastName={lastName}
                        setLastName={setLastName}
                        otherName={otherName}
                        setOtherName={setOtherName}
                        email={email}
                        setEmail={setEmail}
                        address={address}
                        setAddress={setAddress}
                        number={number}
                        setNumber={setNumber}
                        gender={gender}
                        setGender={setGender}
                        dob={dob}
                        setDob={setDob}
                        setModalState={setModalState}
                    />
                );
            case 'account':
                return (
                    <div className="row">
                        <div className="col-lg-12 mb-3">
                            <TextInput
                                val={editAccount}
                                changeVal={setEditAccount}
                                label="Account Name"
                                placeholder="Enter Account Name"
                            />
                        </div>
                        <div className="col-lg-12 text-center">
                            <button
                                onClick={() => {
                                    setModalState({
                                        function: 'account',
                                        state: 'otp',
                                    });
                                }}
                                className="main-btn">
                                {'Edit Account'}
                            </button>
                        </div>
                    </div>
                );
            case 'otp':
                return (
                    <SecurityOtp
                        load={otpLoad}
                        otp={otp}
                        setOtp={setOtp}
                        handleSubmit={() => {
                            modalState.function === 'account'
                                ? changeAccount()
                                : modalState.function === 'edit' &&
                                  createProfileRequest();
                        }}
                    />
                );
            default:
                return '';
        }
    };

    return (
        <div>
            <BreadCrumbTitle
                title={
                    details?.first_name
                        ? `${details?.first_name} ${details?.last_name}`
                        : 'Customer Details'
                }
            />

            {details?.bank_account?.length !== 1 && (
                <div className="detail-card-align">
                    <SelectInput
                        val={acc}
                        changeVal={setAcc}
                        option={details?.bank_account?.map((a: any) => {
                            return {
                                label: a.account_number,
                                value: a.account_number,
                            };
                        })}
                        placeholder="Select Account"
                        label="Select Account"
                    />
                </div>
            )}

            <div className="mt-4">
                <TabComponent
                    arr={[
                        {
                            id: 1,
                            eventKey: 'details',
                            title: 'Customer Details',
                            child: (
                                <Details
                                    acc={acc}
                                    details={details}
                                    load={load}
                                    name={name}
                                    documents={documents}
                                    setModalState={setModalState}
                                    setModal={setModal}
                                />
                            ),
                        },
                        {
                            id: 2,
                            eventKey: 'documents',
                            title: 'Documents',
                            child: (
                                <Documents
                                    documents={documents}
                                    call={() => fetchDocuments()}
                                />
                            ),
                        },
                        {
                            id: 3,
                            eventKey: 'login-history',
                            title: 'Login History',
                            child: (
                                <LoginHistory
                                    login={login}
                                    page={page}
                                    setPage={setPage}
                                    setLimit={setLimit}
                                    limit={limit}
                                    load={load}
                                />
                            ),
                        },
                        {
                            id: 4,
                            eventKey: 'kyc-upgrade-request',
                            title: 'KYC Upgrade Request',
                            child: <KycUpgradeRequest kyc={kyc} />,
                        },
                        {
                            id: 5,
                            eventKey: 'upload-document',
                            title: 'Upload Document',
                            child: <UploadDocuments details={details} />,
                        },
                        {
                            id: 6,
                            eventKey: 'verification',
                            title: 'Verification',
                            child: <Verification details={details} />,
                        },
                        {
                            id: 7,
                            eventKey: 'nin-details',
                            title: 'Nin Details',
                            child: (
                                <NinVerificationDetails
                                    details={details}
                                    load={load}
                                />
                            ),
                        },
                        {
                            id: 8,
                            eventKey: 'face-details',
                            title: 'Face Details',
                            child: (
                                <FaceVerificationDetails details={details} />
                            ),
                        },
                        {
                            id: 9,
                            eventKey: 'device',
                            title: 'Devices',
                            child: (
                                <Devices
                                    device={device}
                                    page={page}
                                    setPage={setPage}
                                    setLimit={setLimit}
                                    limit={limit}
                                    load={load}
                                />
                            ),
                        },
                        {
                            id: 10,
                            eventKey: 'lock',
                            title: 'Lock History',
                            child: <LockHistories lock={lock} />,
                        },
                        {
                            id: 11,
                            eventKey: 'edit-customer',
                            title: 'Accounts',
                            child: (
                                <Accounts
                                    id={id}
                                    setModal={setModal}
                                    setModalState={setModalState}
                                    setEditAccount={setEditAccount}
                                    setCall={setCall}
                                    list={listAccounts}
                                />
                            ),
                        },
                    ]}
                    activeKey={key}
                    setKey={setKey}
                />
            </div>
            <ModalComponent
                open={modal}
                close={() => setModal(false)}
                title={getModalTitle()}>
                {getModalContent()}
            </ModalComponent>
        </div>
    );
}

export default TransCustomersDetail;
