import React, {useEffect, useState} from 'react';
import reportService from '../../../redux/features/reports/reportService';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import LoadTable from '../../../component/Loaders/LoadTable';
import dateFormat from 'dateformat';
import PageCover from '../../../component/PageCover';

function SubscriptionReport() {
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState<any>({});

    let filters = ` `;
    let page = 1;
    let limit = 50;

    const getUsers = async () => {
        try {
            setLoading(true);
            let res = await reportService.getSubscriptionReport(filters);
            setLoading(false);
            if (res) {
                setList(res);
            }
        } catch (err) {
            setLoading(false);
            setList([]);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getUsers();
    }, []);

    return (
        <PageCover page="Subscription Report">
            <div>
                <BreadCrumbTitle
                    title="Subscription Report"
                    dataCount={list?.total}
                />

                <div className="basic-card mt-3">
                    <div className="head"></div>
                    <div className="body">
                        <div className="table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>User Id</th>
                                            <th>Service Name</th>
                                            <th>Transaction Service</th>
                                            <th>Waved Fee</th>
                                            <th>Created At</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    {!loading &&
                                        list?.allPromoSubscriptions?.length >
                                            0 && (
                                            <tbody>
                                                {list.allPromoSubscriptions.map(
                                                    (tr: any, i: number) => (
                                                        <tr key={tr.id}>
                                                            <td>
                                                                {limit *
                                                                    (page - 1) +
                                                                    i +
                                                                    1}
                                                            </td>
                                                            <td>{tr.userId}</td>
                                                            <td>{'--'}</td>
                                                            <td>
                                                                {tr.transactionRef
                                                                    ? tr.transactionRef
                                                                    : 'not given'}
                                                            </td>
                                                            <td>
                                                                {tr.wavedFee}
                                                            </td>
                                                            <td>
                                                                {dateFormat(
                                                                    tr.createdAt,
                                                                )}
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    ),
                                                )}
                                            </tbody>
                                        )}
                                </table>
                                {loading && <LoadTable />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageCover>
    );
}

export default SubscriptionReport;
