import React, {useEffect, useState} from 'react';
import ModalComponent from '../../../../component/ModalComponent';
import PromotionConfig from '../../../../component/Extras/PromotionConfig';
import LoadTable from '../../../../component/Loaders/LoadTable';
import Paginate from '../../../../component/Paginate';
import {AiOutlineDelete} from 'react-icons/ai';
import {GoPencil} from 'react-icons/go';
import BreadCrumbTitle from '../../../../component/BreadCrumbTitle';
import {displayError} from '../../../../utils/errors';
import extraService from '../../../../redux/features/extras/extraService';
import {useAppSelector} from '../../../../redux/hooks';
import PageCover from '../../../../component/PageCover';

function SetPromotions() {
    const {token} = useAppSelector(state => state.auth);

    //ADD/EDIT CONFIG

    const [name, SetName] = useState('');
    const [description, setDescription] = useState('');
    const [categoryCode, setCategoryCode] = useState('');
    const [serviceCode, setServiceCode] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [service, setService] = useState('');
    const [customerMaxTxnCount, setCustomerMaxTxnCount] = useState('');
    const [maxSubscriptionPermit, setMaxSubscriptionPermit] = useState('');
    const [details, setDetails] = useState<any>(null);
    const [transactionCategory, setTransactionCategory] = useState<any>([]);
    const [serviceCodeDetails, setServiceCodeDetails] = useState<any>([]);
    const [startDate, setStartDate] = useState(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    );
    const [endDate, setEndDate] = useState(
        new Date(new Date().setDate(new Date().getDate() + 1)),
    );

    //filters
    const [page, setPage] = useState(1);
    const [limits, setLimits] = useState(15);
    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>([]);
    const [add, setAdd] = useState(false);

    const filters = `?page=${page}`;

    const fetchPromotions = async () => {
        try {
            setLoad(true);
            const res = await extraService.getPromotion(token, filters);
            setLoad(false);
            setList(res);
        } catch (err) {
            setLoad(false);
            setList([]);
            displayError(err, true);
        }
    };
    const fetchTransCategory = async () => {
        try {
            setLoad(true);
            const res = await extraService.getTransactionCategories(token);
            setLoad(false);
            setTransactionCategory(res?.data);
        } catch (err) {
            setLoad(false);
            setTransactionCategory([]);
            displayError(err, true);
        }
    };

    const fetchServiceCode = async () => {
        try {
            const res = await extraService.getServiceCode(
                token,
                categoryCode.split(',')[1],
            );

            setServiceCodeDetails(res?.data);
        } catch (err) {
            setServiceCodeDetails([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchPromotions();
        fetchTransCategory();
        if (categoryCode) {
            fetchServiceCode();
        }
    }, [filters, categoryCode]);

    // useEffect(() => {
    //     if (categoryCode) {
    //         fetchServiceCode();
    //     }
    // }, [  categoryCode]);

    return (
        <PageCover page="Set Promotions">
            <div>
                <BreadCrumbTitle
                    title="Set Promotion"
                    dataCount={list?.promoConfigs?.length}
                    button="+ Add"
                    buttonClick={() => setAdd(true)}
                />

                <div className="basic-card mt-3">
                    <div className="head"></div>
                    <div className="body">
                        <div className="table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>category name</th>
                                            <th>service name</th>
                                            <th>Service appllicable</th>
                                            <th>
                                                customers max transaction count
                                            </th>
                                            <th>subscription count</th>
                                            <th>Max subscription permit</th>
                                            <th>start date</th>
                                            <th>end date</th>
                                        </tr>
                                    </thead>
                                    {!load &&
                                        list?.promoConfigs?.length > 0 && (
                                            <tbody>
                                                {list?.promoConfigs?.map(
                                                    (tr: any, i: number) => (
                                                        <tr key={tr._id}>
                                                            <td>
                                                                {limits *
                                                                    (page - 1) +
                                                                    i +
                                                                    1}
                                                            </td>

                                                            <td>{tr.name}</td>
                                                            <td>
                                                                {tr.description}
                                                            </td>
                                                            <td>
                                                                {
                                                                    tr
                                                                        .categoryId
                                                                        .name
                                                                }
                                                            </td>
                                                            <td>
                                                                {tr?.serviceId
                                                                    ?.serviceName ||
                                                                    'Not given'}
                                                            </td>
                                                            <td>
                                                                {tr.serviceCode}
                                                            </td>
                                                            <td>
                                                                {
                                                                    tr.customerMaxTxnCount
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    tr.maxSubscriptionPermit
                                                                }
                                                            </td>
                                                            <td>
                                                                {tr.startDate}
                                                            </td>
                                                            <td>
                                                                {tr.endDate}
                                                            </td>
                                                            <td>
                                                                <span
                                                                    onClick={() => {
                                                                        setAdd(
                                                                            true,
                                                                        );
                                                                        setDetails(
                                                                            tr,
                                                                        );
                                                                    }}>
                                                                    <GoPencil />
                                                                </span>
                                                                <span>
                                                                    <AiOutlineDelete />
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ),
                                                )}
                                            </tbody>
                                        )}
                                </table>
                            </div>
                            {!load && list?.lastPage > 1 && (
                                <Paginate
                                    currentPage={page}
                                    totalCount={list?.allTxnLimitConfigs}
                                    pageSize={limits}
                                    lastPage={list?.lastPage}
                                    onSelect={(p: number) => setPage(Number(p))}
                                    onNext={(p: number) => setPage(p)}
                                    onPrev={(p: number) => setPage(p)}
                                    changeLimit={(p: string) =>
                                        setLimits(Number(p))
                                    }
                                />
                            )}
                            {load && <LoadTable />}
                        </div>
                    </div>
                </div>
                <ModalComponent
                    open={add}
                    size="lg"
                    title="Promotion Config"
                    close={() => setAdd(false)}>
                    <PromotionConfig
                        name={name}
                        setName={SetName}
                        description={description}
                        setDescription={setDescription}
                        categoryCode={categoryCode}
                        setCategoryCode={setCategoryCode}
                        serviceCode={serviceCode}
                        setServiceCode={setServiceCode}
                        service={service}
                        setService={setService}
                        categoryId={categoryId}
                        setCategoryId={setCategoryId}
                        customerMaxTxnCount={customerMaxTxnCount}
                        setCustomerMaxTxnCount={setCustomerMaxTxnCount}
                        maxSubscriptionPermit={maxSubscriptionPermit}
                        setMaxSubscriptionPermit={setMaxSubscriptionPermit}
                        data={details}
                        transactionCategory={transactionCategory}
                        serviceCodeDetails={serviceCodeDetails}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        setClosed={() => {
                            setAdd(false);
                            fetchPromotions();
                        }}
                    />
                </ModalComponent>
            </div>
        </PageCover>
    );
}

export default SetPromotions;
