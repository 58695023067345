import {authHeader} from '../../../utils/headers';
import {getPayload} from '../../../utils/encrypt';
import {apiRequest} from '../../../utils/axiosInstance';

const getReferrals = async (filters: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/referral_codes${filters}`,
    );
    return data.data;
};

const createReferrals = async (payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/user/generate_referral_code`,
        passPayload,
    );
    return data.data;
};

const getAuditLogs = async (filters: any) => {
    const {data} = await apiRequest('url').post(
        `/audit/audit_log${filters}`,
        {},
    );
    return data.data;
};

const getAdmins = async (filters: any) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/admin/users${filters}`,
    );
    return data.data;
};
const getAdminRoles = async (filters: any) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/admin_roles${filters}`,
    );
    return data.data;
};
const createAdmin = async (payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/create/admin_users`,
        passPayload,
    );
    return data.data;
};
const editAdmin = async (payload: any, id: number, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').put(
        `/admin_users/${id}`,
        passPayload,
    );
    return data.data;
};

const toggleAdmin = async (status: any, userId: any, otp: any) => {
    let url =
        status !== '0'
            ? `enable/admin_users/${userId}`
            : `disable/admin_users/${userId}`;
    const {data} = await apiRequest('baseUrl3Admin').get(`/${url}`, {
        headers: authHeader(otp),
    });
    return data.data;
};
const deleteAdmin = async (id: number, otp: any) => {
    const {data} = await apiRequest('baseUrl3Admin').delete(
        `/admin_users/${id}`,
        {
            headers: authHeader(otp),
        },
    );
    return data.data;
};
const resetPassword = async (payload: any, id: number, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/admin/password/reset/${id}`,
        passPayload,
    );
    return data.data;
};

const resetGoogleAuth = async (id: number, otp: any, key: any) => {
    let passPayload = getPayload(key, {}, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').get(
        `admin_users/${id}/reset_ga`,
        {
            headers: authHeader(otp),
        },
    );
    return data.data;
};

const changeImage = async (payload: any) => {
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/admin/user/upload_document`,
        payload,
    );
    return data.data;
};

const adminService = {
    getReferrals,
    createReferrals,
    getAuditLogs,
    getAdminRoles,
    getAdmins,
    createAdmin,
    toggleAdmin,
    deleteAdmin,
    editAdmin,
    resetPassword,
    resetGoogleAuth,
    changeImage,
};

export default adminService;
