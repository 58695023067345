import React, {useState, useEffect} from 'react';
import TabComponent from '../../../component/TabComponent';
import {useLocation} from 'react-router-dom';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import corporateService from '../../../redux/features/corporate/corporateService';
import {displayError} from '../../../utils/errors';
import CorporateStaffs from '../../../component/Corporate/CorporateStaffs';
import CorporateProfiles from '../../../component/Corporate/CorporateProfiles';
import CorporateDetail from '../../../component/Corporate/CorporateDetail';
import CorporateBankAccounts from '../../../component/Corporate/CorporateBankAccounts';

function CorporateDetails() {
    const details = useLocation().state;

    const [key, setKey] = useState('details');
    const [staffs, setStaffs] = useState<any>([]);
    const [profiles, setProfiles] = useState<any>([]);
    const [bankAccounts, setBankAccounts] = useState<any>([]);

    useEffect(() => {
        if (details) {
            setKey('details');
        }
        getStaffs();
        getProfiles();
        getCorporateAccounts();
    }, [details]);

    const getStaffs = async () => {
        try {
            const res = await corporateService.corporateAccountUsers(
                details?.id,
            );
            setStaffs(res);
        } catch (err) {
            displayError(err, true);
        }
    };

    const getProfiles = async () => {
        try {
            const res = await corporateService.corporateProfileUsers(
                details?.id,
            );
            setProfiles(res);
        } catch (err) {
            displayError(err, true);
        }
    };

    const getCorporateAccounts = async () => {
        try {
            const res = await corporateService.corporateBankAccount(
                details?.id,
            );
            setBankAccounts(res);
        } catch (err) {
            displayError(err, true);
        }
    };

    return (
        <div>
            <BreadCrumbTitle title="Corporate Details" />{' '}
            <div className="mt-4">
                <TabComponent
                    arr={[
                        {
                            id: 1,
                            eventKey: 'details',
                            title: 'Corporate Details',
                            child: <CorporateDetail details={details} />,
                        },
                        {
                            id: 2,
                            eventKey: 'account-users',
                            title: 'Account Users',
                            child: <CorporateStaffs list={staffs} />,
                        },
                        {
                            id: 3,
                            eventKey: 'staffs',
                            title: 'Staffs',
                            child: <CorporateProfiles list={profiles} />,
                        },
                        {
                            id: 4,
                            eventKey: 'bank-accounts',
                            title: 'Bank Accounts',
                            child: (
                                <CorporateBankAccounts list={bankAccounts} />
                            ),
                        },
                    ]}
                    activeKey={key}
                    setKey={setKey}
                />
            </div>
        </div>
    );
}

export default CorporateDetails;
