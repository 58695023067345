import {getPayload} from '../../../utils/encrypt';
import {apiRequest} from '../../../utils/axiosInstance';

const getCardBatchConfig = async () => {
    const {data} = await apiRequest('baseUrl').get(`/cards/batches`);
    return data;
};

const createCardBatchConfig = async (payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl').post(
        `/cards/batches`,
        passPayload,
    );
    return data;
};

const getCardRequests = async (filter: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/cards/card_requests${filter}`,
    );
    return data;
};

const submitBatches = async () => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_beneficiaries`,
    );
    return data;
};

const deleteBatch = async (url: any, id: number) => {
    const {data} = await apiRequest('baseUrl').get(`/cards/${url}/${id}`);
    return data;
};

const getCardRequestBatch = async () => {
    const {data} = await apiRequest('baseUrl').get(
        `/cards/card_requests_batches`,
    );
    return data;
};

const createCardRequestBatch = async () => {
    const {data} = await apiRequest('baseUrl').get(
        `/cards/card_requests_batches`,
    );
    return data;
};

const cardService = {
    getCardBatchConfig,
    getCardRequestBatch,
    getCardRequests,
    createCardRequestBatch,
    createCardBatchConfig,
    submitBatches,
    deleteBatch,
};

export default cardService;
