const roles = [
    {
        id: 1,
        page: 'Dashboard',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 2,
        page: 'Customers',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 3,
        page: 'Login Histories',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 4,
        page: 'All Devices',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 5,
        page: 'Transactions',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 6,
        page: 'Bank Transfers',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 7,
        page: 'Bills Transactions',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 8,
        page: 'Switch Code',
        roles: ['1', '3'],
    },
    {
        id: 9,
        page: 'KYC Levels',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 10,
        page: 'KYC Upgrade Request',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 11,
        page: 'Uploaded Documents',
        roles: ['1', '3'],
    },
    {
        id: 12,
        page: 'Savings Wallet',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 13,
        page: 'Savings Penality',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 14,
        page: 'Wallet Transaction',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 15,
        page: 'Withdrawal Date',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 16,
        page: 'Calculated Interest %',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 17,
        page: 'Earnings',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 18,
        page: 'Interest Payout',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 19,
        page: 'Disputes',
        roles: ['1', '2', '3'],
    },
    {
        id: 20,
        page: 'New Account',
        roles: ['1', '3'],
    },
    {
        id: 21,
        page: 'Corporate Account',
        roles: ['1', '3','2','4'],
    },
    {
        id: 22,
        page: 'Referrals',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 23,
        page: 'Audit Logs',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 24,
        page: 'User Report',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 25,
        page: 'Report Summary',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 26,
        page: 'Customer Report',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 27,
        page: 'Subscription Report',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 28,
        page: 'NIBBS Report',
        roles: ['1', '3'],
    },
    {
        id: 29,
        page: 'Institution Report',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 30,
        page: 'Service Report',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 31,
        page: 'All Terminals',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 32,
        page: 'Agent Terminals',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 33,
        page: 'Approve Terminals',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 34,
        page: 'All Agents',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 35,
        page: 'Agent Categories',
        roles: ['1', '2', '3', '4'],
    },
    {
        id: 36,
        page: 'Pos Bvn List',
        roles: ['1', '2', '3'],
    },
    {
        id: 37,
        page: 'Card Batch Config',
        roles: ['1', '3'],
    },
    {
        id: 38,
        page: 'Card Request',
        roles: ['1', '3'],
    },
    {
        id: 39,
        page: 'Card Request Batch',
        roles: ['1', '3'],
    },
    {
        id: 40,
        page: 'All Admins',
        roles: ['1'],
    },
    {
        id: 41,
        page: 'Wallet Histories',
        roles: ['1'],
    },
    {
        id: 42,
        page: 'Beneficiaries',
        roles: ['1'],
    },
    {
        id: 43,
        page: 'Fees',
        roles: ['1'],
    },
    {
        id: 44,
        page: 'Transaction Config',
        roles: ['1'],
    },
    {
        id: 45,
        page: 'Set Promotions',
        roles: ['1'],
    },
    {
        id: 46,
        page: 'Interests',
        roles: ['1'],
    },
    {
        id:47,
        page:'Agent Form',
        roles:['3','2','1']
    },
    //BUTTONS
    {
        id: 48,
        page: 'Reverse Transactions',
        roles: ['1'],
    },
    {
        id:49,
        page:'create corporate account',
        roles:['1','3','2']
    },

    //Not a button
    {
        id:50,
        page:'Bank Channels',
        roles:['1','3','2']
    },
    {
        id:51,
        page:'Bank Bins',
        roles:['1','3','2']
    },
    {
        id:52,
        page:'Card Channels',
        roles:['1','3','2']
    },
    {
        id:53,
        page:'Card Settings',
        roles:['1','3','2']
    },
    {
        id: 54,
        page: 'Customers Approval',
        roles: ['1'],
    },
];

export {roles};
