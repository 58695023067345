import React, {useEffect, useState} from 'react';
import Paginate from '../../../component/Paginate';
import LoadTable from '../../../component/Loaders/LoadTable';
import {useAppSelector} from '../../../redux/hooks';
import KYCService from '../../../redux/features/kyc/kycService';
import {displayError} from '../../../utils/errors';
import {UseDebounce} from '../../../utils/hooks';
import {SearchInput, SelectInput} from '../../../component/BasicInputs';
import {IoRefreshCircle} from 'react-icons/io5';
import dateFormat from 'dateformat';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import {Link} from 'react-router-dom';
import ModalComponent from '../../../component/ModalComponent';
import SecurityOtp from '../../../component/SecurityOtp';
import {toast} from 'react-toastify';
import PageCover from '../../../component/PageCover';

function UploadedDocuments() {
    const {token, details} = useAppSelector(state => state.auth);

    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});
    const [limit, setLimit] = useState(15);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState('');
    const [state, setState] = useState('');

    const debouncedSearch = UseDebounce(search);

    const filters = `${
        state === 'name'
            ? `?user_name=${search}&paginate=yes&no_of_pages=15`
            : state === 'filter'
            ? `?paginate=yes&filter_by=${status}&no_of_pages=15`
            : `?paginate=${page}&filter_by=${status}&limit=${limit}`
    }`;

    const fetchRequest = async () => {
        try {
            setLoad(true);
            const res = await KYCService.getUploadedDocuments(token, filters);
            setLoad(false);
            setList(res);
        } catch (err) {
            setLoad(false);
            setList([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (debouncedSearch) {
            fetchRequest();
        } else {
            fetchRequest();
        }
    }, [page, status, limit, debouncedSearch]);

    const resetUsers = () => {
        setPage(1);
        setLimit(0);
        setSearch('');
        setStatus('');
        setState('');
        fetchRequest();
    };

    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    //checkbox

    const [ids, setIds] = useState<any>([]);

    const [otp, setOtp] = useState('');
    const [otpLoad, setOtpLoad] = useState(false);
    const [openOtp, setOpenOtp] = useState(false);
    const [data, setData] = useState<any>({});

    const changeId = (val: any, id: any) => {
        const find = ids.find((i: any) => i.id === id.id);
        if (find) {
            setIds(ids.filter((i: any) => i.id !== id.id));
        } else {
            setIds([...ids, id]);
        }
    };

    const approveId = async (id: any, user: string, comment: string) => {
        const payload = {
            user_id: user,
            approved_by: details.id,
            user_document_id: id,
            comment,
        };
        setOtpLoad(true);
        try {
            await KYCService.approveRequest(
                token,
                payload,
                {
                    aes_key: details.aes_key,
                    aes_iv: details.aes_iv,
                },
                comment,
                otp,
            );
            toast.success(comment);
            fetchRequest();
            setOtpLoad(false);
            setOtp('');
            setOpenOtp(false);
        } catch (err) {
            displayError(err, true);
            setIds([]);
            setOtpLoad(false);
            setOtp('');
        }
    };

    return (
        <PageCover page="Uploaded Documents">
            <div>
                <BreadCrumbTitle
                    title="Uploaded Documents"
                    dataCount={list?.total}
                />
                <div className="d-flex align-items-center justify-content-between">
                    <div className="col-lg-4 mb-4">
                        <SelectInput
                            option={[
                                {label: 'Status', value: 'filter'},
                                {label: 'Username', value: 'name'},
                            ]}
                            val={state}
                            changeVal={setState}
                            placeholder="Select Status"
                        />
                    </div>
                    {state === 'name' && (
                        <div className="col-lg-4">
                            <SearchInput val={search} changeVal={setSearch} />
                        </div>
                    )}

                    {state === 'filter' && (
                        <div className="col-lg-4 mb-4">
                            <SelectInput
                                option={[
                                    {label: 'Approved', value: 'Approved'},
                                    {label: 'Rejected', value: 'Rejected'},
                                    {label: 'Pending', value: 'Pending'},
                                ]}
                                val={status}
                                changeVal={setStatus}
                                placeholder="Select Status"
                            />
                        </div>
                    )}

                    <div className="filter">
                        <button
                            onClick={() => resetUsers()}
                            className="main-btn">
                            <IoRefreshCircle />
                            <span>Refresh</span>
                        </button>
                    </div>
                </div>
                {/* {ids.length > 0 && (
                <div className="d-flex gap-3">
                    <div>
                        <button
                            onClick={() => approveId(ids, 'approve')}
                            className="main-btn">
                            <span>Approve</span>
                        </button>
                    </div>
                    <div>
                        <button
                            onClick={() => approveId(ids, 'reject')}
                            type="button"
                            className="btn btn-danger">
                            Reject
                        </button>
                    </div>
                </div>
            )} */}

                <div className="basic-card mt-3">
                    <div className="head"></div>
                    <div className="body">
                        <div className="table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>S/N</th>
                                            <th>Customer Name</th>
                                            <th>Document Name</th>
                                            <th>Document URL</th>
                                            <th>Approved By</th>
                                            <th>Document Status</th>
                                            <th>Comment</th>
                                            <th>Created At</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    {!load && list?.data?.length > 0 && (
                                        <tbody>
                                            {list.data.map(
                                                (tr: any, i: number) => (
                                                    <tr key={tr.id}>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                onChange={e =>
                                                                    changeId(
                                                                        e.target
                                                                            .checked,
                                                                        {
                                                                            user_document_id:
                                                                                tr.document_id,
                                                                            user_id:
                                                                                tr.user_id,
                                                                            id: tr.id,
                                                                        },
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                        <td>
                                                            {' '}
                                                            {limit *
                                                                (page - 1) +
                                                                i +
                                                                1}
                                                        </td>
                                                        <td className="text-primary">
                                                            <Link
                                                                to={`/dashboard/customers/${tr.user_id}`}
                                                                state={
                                                                    tr.user_id
                                                                }>
                                                                {
                                                                    tr.user
                                                                        .first_name
                                                                }{' '}
                                                                {
                                                                    tr.user
                                                                        .last_name
                                                                }
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            {tr.document_url &&
                                                                tr?.document
                                                                    ?.name}
                                                        </td>
                                                        <td>
                                                            {tr.document_url &&
                                                            tr.document_url.substr(
                                                                0,
                                                                4,
                                                            ) === 'http' ? (
                                                                <button
                                                                    onClick={() =>
                                                                        openInNewTab(
                                                                            tr?.document_url,
                                                                        )
                                                                    }
                                                                    className="btn btn-primary">
                                                                    View
                                                                </button>
                                                            ) : (
                                                                <p>
                                                                    {tr.document_url &&
                                                                        tr.document_url}
                                                                </p>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {
                                                                tr
                                                                    .approval_details
                                                                    ?.username
                                                            }
                                                        </td>
                                                        <td>
                                                            {tr?.approval_status.toUpperCase()}
                                                        </td>
                                                        <td>{tr?.comment}</td>
                                                        <td>
                                                            {dateFormat(
                                                                tr?.created_at,
                                                            )}
                                                        </td>
                                                        <td className="d-flex gap-2">
                                                            {tr?.approval_status ===
                                                            'Pending' ? (
                                                                <>
                                                                    <button
                                                                        onClick={() => {
                                                                            setData(
                                                                                {
                                                                                    id: tr?.id,
                                                                                    user: tr?.user_id,
                                                                                    state: 'approve',
                                                                                },
                                                                            );
                                                                            setOpenOtp(
                                                                                true,
                                                                            );
                                                                        }}
                                                                        type="button"
                                                                        className="btn btn-primary">
                                                                        Accept
                                                                    </button>
                                                                    <button
                                                                        onClick={() => {
                                                                            setData(
                                                                                {
                                                                                    id: tr?.id,
                                                                                    user: tr?.user_id,
                                                                                    state: 'reject',
                                                                                },
                                                                            );
                                                                            setOpenOtp(
                                                                                true,
                                                                            );
                                                                        }}
                                                                        type="button"
                                                                        className="btn btn-danger">
                                                                        Reject
                                                                    </button>
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                    </tr>
                                                ),
                                            )}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                            {!load && list?.per_page > 1 && (
                                <Paginate
                                    currentPage={page}
                                    totalCount={list.total}
                                    pageSize={limit}
                                    onSelect={(p: number) => setPage(Number(p))}
                                    onNext={(p: number) => setPage(p)}
                                    onPrev={(p: number) => setPage(p)}
                                    changeLimit={(p: string) =>
                                        setLimit(Number(p))
                                    }
                                />
                            )}
                            {load && <LoadTable />}
                        </div>
                    </div>
                </div>
                <ModalComponent
                    title="Enter your Otp"
                    open={openOtp}
                    close={() => setOpenOtp(false)}>
                    <SecurityOtp
                        otp={otp}
                        setOtp={setOtp}
                        handleSubmit={() =>
                            approveId(data?.id, data?.user, data?.state)
                        }
                        load={otpLoad}
                    />
                </ModalComponent>
            </div>
        </PageCover>
    );
}

export default UploadedDocuments;
