import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../../redux/hooks';
import beneficiariesService from '../../../../redux/features/beneficiaries/beneficiariesSlice';
import {displayError} from '../../../../utils/errors';
import BreadCrumbTitle from '../../../../component/BreadCrumbTitle';
import {AiOutlineDelete} from 'react-icons/ai';
import ModalComponent from '../../../../component/ModalComponent';
import AddBeneficiary from './AddBeneficiary';
import PageCover from '../../../../component/PageCover';

function Beneficiaries() {
    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});

    const {token, details} = useAppSelector(state => state.auth);

    const [add, setAdd] = useState(false);

    const [user, setUser] = useState('');
    const [userName, setUserName] = useState('');
    const [userType, setUserType] = useState('');

    const fetchBenficiaries = async () => {
        try {
            setLoad(true);
            const res = await beneficiariesService.fetchBeneficiaries(token);
            setLoad(false);
            setList(res);
        } catch (err) {
            setLoad(false);
            setList([]);
            displayError(err, true);
        }
    };

    const addBeneficiary = async () => {
        const payload = {userType, userId: userName};
        try {
            setLoad(true);
            const res = await beneficiariesService.addBeneficiaries(
                token,
                payload,
                {aes_key: details.aes_key, aes_iv: details.aes_iv},
            );
            setLoad(false);
        } catch (err) {
            displayError(err, true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchBenficiaries();
    }, []);

    return (
        <PageCover page="Beneficiaries">
            <div>
                <BreadCrumbTitle
                    title="Beneficiaries"
                    dataCount={list?.beneficiaries?.length}
                    button="+ Add New Beneficiary"
                    buttonClick={() => setAdd(true)}
                />

                <div className="basic-card mt-3">
                    <div className="head"></div>
                    <div className="body">
                        <div className="table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>User type</th>
                                            <th>user id</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    {!load &&
                                        list?.beneficiaries?.length > 0 && (
                                            <tbody>
                                                {list.beneficiaries.map(
                                                    (tr: any, i: number) => (
                                                        <tr key={tr.id}>
                                                            <td>
                                                                {' '}
                                                                {15 * (1 - 1) +
                                                                    i +
                                                                    1}
                                                            </td>

                                                            <td>
                                                                {tr.userType}
                                                            </td>
                                                            <td>{tr.userId}</td>
                                                            <td className="d-flex gap-2">
                                                                <span>
                                                                    {' '}
                                                                    <AiOutlineDelete />
                                                                </span>
                                                                <span>
                                                                    <div className="form-check form-switch">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="flexSwitchCheckDefault"
                                                                        />
                                                                    </div>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ),
                                                )}
                                            </tbody>
                                        )}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalComponent open={add} close={() => setAdd(false)}>
                    <AddBeneficiary
                        user={user}
                        setUser={setUser}
                        userName={userName}
                        setUserName={setUserName}
                        setUserType={setUserType}
                        userType={userType}
                        filter={() => addBeneficiary()}
                    />
                </ModalComponent>
            </div>
        </PageCover>
    );
}

export default Beneficiaries;
