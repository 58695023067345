import React, {useState} from 'react';
import {SelectInput, TextArea, TextInput} from '../BasicInputs';
import {displayError} from '../../utils/errors';
import axios from 'axios';
import {toast} from 'react-toastify';
import {useAppSelector} from '../../redux/hooks';
import config from '../../utils/config';

function PushNotificationModal({id, close}: {id: any; close: () => void}) {
    const {token} = useAppSelector(state => state.auth);
    const [type, setType] = useState('');
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [load, setLoad] = useState(false);

    const sendMessage = async () => {
        setLoad(true);
        if (
            Array.isArray(id) &&
            id.length > 0 &&
            typeof title === 'string' &&
            typeof message === 'string'
        ) {
            const payload = {
                device_tokens: id,
                title,
                message,
            };
            try {
                await axios.post(
                    `${config.baseUrl}/notification/send_push`,
                    payload,
                    {
                        headers: {
                            Authorization: `bearer${token}`,
                            'Content-Type': 'application/json',
                            servicesAuthKey:
                                'GTP_SERVELLDWhpzwJwLqxJezIqqOSmhsYBOVUDOc',
                        },
                    },
                );
                setLoad(false);
                setTitle('');
                setMessage('');
                toast.success('Push Notification Sent');
                close();
            } catch (err) {
                displayError(err, true);
                setLoad(false);
            }
        }
    };
    return (
        <div className="row">
            <div className="mb-3">
                <SelectInput
                    option={[{label: 'Message', value: 'Message'}]}
                    val={type}
                    changeVal={setType}
                    label="Type of Information"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={title}
                    changeVal={setTitle}
                    label="Title"
                    placeholder="Enter Title"
                />
            </div>
            <div className="mb-3">
                <TextArea
                    val={message}
                    changeVal={setMessage}
                    label="Message"
                    placeholder="Enter Message"
                    required
                />
            </div>
            <div className="col-lg-12 text-center">
                <button className="main-btn" onClick={sendMessage}>
                    {load ? 'Loading...' : 'Send Message'}
                </button>
            </div>
        </div>
    );
}

export default PushNotificationModal;
