import React, {useEffect, useState} from 'react';
import Paginate from '../../../component/Paginate';
import LoadTable from '../../../component/Loaders/LoadTable';
import dateFormat from 'dateformat';
import {SearchInput} from '../../../component/BasicInputs';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import {displayError} from '../../../utils/errors';
import savingsService from '../../../redux/features/savings/savingsService';
import {useAppSelector} from '../../../redux/hooks';
import PageCover from '../../../component/PageCover';

function WalletTransactions() {
    const {token} = useAppSelector(state => state.auth);

    //filters
    const [list, setList] = useState<any>([]);
    const [load, setLoad] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(15);

    const filters = '';

    const fetchSavinsWallet = async () => {
        try {
            setLoad(true);
            const res = await savingsService.getwalletTransactions(token);
            setLoad(false);
            setList(res);
        } catch (err) {
            setLoad(false);
            setList([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        fetchSavinsWallet();
    }, [filters]);

    return (
        <PageCover page="Wallet Transaction">
            <div>
                <div>
                    <BreadCrumbTitle
                        title="Savings Wallet"
                        dataCount={list?.totalElements}
                    />
                    <div className="row">
                        <div className="col-lg-6 mt-3">
                            <SearchInput val={search} changeVal={setSearch} />
                        </div>
                    </div>
                </div>

                <div className="basic-card mt-3">
                    <div className="head"></div>
                    <div className="body">
                        <div className="table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>source Number</th>
                                            <th>Transaction type</th>
                                            <th>Reciepient Number</th>
                                            <th>amount</th>
                                            <th>description</th>
                                            <th>created at</th>
                                            <th>action</th>
                                        </tr>
                                    </thead>
                                    {!load && list?.length > 0 && (
                                        <tbody>
                                            {list.map((tr: any, i: number) => (
                                                <tr key={tr.id}>
                                                    <td>
                                                        {limit * (page - 1) +
                                                            i +
                                                            1}
                                                    </td>
                                                    <td>{tr.source_no}</td>
                                                    <td>
                                                        {tr.transaction_type}
                                                    </td>
                                                    <td>{tr.recipient_no}</td>
                                                    <td>{tr.amount}</td>
                                                    <td>{tr.description}</td>
                                                    <td>
                                                        {dateFormat(
                                                            tr.created_at,
                                                        )}
                                                    </td>
                                                    <td>
                                                        <div className="form-check form-switch">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="flexSwitchCheckDefault"
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                            {!load && list?.totalPages > 1 && (
                                <Paginate
                                    currentPage={page}
                                    totalCount={list.totalElements}
                                    pageSize={limit}
                                    lastPage={list.totalPages}
                                    onSelect={(p: number) => setPage(Number(p))}
                                    onNext={(p: number) => setPage(p)}
                                    onPrev={(p: number) => setPage(p)}
                                    changeLimit={(p: string) =>
                                        setLimit(Number(p))
                                    }
                                />
                            )}
                            {load && <LoadTable />}
                        </div>
                    </div>
                </div>
            </div>
        </PageCover>
    );
}

export default WalletTransactions;
