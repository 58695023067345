import React from 'react';

interface FaceVerificationDetailsProps {
    details?: any;
}

const FaceVerificationDetails: React.FC<FaceVerificationDetailsProps> = ({
    details,
}) => {
    const data = details?.face_verification_data?.data;

    return (
        <div className="row">
            <div className="col-lg-6 customer-details">
                {data ? (
                    Object.entries(data).map(([key, value]: any) => {
                        if (
                            key === 'bvn_check' ||
                            (key === 'face_verification_check' &&
                                typeof value === 'object')
                        ) {
                            return null;
                        }

                        if (key === 'imageUrl' || key === 'photo') {
                            const imageUrl =
                                key === 'imageUrl'
                                    ? value
                                    : `data:image/jpeg;base64,${value}`;
                            return (
                                <div
                                    key={key}
                                    className="customer-details-veri">
                                    <span className="left">{key}</span>
                                    <img
                                        className="right"
                                        src={imageUrl}
                                        alt={key}
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                        }}
                                    />
                                </div>
                            );
                        }

                        if (key === 'bvn') {
                            return (
                                <div
                                    key={key}
                                    className="customer-details-veri">
                                    <span className="left">{key}</span>
                                    <strong className="right">
                                        {value?.substring(0, 4)}*******
                                    </strong>
                                </div>
                            );
                        }

                        return (
                            <div key={key} className="customer-details-veri">
                                <span className="left">{key}</span>
                                <strong className="right">{value} </strong>
                            </div>
                        );
                    })
                ) : (
                    <div>No Data</div>
                )}
            </div>
            <div className="col-lg-6"></div>
        </div>
    );
};

export default FaceVerificationDetails;
