import {authHeader} from '../../../utils/headers';
import {apiRequest} from '../../../utils/axiosInstance';

const AllTerminals = async (filters: string) => {
    const {data} = await apiRequest('posUrl').get(`/terminal${filters}`);
    return data.data;
};

const AgentTerminals = async (filters: string) => {
    const {data} = await apiRequest('posUrl').get(`/agent_terminal${filters}`);
    return data.data;
};

const NewTerminal = async (payload: any) => {
    const {data} = await apiRequest('posUrl').post(`/terminal`, payload);
    return data.data;
};

const editTerminal = async (payload: any, id: string) => {
    const {data} = await apiRequest('posUrl').put(`/terminal/${id}`, payload);
    return data.data;
};

const resetPin = async (payload: any) => {
    const {data} = await apiRequest('posUrl').post(
        `/terminal/reset-pin`,
        payload,
    );
    return data.data;
};

const setFeatures = async (url: any, payload: any) => {
    const {data} = await apiRequest('posUrl').post(`/terminal/${url}`, payload);
    return data.data;
};

const activeHandler = async (type: any, id: any) => {
    let url =
        type === 'Active'
            ? `/terminal/deactivate/${id}`
            : `/terminal/activate/${id}`;
    const {data} = await apiRequest('posUrl').get(`${url}`);
    return data.data;
};

const setWithdraw = async (payload: any) => {
    const {data} = await apiRequest('posUrl').post(
        `/agent_terminal/withdraw_terminal`,
        payload,
    );
    return data.data;
};

const getBvnListing = async (filters: string) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/link_bvn_requests${filters}`,
    );
    return data.data;
};

const getBvnListingApproval = async (id: number, otp: string) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/link_bvn_requests/${id}/approve`,
        {
            headers: authHeader(otp),
        },
    );
    return data.data;
};

const getTerminalApproval = async (filters: any) => {
    const {data} = await apiRequest('posUrl').get(`/agent-request${filters}`);
    return data.data;
};

const ApproveTerminal = async (
    id: number,
    type: any,
    agentCat: number,
    terminal: string,
) => {
    const url =
        type === 'approve'
            ? `/agent-request/approve/${id}?agentCategoryId=${agentCat}&terminalId=${terminal}`
            : `/agent-request/reject/${id}`;
    const {data} = await apiRequest('posUrl').get(`${url}`);
    return data.data;
};

const terminalService = {
    AllTerminals,
    AgentTerminals,
    NewTerminal,
    resetPin,
    activeHandler,
    setFeatures,
    setWithdraw,
    getBvnListing,
    getBvnListingApproval,
    getTerminalApproval,
    ApproveTerminal,
    editTerminal,
};
export default terminalService;
