import React, {useEffect, useState} from 'react';
import {SearchInput} from '../../../component/BasicInputs';
import terminalService from '../../../redux/features/terminals/terminalService';
import {useAppSelector} from '../../../redux/hooks';
import {displayError} from '../../../utils/errors';
import Paginate from '../../../component/Paginate';
import LoadTable from '../../../component/Loaders/LoadTable';
import {UseDebounce} from '../../../utils/hooks';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import ModalComponent from '../../../component/ModalComponent';
import ResetPin from '../../../component/Terminals/ResetPin';
import NewTerminal from '../../../component/Terminals/NewTerninal';
import {BiPencil} from 'react-icons/bi';
import PageCover from '../../../component/PageCover';

function AllTerminals() {
    //Reset Pin
    const [reset, setReset] = useState(false);
    const [pin, setPin] = useState('');
    const [confirmPin, setConfirmPin] = useState('');
    const [serialDetails, setSerialDetails] = useState<any>({});

    //NEW TERMINAL
    const [serialNumber, setSerialNumber] = useState('');
    const [model, setModel] = useState('');
    const [manufacturer, setManufacturer] = useState('');
    const [operatingSystem, setOperatingSystem] = useState('');
    const [upTerminal, setUpTerminal] = useState('');
    const [interswitchTerminal, setInterSwitchTerminal] = useState('');
    const [globusTerminal, setGlobusTerminal] = useState('');
    const [nibbsTerminal, setNibbsTerminal] = useState('');
    const [terminal, setTerminal] = useState(false);

    const {token} = useAppSelector(state => state.auth);

    //filters
    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [serialNo, setSerialNo] = useState('');
    const [data, setData] = useState<any>({});

    const debouncedSearch = UseDebounce(serialNo);

    const filters = `?page=${page - 1}&serialNo=${serialNo}&size=${limit}`;

    const fetchAllTerminals = async () => {
        try {
            setLoad(true);
            const res = await terminalService.AllTerminals(token, filters);
            setLoad(false);
            setList(res);
        } catch (err) {
            setLoad(false);
            setList([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (debouncedSearch) {
            fetchAllTerminals();
        } else {
            fetchAllTerminals();
        }
    }, [debouncedSearch, filters]);

    //Handler

    const activeHandler = async (type: any, id: any) => {
        if (
            window.confirm(
                'Are you sure want to proceed? This will deactivate.',
            )
        ) {
            try {
                const res = await terminalService.activeHandler(
                    token,
                    type,
                    id,
                );
                if (res) {
                    fetchAllTerminals();
                }
            } catch (err) {
                displayError(err, true);
            }
        }
    };

    return (
        <PageCover page="All Terminals">
            <div>
                <BreadCrumbTitle
                    title="All Terminals"
                    dataCount={list?.totalElements}
                />
                <div className="row d-flex justify-content-between">
                    <div className="col-lg-6 mt-3">
                        <SearchInput val={serialNo} changeVal={setSerialNo} />
                    </div>
                    <div className="col-lg-4"></div>
                    <div className="col-lg-2 flex align-items-end mt-3">
                        <button
                            className="main-btn"
                            onClick={() => setTerminal(true)}>
                            New Terminal{' '}
                        </button>
                    </div>
                </div>
                <div className="basic-card mt-3">
                    <div className="head"></div>
                    <div className="body">
                        <div className="table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Serial Number</th>
                                            <th>Unified Payment TID</th>
                                            <th>InterSwitch TID</th>
                                            <th>OS</th>
                                            <th>Status </th>
                                            <th>Action </th>
                                        </tr>
                                    </thead>
                                    {!load && list?.content?.length > 0 && (
                                        <tbody>
                                            {list.content.map(
                                                (tr: any, i: number) => (
                                                    <tr key={tr.id}>
                                                        <td>{tr.serialNo}</td>
                                                        <td>
                                                            {tr.upTid ||
                                                                'Not Set'}
                                                        </td>
                                                        <td>
                                                            {tr.intTid ||
                                                                'Not Set'}
                                                        </td>
                                                        <td>{tr.os}</td>
                                                        <td>{tr.status}</td>
                                                        <td
                                                            role="button"
                                                            className=" d-flex text-primary">
                                                            <div
                                                                onClick={() => {
                                                                    setReset(
                                                                        true,
                                                                    );
                                                                    setSerialDetails(
                                                                        tr,
                                                                    );
                                                                }}
                                                                style={{
                                                                    borderRight:
                                                                        '2px solid',
                                                                    paddingRight:
                                                                        '15px',
                                                                }}>
                                                                Reset PIN
                                                            </div>

                                                            <span
                                                                className="link"
                                                                onClick={() =>
                                                                    activeHandler(
                                                                        tr.status,
                                                                        tr.id,
                                                                    )
                                                                }
                                                                style={{
                                                                    paddingLeft:
                                                                        '15px',
                                                                }}>
                                                                {tr.status ===
                                                                'Active'
                                                                    ? 'Deactivate'
                                                                    : 'Activate'}
                                                            </span>
                                                        </td>
                                                        <td
                                                            onClick={() => {
                                                                setTerminal(
                                                                    true,
                                                                );
                                                                setData(tr);
                                                            }}>
                                                            <BiPencil />
                                                        </td>
                                                    </tr>
                                                ),
                                            )}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                            {!load && list?.totalPages > 1 && (
                                <Paginate
                                    currentPage={page}
                                    totalCount={list.totalElements}
                                    pageSize={limit}
                                    lastPage={list.totalPages}
                                    onSelect={(p: number) => setPage(Number(p))}
                                    onNext={(p: number) => setPage(p)}
                                    onPrev={(p: number) => setPage(p)}
                                    changeLimit={(p: string) =>
                                        setLimit(Number(p))
                                    }
                                />
                            )}
                            {load && <LoadTable />}
                        </div>
                    </div>
                </div>

                <ModalComponent
                    open={reset}
                    close={() => setReset(false)}
                    size="sm"
                    title="Reset Pin">
                    <ResetPin
                        pin={pin}
                        setPin={setPin}
                        confirmPin={confirmPin}
                        setConfirmPin={setConfirmPin}
                        serialNo={serialDetails.serialNo}
                        refresh={() => {
                            fetchAllTerminals();
                            setReset(false);
                        }}
                    />
                </ModalComponent>

                <ModalComponent
                    open={terminal}
                    close={() => setTerminal(false)}
                    size="lg"
                    title="Create New Terminal">
                    <NewTerminal
                        serialNumber={serialNumber}
                        setSerialNumber={setSerialNumber}
                        model={model}
                        setModel={setModel}
                        manufacturer={manufacturer}
                        setManufacturer={setManufacturer}
                        operatingSystem={operatingSystem}
                        setOperatingSystem={setOperatingSystem}
                        upTerminal={upTerminal}
                        setUpTerminal={setUpTerminal}
                        nibbsTerminal={nibbsTerminal}
                        setNibbsTerminal={setNibbsTerminal}
                        globusTerminal={globusTerminal}
                        setGlobusTerminal={setGlobusTerminal}
                        interswitchTerminal={interswitchTerminal}
                        setInterSwitchTerminal={setInterSwitchTerminal}
                        data={data}
                        refresh={() => {
                            fetchAllTerminals();
                            setTerminal(false);
                            setData({});
                        }}
                    />
                </ModalComponent>
            </div>
        </PageCover>
    );
}

export default AllTerminals;
