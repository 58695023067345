import React from 'react';
import {TextInput, SelectInput} from '../BasicInputs';

function ReportTransaction({
    reason,
    setReason,
    activity,
    setActivity,
    close,
    setOtpModal,
}: {
    reason: string;
    setReason: (arg: string) => void;
    activity: string;
    setActivity: (arg: string) => void;
    setOtpModal: (arg: boolean) => void;
    close: () => void;
}) {
    return (
        <div className="row">
            <div className="col-lg-12 mb-3">
                <SelectInput
                    option={[
                        {label: 'Suspicious', value: 'sus'},
                        {label: 'BlackList', value: 'black'},
                    ]}
                    val={activity}
                    changeVal={setActivity}
                    label="Activity"
                />
            </div>
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={reason}
                    changeVal={setReason}
                    placeholder="Enter Reason"
                    label="Reason..."
                />
            </div>
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={reason}
                    changeVal={setReason}
                    placeholder="Condition"
                    label="Condition"
                />
            </div>
            <div className="col-lg-12 text-center">
                <button
                    onClick={() => {
                        setOtpModal(true);
                        close();
                    }}
                    className="main-btn">
                    {'Report Transaction'}
                </button>
            </div>
        </div>
    );
}

export default ReportTransaction;
