import React from 'react';
import {SelectInput} from '../BasicInputs';

function PopModal({
    options,
    approve,
    value,
    setValue,
    loading,
}: {
    options: any;
    approve: () => void;
    value: string;
    setValue: (arg: string) => void;
    loading: boolean;
}) {
    return (
        <div className="row">
            <div className="col-lg-12 mb-3">
                <SelectInput
                    option={options?.map((i: any) => ({
                        label: i.description,
                        value: `${i.name}`,
                    }))}
                    val={value}
                    changeVal={setValue}
                    label="Transaction Category"
                    placeholder="Select"
                    required
                />
            </div>
            <div className='col-lg-12 mt-4 text-center'>
                <button className="main-btn" onClick={approve}>
                    {loading ? 'loading...' : 'Approve'}
                </button>
            </div>
        </div>
    );
}

export default PopModal;
