import React, {useEffect, useState} from 'react';
import {SelectInput, TextInput} from '../BasicInputs';
import terminalService from '../../redux/features/terminals/terminalService';
import {displayError} from '../../utils/errors';
import {useAppSelector} from '../../redux/hooks';
import BasicLoad from '../Loaders/BasicLoad';
import {toast} from 'react-toastify';
interface props {
    serialNumber: string;
    setSerialNumber: (arg: string) => void;
    manufacturer: string;
    setManufacturer: (arg: string) => void;
    model: string;
    setModel: (arg: string) => void;
    operatingSystem: string;
    setOperatingSystem: (arg: string) => void;
    upTerminal: string;
    setUpTerminal: (arg: string) => void;
    interswitchTerminal: string;
    setInterSwitchTerminal: (arg: string) => void;
    globusTerminal: string;
    setGlobusTerminal: (arg: string) => void;
    nibbsTerminal: string;
    setNibbsTerminal: (arg: string) => void;
    refresh: () => void;
    data: any;
}
function NewTerminal({
    serialNumber,
    setSerialNumber,
    manufacturer,
    setManufacturer,
    model,
    setModel,
    operatingSystem,
    setOperatingSystem,
    upTerminal,
    setUpTerminal,
    interswitchTerminal,
    setInterSwitchTerminal,
    globusTerminal,
    setGlobusTerminal,
    nibbsTerminal,
    setNibbsTerminal,
    refresh,
    data,
}: props) {
    const [load, setLoad] = useState(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const payload = {
            serialNo: serialNumber,
            model,
            manufacturer,
            os: operatingSystem,
            upTid: upTerminal,
            intTid: interswitchTerminal,
            gloTid: globusTerminal,
            nibTid: nibbsTerminal,
        };

        setLoad(true);
        try {
            if (data) {
                await terminalService.editTerminal( payload, data?.id);
                setLoad(false);
                toast.success('Terminal Edited Successfully');
                refresh();
            } else {
                await terminalService.NewTerminal( payload);
                setLoad(false);
                toast.success('Terminal Created Successfully');
                refresh();
            }
        } catch (e) {
            setLoad(false);
            displayError(e, true);
        }
    };

    useEffect(() => {
        setSerialNumber(data?.serialNo);
        setManufacturer(data?.manufacturer);
        setModel(data?.model);
        setOperatingSystem(data?.os);
        setUpTerminal(data?.upTid);
        setInterSwitchTerminal(data?.intTid);
        setGlobusTerminal(data?.gloTid);
        setNibbsTerminal(data?.nibTid);
    }, []);

    return (
        <div className="row">
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={serialNumber}
                    changeVal={setSerialNumber}
                    label="Enter serial number of the machine"
                    type="text"
                    placeholder="Serial Number"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={manufacturer}
                    changeVal={setManufacturer}
                    label="Manufacturer"
                    type="text"
                    placeholder="Manufacturer.."
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={model}
                    changeVal={setModel}
                    label="Model"
                    type="text"
                    placeholder="Model"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <SelectInput
                    option={[
                        {label: 'Android', value: 'android'},
                        {label: 'Linus', value: 'linus'},
                        {label: 'ROTS', value: 'rots'},
                    ]}
                    val={operatingSystem}
                    changeVal={setOperatingSystem}
                    label="Operating system of the Machine"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={upTerminal}
                    changeVal={setUpTerminal}
                    label="Up Terminal ID"
                    type="text"
                    placeholder="For unified payment"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={interswitchTerminal}
                    changeVal={setInterSwitchTerminal}
                    label="Interswitch Terminal ID"
                    type="text"
                    placeholder="For interswitch"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={globusTerminal}
                    changeVal={setGlobusTerminal}
                    label="Globus Terminal ID"
                    type="text"
                    placeholder="For Globus"
                    required
                />
            </div>

            <div className="col-lg-6 mb-3">
                <TextInput
                    val={nibbsTerminal}
                    changeVal={setNibbsTerminal}
                    label="Nibbs Terminal ID"
                    type="text"
                    placeholder="For Nibbs"
                    required
                />
            </div>
            <div onClick={handleSubmit} className="col-lg-12 text-center">
                <button className="main-btn">
                    {load ? <BasicLoad /> : data ? 'Edit' : 'Create'}
                </button>
            </div>
        </div>
    );
}

export default NewTerminal;
