import {DateSelect, SelectInput, TextInput} from '../../BasicInputs';

function EditCustomer({
    setModalState,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    otherName,
    setOtherName,
    email,
    setEmail,
    maritalStatus,
    setMaritalStatus,
    address,
    setAddress,
    dob,
    setDob,
    gender,
    setGender,
    number,
    setNumber,
}: {
    setModalState: (arg: any) => void;
    firstName: string;
    setFirstName: (arg: string) => void;
    lastName: string;
    setLastName: (arg: string) => void;
    otherName: string;
    setOtherName: (arg: string) => void;
    email: string;
    setEmail: (arg: string) => void;
    maritalStatus: string;
    setMaritalStatus: (arg: string) => void;
    address: string;
    setAddress: (arg: string) => void;
    dob: Date;
    setDob: (arg: Date) => void;
    gender: string;
    setGender: (arg: string) => void;
    setNumber: (arg: string) => void;
    number: string;
}) {
    return (
        <div className="row">
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={firstName}
                    changeVal={setFirstName}
                    label="Firstname"
                    placeholder="Enter Firstname"
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={lastName}
                    changeVal={setLastName}
                    label="Lastname"
                    placeholder="Enter Lastname"
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={otherName}
                    changeVal={setOtherName}
                    label="Othername"
                    placeholder="Othername"
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={email}
                    changeVal={setEmail}
                    type="email"
                    label="Email"
                    placeholder="Enter Email"
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={number}
                    changeVal={setNumber}
                    type="number"
                    label="Phone Number"
                    placeholder="Enter Phone Number"
                />
            </div>

            <div className="col-lg-6 mb-3">
                <DateSelect
                    dateVal={dob}
                    changeDateVal={setDob}
                    label="Date of Birth"
                />
            </div>
            <div className="col-lg-6 mb-3">
                <SelectInput
                    val={gender}
                    changeVal={setGender}
                    label="Gender"
                    option={[
                        {label: 'Male', value: 'male'},
                        {label: 'Female', value: 'female'},
                        {label: 'Others', value: 'others'},
                    ]}
                />
            </div>
            <div className="col-lg-6 mb-3">
                <SelectInput
                    val={maritalStatus}
                    changeVal={setMaritalStatus}
                    label="Marital Status"
                    option={[
                        {label: 'Married', value: 'married'},
                        {label: 'Single', value: 'single'},
                        {label: 'Divorced', value: 'divorced'},
                    ]}
                />
            </div>
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={address}
                    changeVal={setAddress}
                    label="Address"
                    placeholder="Enter Address"
                />
            </div>
            <div className="col-lg-12 text-center">
                <button
                    onClick={() => {
                        setModalState({function: 'edit', state: 'otp'});
                    }}
                    className="main-btn">
                    {'Edit Profile'}
                </button>
            </div>
        </div>
    );
}

export default EditCustomer;
