import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import adminService from '../../../redux/features/admin/adminService';
import { displayError } from '../../../utils/errors';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import dateFormat from 'dateformat';
import Paginate from '../../../component/Paginate';
import LoadTable from '../../../component/Loaders/LoadTable';
import { BiPencil, BiTrash } from 'react-icons/bi';
import ModalComponent from '../../../component/ModalComponent';
import AdminForm from '../../../component/Admin/AdminForm';
import SecurityOtp from '../../../component/SecurityOtp';
import { toast } from 'react-toastify';

function AllAdmins() {
    const { token, details: data } = useAppSelector(state => state.auth);

    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const [roles, setRoles] = useState<any>({});
    const [id, setId] = useState(0);

    const [openEdit, setOpenEdit] = useState(false);
    const [details, setDetails] = useState<any>({});

    //Otp

    const [otp, setOtp] = useState('');
    const [otpModal, setOtpModal] = useState(false);
    const [otpLoad, setOtpLoad] = useState(false);
    const [otpDetails, setOtpDetails] = useState<any>({});
    const [otpValue, setOtpValue] = useState('');

    //Form
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [staffId, setStaffId] = useState('');
    const [password, setPassword] = useState('');

    const [role, setRole] = useState<any>(
        details?.admin_roles?.map((i: any) => ({
            label: i.name,
            value: i.id,
        })) || {},
    );
    const [lock, setLock] = useState(false);

    const filters = ``;

    const fetchAdmins = async () => {
        try {
            setLoad(true);
            let res = await adminService.getAdmins(token, filters);
            setLoad(false);
            if (res?.data) {
                setList(res);
            }
        } catch (err) {
            setLoad(false);
            setList([]);
            displayError(err, true);
        }
    };

    const fetchAdminRoles = async () => {
        try {
            let res = await adminService.getAdminRoles(token, filters);
            if (res) {
                setRoles(res);
            }
        } catch (err) {
            setRoles([]);
            displayError(err, true);
        }
    };

    const toggleHandler = async (e: any, userId: any, status: any) => {
        e.preventDefault();
        setOtpLoad(true);
        try {
            await adminService.toggleAdmin(token, status, userId, otp);
            reset();
            toast.success('Admin Toggled Successfully');
        } catch (err) {
            displayError(err, true);
            setOtpLoad(false);
            setOtp('');
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchAdmins();
        fetchAdminRoles();
    }, [page, limit]);

    const deleteAdmin = async (id: number) => {
        setOtpLoad(true);
        try {
            await adminService.deleteAdmin(token, id, otp);
            reset();
            toast.success('Admin Deleted Successfully');
        } catch (err) {
            displayError(err, true);
            setOtpLoad(false);
            setOtp('');
        }
    };

    const reset = () => {
        fetchAdmins();
        setOtpDetails({});
        setOtpLoad(false);
        setOtpModal(false);
        setOtp('');
    };

    const resetGoogleAuth = async (id: number) => {
        setOtpLoad(true);
        try {
            await adminService.resetGoogleAuth(token, id, otp, {
                aes_key: data.aes_key,
                aes_iv: data.aes_iv,
            });
            reset();
            toast.success('Google Auth Reset Successful');
        } catch (err) {
            displayError(err, true);
            setOtpLoad(false);
            setOtp('');
        }
    };

    const handleSubmit = async (e: any) => {
        setOtpLoad(true);
        try {
            if (details.id) {
                let payload = {
                    email,
                    username,
                    staff_id: staffId,
                    role_id: role.value,
                    role,
                    google_auth_code: otp,
                };
                const res = await adminService.editAdmin(
                    token,
                    payload,
                    details.id,
                    { aes_key: data.aes_key, aes_iv: data.aes_iv },
                );
                if (res) {
                    setOtpLoad(false);
                    setOtpModal(false);
                    fetchAdmins();
                    setOtp('');
                    setDetails({});
                    toast.success('Admin Updated Successfully');
                }
            } else {
                let payload = {
                    email,
                    username,
                    staff_id: staffId,
                    role_id: role?.value,
                    role,
                    password,
                    lock,
                    google_auth_code: otp,
                };
                const res = await adminService.createAdmin(token, payload, {
                    aes_key: data.aes_key,
                    aes_iv: data.aes_iv,
                });
                if (res) {
                    setOtpLoad(false);
                    setOtpModal(false);
                    fetchAdmins();
                    setOtp('');
                    setDetails({});
                    toast.success('Admin Created Successfully');
                }
            }
        } catch (err) {
            displayError(err, true);
            setOtpLoad(false);
            setOtp('');
        }
    };
    return (
        <div>
            <BreadCrumbTitle
                title="All Admins"
                dataCount={list?.total}
                button="+ New"
                buttonClick={() => setOpenEdit(true)}
            />

            <div className="basic-card mt-3">
                <div className="head"></div>
                <div className="body">
                    <div className="table-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Email</th>
                                        <th>Staff Id</th>
                                        <th>Username</th>
                                        <th>Date Created</th>
                                        <th>Role</th>
                                        <th>Lock</th>
                                        <th>Reset Google Auth</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>

                                {!load && list?.data?.length > 0 && (
                                    <tbody>
                                        {list.data.map((tr: any, i: number) => (
                                            <tr key={tr.id}>
                                                <td>
                                                    {limit * (page - 1) + i + 1}
                                                </td>
                                                <td>{tr.email}</td>
                                                <td>{tr?.staff_id}</td>
                                                <td>{tr.username}</td>
                                                <td>
                                                    {tr.admin_roles?.created_at
                                                        ? dateFormat(
                                                            tr.admin_roles
                                                                ?.created_at,
                                                            'mmm dd, yyyy / h:MM TT ',
                                                        )
                                                        : '--'}
                                                </td>
                                                <td>
                                                    {(({
                                                        '1': 'Super Admin',
                                                        '3': 'Admin',
                                                        '2': 'Support',
                                                        '4': 'Audit'
                                                    } as any)[tr.role_id] || 'Unknown')}
                                                </td>
                                                <td>
                                                    <div className="form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            onChange={e => {
                                                                setOtpDetails(
                                                                    tr,
                                                                );
                                                                setOtpValue(
                                                                    'toggle',
                                                                );
                                                                setOtpModal(
                                                                    true,
                                                                );
                                                            }}
                                                            checked={
                                                                tr.lock === '0'
                                                                    ? false
                                                                    : true
                                                            }
                                                        />
                                                    </div>
                                                </td>

                                                <td className="link">
                                                    <div
                                                        onClick={() => {
                                                            setOtpModal(true);
                                                            setOtpValue(
                                                                'reset',
                                                            );
                                                            setOtpDetails(
                                                                tr.id,
                                                            );
                                                        }}>
                                                        Reset
                                                    </div>
                                                </td>
                                                <td>
                                                    <span
                                                        onClick={() => {
                                                            setOtpModal(true);
                                                            setOtpDetails(
                                                                tr.id,
                                                            );
                                                            setOtpValue(
                                                                'delete',
                                                            );
                                                        }}>
                                                        <BiTrash />
                                                    </span>
                                                    <span
                                                        onClick={() => {
                                                            setOpenEdit(true);
                                                            setDetails(tr);
                                                        }}>
                                                        <BiPencil />
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        {!load && list?.last_page > 1 && (
                            <Paginate
                                currentPage={page}
                                totalCount={list.total}
                                pageSize={limit}
                                onSelect={(p: number) => setPage(Number(p))}
                                onNext={(p: number) => setPage(p)}
                                onPrev={(p: number) => setPage(p)}
                                changeLimit={(p: string) => setLimit(Number(p))}
                            />
                        )}
                        {load && <LoadTable />}
                    </div>
                </div>
            </div>
            <ModalComponent
                open={openEdit}
                close={() => setOpenEdit(false)}
                title={`${details.id ? 'Edit' : 'Create'} Admin`}>
                <AdminForm
                    close={() => {
                        setOpenEdit(false);
                    }}
                    username={username}
                    setUsername={setUsername}
                    email={email}
                    setEmail={setEmail}
                    staffId={staffId}
                    setStaffId={setStaffId}
                    password={password}
                    setPassword={setPassword}
                    role={role}
                    setRole={setRole}
                    details={details}
                    roles={roles}
                    setOtpModal={setOtpModal}
                    lock={lock}
                    setLock={setLock}
                />
            </ModalComponent>
            {/* Reset Google Auth */}
            <ModalComponent
                open={otpModal}
                title="Enter Google Auth Code"
                close={() => setOtpModal(false)}>
                <SecurityOtp
                    otp={otp}
                    setOtp={setOtp}
                    load={otpLoad}
                    handleSubmit={(e?: any) => {
                        if (otpValue === 'toggle') {
                            toggleHandler(e, otpDetails.id, otpDetails.lock);
                        } else if (otpValue === 'delete') {
                            deleteAdmin(otpDetails);
                        } else if (otpValue === 'reset') {
                            resetGoogleAuth(otpDetails);
                        } else {
                            handleSubmit(e);
                        }
                    }}
                />
            </ModalComponent>
        </div>
    );
}

export default AllAdmins;
