import {apiRequest} from '../../../utils/axiosInstance';

const getCalculatedInterest = async (filters: string) => {
    const {data} = await apiRequest('url').get(
        `/interests/interest/history${filters}`,
    );
    return data.data;
};

const getEarnings = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/interests/interest/earnings${filters}`,
    );
    return data.data;
};

const getInterestPayouts = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/interests/interest/payouts${filters}`,
    );
    return data.data;
};

const getFilterInterestPayouts = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/interests/interest/payouts${filters}`,
    );
    return data.data;
};

const getInterestPayoutsConfig = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/interests/payouts/config${filters}`,
    );
    return data.data;
};

const InterestService = {
    getEarnings,
    getInterestPayouts,
    getCalculatedInterest,
    getInterestPayoutsConfig,
    getFilterInterestPayouts,
};
export default InterestService;
