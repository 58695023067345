import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import Paginate from '../../../component/Paginate';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import {Link, useNavigate} from 'react-router-dom';
import LoadTable from '../../../component/Loaders/LoadTable';

import PageCover from '../../../component/PageCover';
import corporateService from '../../../redux/features/corporate/corporateService';
import {logout} from '../../../redux/features/auth/authSlice';
import {SearchInput} from '../../../component/BasicInputs';
import {UseDebounce} from '../../../utils/hooks';
import { displayError } from '../../../utils/errors';

const CorporateAccount = () => {
    const navigate = useNavigate();

    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(15);

    const [search, setSearch] = useState('');

    const debouncedSearch = UseDebounce(search);

    let filters = `?page=${page}&per_page=${limit}&search_str=${search}`;

    const fetchAccounts = async () => {
        try {
            setLoad(true);
            let res = await corporateService.corporateAccount(filters);
            setLoad(false);
            if (res) {
                setList(res);
            }
        } catch (err: any) {
            setLoad(false);
            setList([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchAccounts();
    }, [page, limit, debouncedSearch]);

    return (
        <PageCover page="Corporate Account">
            <BreadCrumbTitle
                title="Corporate Accounts"
                dataCount={list?.total}
            />
            <div className="row">
                <div className="col-lg-10">
                    {' '}
                    <div className="col-lg-6">
                        <SearchInput val={search} changeVal={setSearch} />
                    </div>
                </div>
                <div className="col-lg-2">
                    <PageCover page="create corporate account" button>
                        <button
                            className="main-btn"
                            onClick={() =>
                                navigate('/dashboard/corporate-account/new')
                            }>
                            + New
                        </button>
                    </PageCover>
                </div>
            </div>

            <div className="basic-card mt-3">
                <div className="head"></div>
                <div className="body">
                    <div className="table-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>s/n</th>
                                        <th>Business Name</th>
                                        <th>Business Phone</th>
                                        <th>Business Email</th>
                                        <th>Director</th>
                                        <th>Admin</th>
                                        <th>Initiator</th>
                                        <th>Authorizer</th>
                                        <th>More</th>
                                    </tr>
                                </thead>
                                {!load && list?.length > 0 && (
                                    <tbody>
                                        {list.map((l: any, i: number) => (
                                            <tr key={l.id}>
                                                <td>
                                                    {limit * (page - 1) + i + 1}
                                                </td>
                                                <td>{l.business_name}</td>
                                                <td>{l.business_phone}</td>
                                                <td>{l.business_email}</td>
                                                <td>{l.director_name}</td>
                                                <td>
                                                    {l.admin?.first_name}{' '}
                                                    {l.admin?.last_name}
                                                </td>
                                                <td>
                                                    {l.initiator?.first_name}{' '}
                                                    {l.initiator?.last_name}
                                                </td>
                                                <td>
                                                    {l.authorizer?.first_name}{' '}
                                                    {l.authorizer?.last_name}
                                                </td>
                                                <td>
                                                    <Link
                                                        to={`/dashboard/corporate-account/${getName(
                                                            l.business_name,
                                                        )}/details`}
                                                        state={l}>
                                                        View all details
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        {!load && list?.last_page > 1 && (
                            <Paginate
                                currentPage={page}
                                totalCount={list.total}
                                pageSize={limit}
                                onSelect={(p: number) => setPage(Number(p))}
                                onNext={(p: number) => setPage(p)}
                                onPrev={(p: number) => setPage(p)}
                                changeLimit={(p: string) => setLimit(Number(p))}
                            />
                        )}
                        {load && <LoadTable />}
                    </div>
                </div>
            </div>
        </PageCover>
    );
};

export default CorporateAccount;

export const getName = (name: string) => {
    return name.replace(/ /g, '_');
};
