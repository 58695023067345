function CustomersDetails({
    details,
    contentRef,
    document,
}: {
    details: any;
    contentRef: any;
    document: any;
}) {
    const styles = {
        page: {
            backgroundColor: '#fff',
            paddingLeft: '50px',
            paddingRight: '50px',
            paddingTop: '10px',
            margin: '20px auto',
        },
        logo: {
            display: 'flex',
            flexDirection: 'row' as const,
            backgroundColor: '#0037ba',
            height: '60px',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        log: {
            width: '200px',
            height: '200px',
            border: '1px solid #000000',
        },
        title: {
            fontSize: '20px',
            color: 'white',
        },
        details: {
            padding: '20px',
            width: '100%',
        },
        top: {
            display: 'flex',
            flexDirection: 'row' as const,
            justifyContent: 'space between',
        },
        left: {
            paddingRight: '5px',
            width: '100%',
        },
        long: {
            borderBottom: '1px solid #656565',
            paddingBottom: '2px',
            marginTop: '15px',
            marginBottom: '15px',
            width: '100%',
        },
        longer: {
            borderBottom: '1px solid #656565',
            paddingBottom: '2px',
            marginTop: '12px',
            marginBottom: '12px',
            width: '100%',
        },
        short: {
            borderBottom: '1px solid #656565',
            paddingBottom: '2px',
            width: '50%',
        },
        box: {
            display: 'flex',
            justifyContent: 'space-between',
            gap: '15px',
            marginTop: '12px',
            marginBottom: '12px',
        },
        div: {
            fontSize: '16px',
        },
        header: {
            fontSize: '14px',
            fontWeight: '600',
            color: '#3b3b3b',
            marginBottom: '3px',
        },
        pre: {
            fontSize: '14px',
            fontWeight: '600',
            color: 'white',
        },
        pri: {
            fontSize: '14px',
            padding: '4px',
            backgroundColor: '#0037ba',
            marginBottom: '5px',
        },
        pro: {
            fontSize: '14px',
            padding: '4px',
            backgroundColor: '#0037ba',
            marginBottom: '5px',
            marginTop: '3rem',
        },
        image: {
            width: '200px',
            height: '200px',
            objectFit: 'fill' as const,
            objectPosition: 'center',
        },
        full: {
            marginTop: '4rem',
            height: '100%',
            width: '100%',
        },
        pageBreak: {
            pageBreakBefore: 'always' as const, // This ensures the image starts on a new page when printing
        },
    };

    return (
        <div>
            <div ref={contentRef} style={styles.page}>
                <div style={styles.logo}>
                    <img
                        alt="no-img"
                        style={{width: 80, height: 40}}
                        src="https://duronetworks.com/wp-content/uploads/2022/01/patrickgold-m-400x400.png"
                    />
                    <div style={styles.title}>User's Primary Information</div>
                </div>

                <div style={styles.details}>
                    <div style={styles.top}>
                        <div style={styles.left}>
                            <div style={styles.pri}>
                                <div style={styles.pre}>
                                    Primary Information
                                </div>
                            </div>
                            <div style={styles.long}>
                                <div style={styles.header}>First Name</div>
                                <div style={styles.div}>
                                    {details?.first_name}
                                </div>
                            </div>
                            <div style={styles.long}>
                                <div style={styles.header}>Middle Name</div>
                                <div style={styles.div}>
                                    {details?.others_name}
                                </div>
                            </div>
                            <div style={styles.long}>
                                <div style={styles.header}>Last Name</div>
                                <div style={styles.div}>
                                    {details?.last_name}
                                </div>
                            </div>
                        </div>
                        <div style={styles.log}>
                            <img
                                alt="no-img"
                                style={styles.image}
                                src={
                                    details?.face_verification_data?.data
                                        ?.imageUrl
                                }
                            />
                        </div>
                    </div>
                    <div style={styles.box}>
                        <div style={styles.short}>
                            <div style={styles.header}>Gender</div>
                            <div style={styles.div}>{details?.gender}</div>
                        </div>
                        <div style={styles.short}>
                            <div style={styles.header}>Date Of Birth</div>
                            <div style={styles.div}>
                                {details?.date_of_birth}
                            </div>
                        </div>
                    </div>
                    <div style={styles.box}>
                        <div style={styles.short}>
                            <div style={styles.header}>Phone Number</div>
                            <div style={styles.div}>
                                {details?.phone_number}
                            </div>
                        </div>
                        <div style={styles.short}>
                            <div style={styles.header}>Email Address</div>
                            <div style={styles.div}>{details?.email}</div>
                        </div>
                    </div>
                    <div style={styles.longer}>
                        <div style={styles.header}>Residential Address</div>
                        <div style={styles.div}>{details?.address}</div>
                    </div>

                    <div style={styles.box}>
                        <div style={styles.short}>
                            <div style={styles.header}>State Of Origin</div>
                            <div style={styles.div}>Nil</div>
                        </div>
                        <div style={styles.short}>
                            <div style={styles.header}>Nationality</div>
                            <div style={styles.div}>Nil</div>
                        </div>
                    </div>
                    <div style={styles.longer}>
                        <div style={styles.header}>Id Type</div>
                        <div style={styles.div}>Nil</div>
                    </div>
                    <div style={styles.box}></div>

                    {/* NIN */}
                    <div style={styles.pri}>
                        <div style={styles.pre}>
                            National Identity Information
                        </div>
                    </div>
                    <div style={styles.box}>
                        <div style={styles.short}>
                            <div style={styles.header}>First Name</div>
                            <div style={styles.div}>
                                {details?.nin_data?.data?.firstName}
                            </div>
                        </div>
                        <div style={styles.short}>
                            <div style={styles.header}>Last Name</div>
                            <div style={styles.div}>
                                {details?.nin_data?.data?.lastname}
                            </div>
                        </div>
                    </div>
                    <div style={styles.box}>
                        <div style={styles.short}>
                            <div style={styles.header}>Middle Name</div>
                            <div style={styles.div}>
                                {details?.nin_data?.data?.middlename}
                            </div>
                        </div>
                        <div style={styles.short}>
                            <div style={styles.header}>Nationality</div>
                            <div style={styles.div}>
                                {details?.nin_data?.data?.birthCountry}
                            </div>
                        </div>
                    </div>
                    <div style={styles.box}>
                        <div style={styles.short}>
                            <div style={styles.header}>Phone Number</div>
                            <div style={styles.div}>
                                {details?.nin_data?.data?.phoneNumber}
                            </div>
                        </div>
                        <div style={styles.short}>
                            <div style={styles.header}>Email</div>
                            <div style={styles.div}>
                                {details?.nin_data?.data?.email}
                            </div>
                        </div>
                    </div>
                    <div style={styles.box}>
                        <div style={styles.short}>
                            <div style={styles.header}>Religion</div>
                            <div style={styles.div}>
                                {details?.nin_data?.data?.religion}
                            </div>
                        </div>
                        <div style={styles.short}>
                            <div style={styles.header}>Date Of Birth</div>
                            <div style={styles.div}>
                                {details?.nin_data?.data?.dateOfBirth}
                            </div>
                        </div>
                    </div>
                    <div style={styles.longer}>
                        <div style={styles.header}>Residential Address</div>
                        <div style={styles.div}>
                            {details?.nin_data?.data?.residenceAddressLine1}
                        </div>
                    </div>
                    <div style={styles.box}></div>
                    {/* BVN*/}

                    <div style={styles.box}></div>

                    <div style={styles.pro}>
                        <div style={styles.pre}>BVN Information</div>
                    </div>
                    <div style={styles.box}>
                        <div style={styles.short}>
                            <div style={styles.header}>First Name</div>
                            <div style={styles.div}>
                                {
                                    details?.face_verification_data?.data
                                        ?.firstname
                                }
                            </div>
                        </div>
                        <div style={styles.short}>
                            <div style={styles.header}>Last Name</div>
                            <div style={styles.div}>
                                {
                                    details?.face_verification_data?.data
                                        ?.lastname
                                }
                            </div>
                        </div>
                    </div>
                    <div style={styles.box}>
                        <div style={styles.short}>
                            <div style={styles.header}>Middle Name</div>
                            <div style={styles.div}>
                                {
                                    details?.face_verification_data?.data
                                        ?.middlename
                                }
                            </div>
                        </div>
                        <div style={styles.short}>
                            <div style={styles.header}>Date Of Birth</div>
                            <div style={styles.div}>
                                {
                                    details?.face_verification_data?.data
                                        ?.birthdate
                                }
                            </div>
                        </div>
                    </div>
                    <div style={styles.box}>
                        <div style={styles.short}>
                            <div style={styles.header}>Phone Number</div>
                            <div style={styles.div}>
                                {details?.face_verification_data?.data?.phone}
                            </div>
                        </div>
                        <div style={styles.short}>
                            <div style={styles.header}>Email</div>
                            <div style={styles.div}>
                                {details?.face_verification_data?.data?.email}
                            </div>
                        </div>
                    </div>
                    <div style={styles.box}>
                        <div style={styles.short}>
                            <div style={styles.header}>Gender</div>
                            <div style={styles.div}>
                                {details?.face_verification_data?.data?.gender}
                            </div>
                        </div>
                        <div style={styles.short}>
                            <div style={styles.header}>Nationality</div>
                            <div style={styles.div}>
                                {
                                    details?.face_verification_data?.data
                                        ?.phoneCountryCode
                                }
                            </div>
                        </div>
                    </div>
                    {document.map((props: any, i: any) => {
                        if (props.document_url.substr(0, 4) === 'http') {
                            return (
                                <div key={i} style={styles.pageBreak}>
                                    <img
                                        style={styles.full}
                                        src={props.document_url}
                                        alt="document"
                                    />
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            </div>
            <div></div>
        </div>
    );
}

export default CustomersDetails;
