import {getPayload} from '../../../utils/encrypt';
import {apiRequest} from '../../../utils/axiosInstance';

const getTransactionConfig = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_txn_limit_configs${filters}`,
    );
    return data.data;
};

const setTransactionConfig = async (payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl').post(
        `/transaction/set_txn_limit_config`,
        payload,
    );

    return data;
};
const updateTransactionConfig = async (payload: any, id: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl').patch(
        `/transaction/update_txn_limit_config/${id}`,
        payload,
    );

    return data;
};

const getSetAboutUs = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_txn_limit_configs${filters}`,
    );
    return data.data;
};

const getPromotion = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_all_promo_configs${filters}`,
    );

    return data;
};

const getTransactionCategories = async () => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_txn_categories`,
    );

    return data;
};

const getServiceCode = async (id: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/services_by_catId/${id}`,
    );

    return data;
};

const getTransactionLimit = async () => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_txn_limit_types`,
    );

    return data;
};

const sendPromotion = async (payload: any) => {
    const {data} = await apiRequest('baseUrl').post(
        `/transaction/add_promotion`,
        payload,
    );

    return data;
};
const updatePromotion = async (payload: any, id: any) => {
    const {data} = await apiRequest('baseUrl').patch(
        `/transaction/update_promo_config/${id}`,
        payload,
    );

    return data;
};

const getIncentiveConfig = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_txn_limit_configs${filters}`,
    );
    return data.data;
};

const extraService = {
    getTransactionConfig,
    getTransactionCategories,
    getIncentiveConfig,
    getPromotion,
    getSetAboutUs,
    getServiceCode,
    sendPromotion,
    updatePromotion,
    getTransactionLimit,
    setTransactionConfig,
    updateTransactionConfig,
};

export default extraService;
