import {useEffect, useState} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import agentService from '../../../redux/features/agent/agentService';
import {displayError} from '../../../utils/errors';
import LoadTable from '../../../component/Loaders/LoadTable';
import Paginate from '../../../component/Paginate';
import dateFormat from 'dateformat';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import ModalComponent from '../../../component/ModalComponent';
import AgentCategory from '../../../component/Agents/AgentCategory';
import ViewAgentCategory from '../../../component/Agents/ViewAgentCategory';
import PageCover from '../../../component/PageCover';

function AgentCategories() {
    //NEW AGENT CATEGORY
    const [name, setName] = useState('');
    const [description, setDescription] = useState<any>('');
    const [childCategory, setChildCategory] = useState<any>('');
    const [agentCategory, setAgentCategory] = useState(false);

    //FILTERS
    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});
   
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(15);

    const [view, setView] = useState(false);
    const [viewDetails, setViewDetails] = useState<any>(null);

    const filters = `?page=${page}&size=${limit}`;

    const fetchAgentsCategories = async () => {
        try {
            setLoad(true);
            const res = await agentService.AgentCategories();

            setLoad(false);
            setList(res);
        } catch (err) {
            setLoad(false);
            setList([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchAgentsCategories();
    }, [filters]);
    return (
        <PageCover page="Agent Categories">
            <div>
                <BreadCrumbTitle
                    title="Agents Categories"
                    dataCount={list?.totalElements}
                />
                <div className="basic-card mt-3">
                    <div className="head"></div>
                    <div className="body">
                        <div className="table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Last Updated</th>
                                            <th>Shares </th>
                                        </tr>
                                    </thead>
                                    {!load && list?.length > 0 && (
                                        <tbody>
                                            {list.map((tr: any, i: number) => (
                                                <tr key={tr.id}>
                                                    <td>
                                                        {' '}
                                                        {limit * (page - 1) +
                                                            i +
                                                            1}
                                                    </td>

                                                    <td>{tr.name}</td>
                                                    <td>{tr.description}</td>
                                                    <td>
                                                        {' '}
                                                        {dateFormat(
                                                            tr.createdAt,
                                                            'mmm dd, yyyy | h:MM TT',
                                                        )}
                                                    </td>
                                                    <td
                                                        role="button"
                                                        className="text-primary"
                                                        onClick={() => {
                                                            setView(true);
                                                            setViewDetails(tr);
                                                        }}>
                                                        View
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                            {!load && list?.totalPages > 1 && (
                                <Paginate
                                    currentPage={page}
                                    totalCount={list.totalElements}
                                    pageSize={limit}
                                    lastPage={list.totalPages}
                                    onSelect={(p: number) => setPage(Number(p))}
                                    onNext={(p: number) => setPage(p)}
                                    onPrev={(p: number) => setPage(p)}
                                    changeLimit={(p: string) =>
                                        setLimit(Number(p))
                                    }
                                />
                            )}
                            {load && <LoadTable />}
                        </div>
                    </div>
                </div>

                <ModalComponent
                    open={agentCategory}
                    close={() => setAgentCategory(false)}
                    size="sm"
                    title="Agent Category">
                    <AgentCategory
                        name={name}
                        setName={setName}
                        description={description}
                        setDescription={setDescription}
                        childCategory={childCategory}
                        setChildCategory={setChildCategory}
                    />
                </ModalComponent>

                <ModalComponent
                    open={view}
                    close={() => setView(false)}
                    size="sm"
                    title="Agent Category">
                    <ViewAgentCategory {...viewDetails} />
                </ModalComponent>
            </div>
        </PageCover>
    );
}

export default AgentCategories;
