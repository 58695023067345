import {apiRequest} from '../../../utils/axiosInstance';

const allDispute = async (filters: string) => {
    const {data} = await apiRequest('posUrl').get(`/dispute${filters}`);
    return data.data;
};

const getTicketDetails = async (id: number) => {
    const {data} = await apiRequest('posUrl').get(
        `/dispute/get-dispute-replies/${id}`,
    );
    return data.data;
};

const postComment = async (payload: any) => {
    const {data} = await apiRequest('posUrl').post(`/dispute-comment`, payload);
    return data.data;
};

const closeTicket = async (payload: any) => {
    const {data} = await apiRequest('posUrl').post(`/dispute/update`, payload);
    return data.data;
};

const disputeService = {
    allDispute,
    getTicketDetails,
    postComment,
    closeTicket,
};

export default disputeService;
