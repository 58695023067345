import {authHeader} from '../../../utils/headers';
import {getPayload} from '../../../utils/encrypt';
import {apiRequest} from '../../../utils/axiosInstance';

const getLevels = async () => {
    const {data} = await apiRequest('baseUrl').get(`/user/kyc_levels`);
    return data.data;
};

const setKycLevels = async (payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl').post(
        `/user/kyc_levels`,
        passPayload,
    );
    return data.data;
};

const getKycDetails = async (id: number) => {
    const {data} = await apiRequest('baseUrl').get(
        `user/user_documents?user_id=${id}`,
    );
    return data.data;
};

const updateKycLevels = async (payload: any, id: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl').patch(
        `/user/kyc_levels/${id}`,
        passPayload,
    );
    return data.data;
};

const getUploadedDocuments = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/user_documents${filters}`,
    );
    return data.data;
};

const getUpgradeRequest = async (filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/kyc_upgrade_request${filters}`,
    );
    return data.data;
};

const approveRequest = async (
    payload: any,
    key: any,
    comment: string,
    otp: string,
) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/admin/user_document/${comment}`,
        passPayload,
        {
            headers: authHeader(otp),
        },
    );
    return data.data;
};

const approveSingleRequest = async (payload: any, key: any, otp: string) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/admin/kyc_upgrade_request/approve`,
        passPayload,
        {
            headers: authHeader(otp),
        },
    );
    return data.data;
};
const KYCService = {
    getUpgradeRequest,
    getUploadedDocuments,
    getLevels,
    setKycLevels,
    updateKycLevels,
    approveRequest,
    getKycDetails,
    approveSingleRequest,
};
export default KYCService;
