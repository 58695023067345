export const dev = {
    base: '',
    baseUrl3Admin: 'https://api.test.getripay.com/api/admin/v3',
    posUrl: 'https://pos-api.testpos.getripay.com/tms/api',
    baseUrl: 'https://api.test.getripay.com/api/v3',
    url:'https://api.test.getripay.com/api/v1',
    posUrl2:'https://pos-api.testpos.getripay.com/pos',
};

export const prod = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    posUrl: process.env.REACT_APP_POS_URL,
    posUrl2:process.env.REACT_APP_POS_URL2,
    baseUrl3Admin: process.env.REACT_APP_BASE_URL_ADMIN,
    base: process.env.REACT_APP_BASE,
    url:process.env.REACT_APP_URL
};

const config = process.env.REACT_APP_ENV === 'production' ? prod : dev;

export default config;
