import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../../redux/hooks';
import {displayError} from '../../../../utils/errors';
import LoadTable from '../../../../component/Loaders/LoadTable';
import dateFormat from 'dateformat';
import BreadCrumbTitle from '../../../../component/BreadCrumbTitle';
import {toast} from 'react-toastify';
import Paginate from '../../../../component/Paginate';
import PageCover from '../../../../component/PageCover';
import posService from '../../../../redux/features/pos/posService';

function CardChannels() {
    const {token} = useAppSelector(state => state.auth);

    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>([]);

    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);

    const filters = `?page=${page}&limit=${limit}`;

    const cardChannelList = async () => {
        setLoad(true);
        try {
            const res = await posService.cardChannels(token);
            setList(res);
            setLoad(false);
        } catch (err) {
            displayError(err, true);
            setLoad(false);
        }
    };

    const toggle = async (id: number, status: string) => {
        const payload = {enabled: status ? 'false' : 'true'};
        try {
            await posService.updateCardChannels(token, id, payload);
            cardChannelList();
            toast.success('Approved');
        } catch (err) {
            displayError(err, true);
        }
    };

    useEffect(() => {
        cardChannelList();
        window.scrollTo(0, 0);
    }, [filters]);

    return (
        <PageCover page="Card Channels">
            <div>
                <BreadCrumbTitle
                    title="Card Channels"
                    dataCount={list?.total}
                />
                <div className="basic-card mt-3">
                    <div className="head"></div>
                    <div className="body">
                        <div className="table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Description</th>
                                            <th>Name</th>
                                            <th>Created At</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    {!load && list?.length > 0 && (
                                        <tbody>
                                            {list.map((tr: any, i: number) => (
                                                <tr key={tr?.id}>
                                                    <td>
                                                        {' '}
                                                        {limit * (page - 1) +
                                                            i +
                                                            1}
                                                    </td>

                                                    <td>{tr.description}</td>
                                                    <td>{tr.name}</td>

                                                    <td>
                                                        {dateFormat(
                                                            tr?.created_at,
                                                        )}
                                                    </td>
                                                    <td>
                                                        <div className="form-check form-switch">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                onChange={e => {
                                                                    toggle(
                                                                        tr.id,
                                                                        tr.enabled,
                                                                    );
                                                                }}
                                                                checked={
                                                                    tr.enabled
                                                                     
                                                                }
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                            {!load && list?.last_page > 1 && (
                                <Paginate
                                    currentPage={page}
                                    totalCount={list.total}
                                    pageSize={limit}
                                    onSelect={(p: number) => setPage(Number(p))}
                                    onNext={(p: number) => setPage(p)}
                                    onPrev={(p: number) => setPage(p)}
                                    changeLimit={(p: string) =>
                                        setLimit(Number(p))
                                    }
                                />
                            )}
                        </div>
                    </div>
                    {load && <LoadTable />}
                </div>
            </div>
        </PageCover>
    );
}

export default CardChannels;
