import React from 'react';

function Verification({details}: {details: any}) {
    const data = details?.nin_data?.data;
    const data2 = details?.face_verification_data?.data;
    return (
        <div className="">
            <div className="row">
                <div className="col-lg-4 customer-details">
                    {/* Customers Details */}
                    <div className="title">Customers Details</div>
                    {details &&
                        Object.entries(details).map(([key, value]: any) => {
                            if (
                                key === 'last_name' ||
                                key === 'first_name' ||
                                key === 'others_name' ||
                                key === 'phone_number'
                            ) {
                                return (
                                    <div
                                        key={key}
                                        className="customer-details-veri">
                                        <span className="left">{key}</span>
                                        <strong
                                            className="right"
                                            style={{
                                                display: 'block',
                                            }}>
                                            {value}
                                        </strong>
                                    </div>
                                );
                            }
                            if (key === 'nin') {
                                return (
                                    <div
                                        key={key}
                                        className="customer-details-veri">
                                        <span className="left">{key}</span>
                                        <strong className="right">
                                            {value?.substring(0, 4)}
                                            *******
                                        </strong>
                                    </div>
                                );
                            }
                            if (key === 'profile_photo') {
                                return (
                                    <div
                                        key={key}
                                        className="customer-details-veri">
                                        <span className="left">{key}</span>
                                        <img
                                            className="right"
                                            src={value}
                                            alt={key}
                                            style={{
                                                width: '100px',
                                                height: '100px',
                                            }}
                                        />
                                    </div>
                                );
                            }
                            return null;
                        })}
                </div>

                {/* Face Verification */}
                <div className="col-lg-4 customer-details">
                    <div className="title">Face (BVN) Verification</div>
                    {/* Render name details first */}
                    {details?.has_verified_face &&
                        data2 &&
                        Object.entries(data2)
                            .filter(([key, value]: any) =>
                                [
                                    'lastname',
                                    'firstname',
                                    'middlename',
                                    'iTphone',
                                ].includes(key),
                            )
                            .map(([key, value]: any) => (
                                <div
                                    key={key}
                                    className="customer-details-veri">
                                    <span className="left">{key}</span>
                                    <strong
                                        className={
                                            (details?.first_name
                                                ?.toString()
                                                .trim()
                                                .toLowerCase() ?? '') ===
                                                (data2?.firstname
                                                    ?.toString()
                                                    .trim()
                                                    .toLowerCase() ?? '') &&
                                            (details?.last_name
                                                ?.toString()
                                                .trim()
                                                .toLowerCase() ?? '') ===
                                                (data2?.lastname
                                                    ?.toString()
                                                    .trim()
                                                    .toLowerCase() ?? '')
                                                ? 'right'
                                                : 'right text-danger'
                                        }>
                                        {value}
                                    </strong>
                                </div>
                            ))}

                    {details?.has_verified_face && data2 ? (
                        Object.entries(data2).map(([key, value]: any) => {
                            if (
                                key === 'bvn_check' ||
                                (key === 'face_verification_check' &&
                                    typeof value === 'object')
                            ) {
                                return null;
                            } else if (key === 'imageUrl' || key === 'photo') {
                                const imageUrl =
                                    key === 'imageUrl'
                                        ? value
                                        : `data:image/jpeg;base64,${value}`;
                                const info =
                                    key === 'photo' ? 'Bvn Photo' : 'Qore Id';
                                return (
                                    <div
                                        key={key}
                                        className="customer-details-veri">
                                        <span className="left">{info}</span>
                                        <img
                                            className="right"
                                            src={imageUrl}
                                            alt={key}
                                            style={{
                                                width: '100px',
                                                height: '100px',
                                            }}
                                        />
                                    </div>
                                );
                            } else if (key === 'percentageSimilarity') {
                                return (
                                    <div
                                        key={key}
                                        className="customer-details-veri ">
                                        <span className="left">
                                            {key?.substr(0, 10)}
                                        </span>
                                        <strong className="right">
                                            {value}%
                                        </strong>
                                    </div>
                                );
                            }

                            return null;
                        })
                    ) : (
                        <div className="text-center">No Data</div>
                    )}
                </div>

                {/*  NIN Verification */}
                <div className="col-lg-4 customer-details">
                    <div className="title">NIN Verification</div>
                    {details?.has_verified_nin && data ? (
                        Object.entries(data).map(([key, value]: any) => {
                            if (
                                key === 'firstName' ||
                                key === 'lastName' ||
                                key === 'phoneNumber'
                            ) {
                                return (
                                    <div
                                        key={key}
                                        className="customer-details-veri">
                                        <span className="left">{key}</span>
                                        <strong
                                            className={
                                                (details?.first_name
                                                    ?.toString()
                                                    .trim()
                                                    .toLowerCase() ?? '') ===
                                                    (data?.firstName
                                                        ?.toString()
                                                        .trim()
                                                        .toLowerCase() ?? '') &&
                                                (details?.last_name
                                                    ?.toString()
                                                    .trim()
                                                    .toLowerCase() ?? '') ===
                                                    (data?.lastName
                                                        ?.toString()
                                                        .trim()
                                                        .toLowerCase() ?? '')
                                                    ? 'right'
                                                    : 'right text-danger'
                                            }>
                                            {value}
                                        </strong>
                                    </div>
                                );
                            }
                            if (key === 'photo') {
                                const imageUrl = `data:image/jpeg;base64,${value}`;
                                return (
                                    <div
                                        key={key}
                                        className="customer-details-veri">
                                        <span className="left">{key}</span>
                                        <img
                                            className="right"
                                            src={imageUrl}
                                            alt={key}
                                            style={{
                                                width: '100px',
                                                height: '100px',
                                            }}
                                        />
                                    </div>
                                );
                            }
                            if (key === 'nin') {
                                return (
                                    <div
                                        key={key}
                                        className="customer-details-veri">
                                        <span className="left">{key}</span>
                                        <strong className="right">
                                            {value?.substring(0, 4)}
                                            *******
                                        </strong>
                                    </div>
                                );
                            }
                            return null;
                        })
                    ) : (
                        <div className="text-center">No Data</div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Verification;
