import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import {displayError} from '../../../utils/errors';
import terminalService from '../../../redux/features/terminals/terminalService';
import {toast} from 'react-toastify';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import LoadTable from '../../../component/Loaders/LoadTable';
import Paginate from '../../../component/Paginate';
import agentService from '../../../redux/features/agent/agentService';
import ModalComponent from '../../../component/ModalComponent';
import ApproveTerminalModal from '../../../component/Terminals/ApproveTerminalModal';
import PageCover from '../../../component/PageCover';

function ApproveTerminals() {
    const {token} = useAppSelector(state => state.auth);

    const [list, setList] = useState<any>([]);
    const [load, setLoad] = useState(false);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(30);
    const [agent, setAgent] = useState<any>([]);
    const [catId, setCatId] = useState('');
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [terminal, setTerminal] = useState('');
    const [id, setId] = useState('');

    const filters = `?page=${page}`;

    const fetchAllApprovals = async () => {
        setLoad(true);
        try {
            const res = await terminalService.getTerminalApproval(
                token,
                filters,
            );
            setLoad(false);
            setList(res);
        } catch (err) {
            displayError(err, true);
        }
    };

    const fetchAgentsCategories = async () => {
        try {
            setLoad(true);
            const res = await agentService.AgentCategories(token);

            setLoad(false);
            setAgent(res);
        } catch (err) {
            setLoad(false);
            setList([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        fetchAllApprovals();
        fetchAgentsCategories();
        window.scrollTo(0, 0);
    }, []);

    const approveTerminal = async (id: any, type: string, agentCat?: any) => {
        setLoading(true);
        if (window.confirm(`Are you sure you want to ${type} this terminal`)) {
            try {
                await terminalService.ApproveTerminal(
                    token,
                    id,
                    type,
                    agentCat,
                    terminal,
                );
                toast.success(`Terminal ${type}ed`);
                setLoading(false);
                fetchAllApprovals();
                setOpen(false);
            } catch (err) {
                displayError(err, true);
                setLoading(false);
            }
        }
    };

    return (
        <PageCover page="Approve Terminals">
            <div>
                <BreadCrumbTitle
                    title="Approve Terminal"
                    dataCount={list?.totalElements}
                />
                <div className="basic-card mt-3">
                    <div className="head"></div>
                    <div className="body">
                        <div className="table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Profile</th>
                                            <th>Business Name</th>
                                            <th>Email</th>
                                            <th>Mobile</th>
                                            <th>Full Name</th>
                                            <th>BVN</th>
                                            <th>Date of Birth</th>
                                            <th>CAC</th>
                                            <th>Identity Doc</th>
                                            <th>Status</th>
                                            <th>Business Address</th>
                                            <th>POS Address</th>
                                            <th>Terminal Serial Number</th>
                                            <th>Requested From</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    {!load && list?.content?.length > 0 && (
                                        <tbody>
                                            {list.content.map(
                                                (t: any, i: number) => (
                                                    <tr key={t.id}>
                                                        <td>
                                                            {' '}
                                                            {page + 0 + i + 1}
                                                        </td>
                                                        <td>
                                                            {t.agentId
                                                                ? 'Existing Agent'
                                                                : 'New Agent'}
                                                        </td>
                                                        <td>
                                                            {t.businessName}
                                                        </td>
                                                        <td>{t.email}</td>
                                                        <td>{t.phone}</td>
                                                        <td>
                                                            {t.firstName}{' '}
                                                            {t.lastName}
                                                        </td>
                                                        <td>{t.bvn}</td>
                                                        <td>{t.dateOfBirth}</td>
                                                        <td>{t.cac}</td>
                                                        <td>
                                                            {t.identityDocument}
                                                        </td>
                                                        <td>{t.status}</td>
                                                        <td>
                                                            {t.businessAddress}
                                                        </td>
                                                        <td>{t.location}</td>
                                                        <td>{t.serialNo}</td>
                                                        <td>{t.createdFrom}</td>
                                                        <td className="d-flex gap-3">
                                                            {t.agentId ? (
                                                                <span>--</span>
                                                            ) : (
                                                                <>
                                                                    <div
                                                                        role="button"
                                                                        className="link"
                                                                        style={{
                                                                            borderRight:
                                                                                '1px solid black',
                                                                            paddingRight:
                                                                                '15px',
                                                                            color: 'blue',
                                                                            textDecoration:
                                                                                'underline',
                                                                        }}
                                                                        onClick={e => {
                                                                            setOpen(
                                                                                true,
                                                                            );
                                                                            setId(
                                                                                t.id,
                                                                            );
                                                                        }}>
                                                                        Approve
                                                                    </div>
                                                                    <div
                                                                        role="button"
                                                                        className="link"
                                                                        style={{
                                                                            color: 'blue',
                                                                            textDecoration:
                                                                                'underline',
                                                                        }}
                                                                        onClick={e => {
                                                                            e.preventDefault();
                                                                            approveTerminal(
                                                                                t.id,
                                                                                'reject',
                                                                            );
                                                                        }}>
                                                                        Reject
                                                                    </div>
                                                                </>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ),
                                            )}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                            {!load && list?.totalPages > 1 && (
                                <Paginate
                                    currentPage={page}
                                    totalCount={list.totalElements}
                                    pageSize={limit}
                                    lastPage={list.totalPages}
                                    onSelect={(p: number) => setPage(Number(p))}
                                    onNext={(p: number) => setPage(p)}
                                    onPrev={(p: number) => setPage(p)}
                                    changeLimit={(p: string) =>
                                        setLimit(Number(p))
                                    }
                                />
                            )}
                            {load && <LoadTable />}
                        </div>
                    </div>
                </div>
                <ModalComponent open={open} close={() => setOpen(false)}>
                    <ApproveTerminalModal
                        agents={agent}
                        catId={catId}
                        setCatId={setCatId}
                        click={() => approveTerminal(id, 'approve', catId)}
                        loading={loading}
                        terminal={terminal}
                        setTerminal={setTerminal}
                    />
                </ModalComponent>
            </div>
        </PageCover>
    );
}

export default ApproveTerminals;
