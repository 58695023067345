import {apiRequest} from '../../../utils/axiosInstance';
import {getPayload} from '../../../utils/encrypt';

const corporateAccount = async (filters: any) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/corporate_profiles${filters}`,
    );
    return data.data;
};

const createCorporateAccount = async (payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/corporate_profiles`,
        passPayload,
    );
    return data.data;
};

const corporateProfileUsers = async (id: number) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/corporate-profile-users?corporate_profile_id=${id}`,
    );
    return data.data;
};
const corporateAccountUsers = async (id: number) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/corporate-profile-account-users?corporate_profile_id=${id}`,
    );
    return data.data;
};

const corporateBankAccount = async (id: number) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/corporate-profiles/${id}/bank_accounts`,
    );
    return data.data;
};

const corporateService = {
    corporateAccount,
    createCorporateAccount,
    corporateAccountUsers,
    corporateBankAccount,
    corporateProfileUsers,
};

export default corporateService;
