import React, {useEffect, useState} from 'react';
import {
    SearchInput,
    SelectInput,
    TextInput,
} from '../../../component/BasicInputs';
import axios from 'axios';
import {displayError} from '../../../utils/errors';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {authHeader, headers} from '../../../utils/headers';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import config from '../../../utils/config';
import BasicLoad from '../../../component/Loaders/BasicLoad';
import agentService from '../../../redux/features/agent/agentService';
import PageCover from '../../../component/PageCover';
import {UseDebounce} from '../../../utils/hooks';
import customerServices from '../../../redux/features/customer/customerService';
import {logout} from '../../../redux/features/auth/authSlice';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';

function New() {
    const {id} = useParams();

    const details = useLocation().state;

    const type = id === 'new' ? 'create' : 'edit';

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [phone, setPhone] = useState('');
    const [businessAddress, setBusinessAddress] = useState('');
    const [userId, setUserId] = useState('');
    const [superAgent, setSuperAgent] = useState('');
    const [agentCat, setAgentCat] = useState('');
    const [imageLoader, setImageLoader] = useState(false);
    const [cac, setCac] = useState('');
    const [isSuperAgent, setIsSuperAgent] = useState<boolean>(false);
    const [addressProofUrl, setAddressProofUrl] = useState('');
    const [identityDocumentUrl, setIdentityDocumentUrl] = useState('');
    const [load, setLoad] = useState(false);
    const [catDet, setCatDet] = useState<any>([]);
    const [search, setSearch] = useState('');
    const [radioId, setRadioId] = useState(false);
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState<any>([]);
    const [show, setShow] = useState(false);
    const [skipSearch, setSkipSearch] = useState(false);

    const {token, details: data} = useAppSelector(state => state.auth);

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const page = 1;
    const limit = 50;

    const debouncedSearch = UseDebounce(search);

    const searchUsers = async () => {
        try {
            setLoading(true);
            setShow(true);
            let res = await customerServices.searchCustomers(
                token,
                debouncedSearch,
                limit,
                page,

                {
                    aes_key: data.aes_key,
                    aes_iv: data.aes_iv,
                },
            );
            setLoading(false);
            if (res?.data) {
                setList(res);
            }
        } catch (err: any) {
            setLoading(false);
            displayError(err, true);
            setList([]);
            if (err?.response?.status === 401) {
                dispatch(logout());
            }
            setShow(false);
        }
    };

    useEffect(() => {
        if (search.length > 0 && !skipSearch) {
            searchUsers();
        } else if (skipSearch) {
            setSkipSearch(false);
        }
    }, [debouncedSearch]);

    console.log(skipSearch);

    useEffect(() => {
        if (details?.id && type === 'edit') {
            setFirstName(details.firstName);
            setLastName(details.lastName);
            setEmail(details.email);
            setBusinessAddress(details.businessAddress);
            setBusinessName(details.businessName);
            setPhone(details.phone);
            setUserId(details.userId);
            setIsSuperAgent(details.isSuperAgent);
            setIdentityDocumentUrl(details.identityDocument);
            setCac(details.cac);
            setAddressProofUrl(details.addressProof);
            setAgentCat(details.agentCategoryId);
            setSuperAgent(details.superAgentId);
        }
    }, [details]);

    const checkHandler = (
        e: React.ChangeEvent<HTMLInputElement>,
        type: string,
    ) => {
        if (e.target.checked && type === 'No') {
            console.log('Test1');
            setIsSuperAgent(false);
        } else if (e.target.checked && type === 'Yes') {
            console.log('Test 2');
            setIsSuperAgent(true);
        }
    };

    const handleFileUpload = async (
        e: React.ChangeEvent<HTMLInputElement>,
        type: string,
    ) => {
        try {
            setImageLoader(true);
            const file = e.target.files?.[0];
            if (!file) return;

            const formData = new FormData();
            formData.append('file', file);

            const {data} = await axios.post(
                `https://api.getripay.com/files/api/documents`,
                formData,
            );
            const res = data?.data?.document_url;
            switch (type) {
                case 'cac':
                    setCac(res);
                    break;
                case 'addressProof':
                    setAddressProofUrl(res);
                    break;
                case 'identityDocument':
                    setIdentityDocumentUrl(res);
                    break;
                default:
                    break;
            }
        } catch (err) {
            console.error(err);
            alert('Unable to connect to upload document');
        } finally {
            setImageLoader(false);
        }
    };

    const handleAgentSubmit = async (e: any) => {
        e.preventDefault();
        const payload = {
            firstName,
            lastName,
            email,
            businessAddress,
            businessName,
            phone,
            userId,
            isSuperAgent,
            identityDocument: identityDocumentUrl,
            cac,
            addressProof: addressProofUrl,
            agentCategoryId: agentCat,
            superAgentId: superAgent,
        };
        try {
            setLoad(true);
            let response;
            if (id !== 'new') {
                response = await axios.patch(
                    `${config.posUrl}/agent/${id}`,
                    payload,
                    {
                        headers: authHeader(token),
                    },
                );
            } else {
                response = await axios.post(`${config.posUrl}/agent`, payload, {
                    headers: authHeader(token),
                });
            }

            const data = response.data;
            if (data.success) {
                navigate('/dashboard/agents/all');
            }
        } catch (err: any) {
            displayError(err, true);
        } finally {
            setLoad(false);
        }
    };

    const getAgentCat = async () => {
        try {
            const res = await agentService.AgentCategories(token);
            setCatDet(res);
        } catch (err) {
            displayError(err, true);
        }
    };

    console.log(list, userId);

    useEffect(() => {
        getAgentCat();
    }, []);

    return (
        <PageCover page="Agent Form">
            <BreadCrumbTitle
                title={`${type === 'create' ? 'Create' : 'Edit'} Agent`}
            />
            <div className="row">
                <div className="col-lg-12">
                    <div className="basic-card mt-3">
                        <div className="head"></div>
                        <div className="body">
                            <div className="form-body">
                                <form
                                    onSubmit={handleAgentSubmit}
                                    className="row agent-box">
                                    <div className="col-lg-6 mb-3">
                                        <TextInput
                                            val={firstName}
                                            changeVal={setFirstName}
                                            label="First Name"
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <TextInput
                                            val={lastName}
                                            changeVal={setLastName}
                                            label="last Name"
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <TextInput
                                            val={email}
                                            changeVal={setEmail}
                                            label="Email"
                                            type="email"
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <label>
                                            Does Agent have a getripay Account?
                                        </label>
                                        {radioId ? (
                                            <div className="drop-search">
                                                <SearchInput
                                                    val={search}
                                                    changeVal={setSearch}
                                                />
                                                {show && (
                                                    <div className="drop-content">
                                                        {loading &&
                                                            'loading...'}
                                                        {!loading &&
                                                            list?.data?.length >
                                                                0 &&
                                                            list.data.map(
                                                                (tr: any) => (
                                                                    <div
                                                                        onClick={() => {
                                                                            setUserId(
                                                                                tr.id,
                                                                            );
                                                                            setShow(
                                                                                false,
                                                                            );
                                                                            setSkipSearch(
                                                                                true,
                                                                            );
                                                                            setSearch(`  ${tr.first_name}
                                                                        ${tr.last_name}`);
                                                                        }}>
                                                                        {
                                                                            tr.first_name
                                                                        }{' '}
                                                                        {
                                                                            tr.last_name
                                                                        }
                                                                    </div>
                                                                ),
                                                            )}
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="d-flex gap-3">
                                                {' '}
                                                <div className="d-flex align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        checked={radioId}
                                                        onChange={e =>
                                                            setRadioId(false)
                                                        }
                                                    />
                                                    <span className="ml-2">
                                                        No
                                                    </span>
                                                </div>
                                                <div className="d-flex align-items-center mr-3">
                                                    <input
                                                        type="checkbox"
                                                        checked={radioId}
                                                        onChange={e =>
                                                            setRadioId(true)
                                                        }
                                                    />
                                                    <span className="ml-2">
                                                        Yes
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-lg-6 mb-3">
                                        <TextInput
                                            val={businessAddress}
                                            changeVal={setBusinessAddress}
                                            label="Business Address"
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <TextInput
                                            val={businessName}
                                            changeVal={setBusinessName}
                                            label="Business Name"
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <TextInput
                                            val={phone}
                                            changeVal={setPhone}
                                            label="Phone Number"
                                            required
                                        />
                                    </div>

                                    <div className="col-lg-6 mb-3">
                                        <SelectInput
                                            option={[]}
                                            val={superAgent}
                                            placeholder="select"
                                            changeVal={setSuperAgent}
                                            label="Select super Agent"
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <SelectInput
                                            option={catDet?.map((i: any) => ({
                                                label: i.name,
                                                value: i.id,
                                            }))}
                                            val={agentCat}
                                            placeholder="select"
                                            changeVal={setAgentCat}
                                            label="Agent Category"
                                        />
                                    </div>
                                    <div className="mb-2 mt-4 col-lg-6">
                                        <label>Is Agent a Super Agent?</label>
                                        <div className="d-flex col-lg-11 gap-3">
                                            <div className="d-flex align-items-center mr-3">
                                                <input
                                                    type="checkbox"
                                                    checked={isSuperAgent}
                                                    onChange={e =>
                                                        checkHandler(e, 'Yes')
                                                    }
                                                />
                                                <span className="ml-2">
                                                    Yes
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <input
                                                    type="checkbox"
                                                    checked={!isSuperAgent}
                                                    onChange={e =>
                                                        checkHandler(e, 'No')
                                                    }
                                                />
                                                <span className="ml-2">No</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column mt-3 col-lg-6 mb-3">
                                        <label>
                                            {type === 'edit'
                                                ? 'Change'
                                                : 'Upload'}{' '}
                                            CAC Document
                                        </label>
                                        <div className="upload-btn-wrapper">
                                            <button className="btn wide">
                                                Click to Upload
                                            </button>
                                            <input
                                                type="file"
                                                name="myfile"
                                                onChange={e =>
                                                    handleFileUpload(e, 'cac')
                                                }
                                                disabled={imageLoader}
                                            />
                                        </div>
                                        {imageLoader && <p>Loading...</p>}
                                        {cac && (
                                            <a
                                                href={cac}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{fontSize: '0.7rem'}}>
                                                View Uploaded file
                                            </a>
                                        )}
                                    </div>
                                    <div className="d-flex flex-column mt-3 col-lg-6 mb-3">
                                        <label>
                                            {type === 'edit'
                                                ? 'Change'
                                                : 'Upload'}{' '}
                                            Proof of Address
                                        </label>
                                        <div className="upload-btn-wrapper">
                                            <button className="btn wide">
                                                Click to Upload
                                            </button>
                                            <input
                                                type="file"
                                                name="myfile"
                                                onChange={e =>
                                                    handleFileUpload(
                                                        e,
                                                        'addressProof',
                                                    )
                                                }
                                                disabled={imageLoader}
                                            />
                                        </div>
                                        {imageLoader && <p>Loading...</p>}
                                        {addressProofUrl && (
                                            <a
                                                href={addressProofUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{fontSize: '0.7rem'}}>
                                                View Uploaded file
                                            </a>
                                        )}
                                    </div>
                                    <div className="d-flex flex-column mt-3 col-lg-6 mb-3">
                                        <label>
                                            {type === 'edit'
                                                ? 'Change'
                                                : 'Upload'}{' '}
                                            Valid ID Card
                                        </label>
                                        <div className="upload-btn-wrapper">
                                            <button className="btn wide">
                                                Click to Upload
                                            </button>
                                            <input
                                                type="file"
                                                name="myfile"
                                                onChange={e =>
                                                    handleFileUpload(
                                                        e,
                                                        'identityDocument',
                                                    )
                                                }
                                                disabled={imageLoader}
                                            />
                                        </div>
                                        {imageLoader && <p>Loading...</p>}
                                        {identityDocumentUrl && (
                                            <a
                                                href={identityDocumentUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{fontSize: '0.7rem'}}>
                                                View Uploaded file
                                            </a>
                                        )}
                                    </div>
                                    <div className="col-lg-6"></div>
                                    <div className="col-lg-12 text-center">
                                        {load ? (
                                            <BasicLoad />
                                        ) : (
                                            <button
                                                type="submit"
                                                className="main-btn">
                                                {' '}
                                                {type === 'edit'
                                                    ? 'Update'
                                                    : 'Create'}
                                            </button>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageCover>
    );
}

export default New;
