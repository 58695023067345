import Select from 'react-select';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import {FiSearch} from 'react-icons/fi';

export interface OptionProp {
    value: string | number;
    label: string;
    isActive?: boolean;
    id?: number;
}

interface InputProp {
    val: string;
    changeVal: (arg: string) => void;
    label?: string;
    type?: string;
    option?: any;
    required?: boolean;
    placeholder?: string;
    disabled?: boolean;
}

interface checkProp {
    val: boolean;
    changeVal: (arg: boolean) => void;
    label?: string;
    type?: string;
    option?: any;
}

interface DateProp {
    dateVal: Date;
    changeDateVal: (arg: any) => void;
    label?: string;
    required?: boolean;
}

interface SelectProp {
    options: OptionProp[];
    changeSelected: (arg: any) => void;
    label?: string;
    value: OptionProp | null;
    loading?: boolean;
    placeholder?: string;

}

export const DateSelect = ({dateVal, changeDateVal, label,required}: DateProp) => {
    return (
        <div className="date-select">
            <label className="label">{label}</label>
            <DatePicker
                selected={dateVal}
                onChange={date => changeDateVal(date)}
                dateFormat='yyyy-MM-dd'
                required={required}
            />
        </div>
    );
};

export const TextInput = ({
    val,
    changeVal,
    label,
    required,
    type,
    placeholder,
    disabled
}: InputProp) => {
    return (
        <div className="basic-input">
            <label className="label">{label}</label>
            <input
                value={val}
                onChange={e => changeVal(e.target.value)}
                type={type || 'text'}
                required={required}
                placeholder={placeholder}
                disabled={disabled}
            />
        </div>
    );
};

export const TextArea = ({
    val,
    changeVal,
    label,
    required,
    type,
    placeholder,
    disabled
}: InputProp) => {
    return (
        <div className="basic-input">
            <label className="label">{label}</label>
            <textarea
                value={val}
                onChange={e => changeVal(e.target.value)}
                required={required}
                placeholder={placeholder}
                disabled={disabled}
                rows={4}
                cols={40}
            />
        </div>
    );
};




// export const CheckInput = ({val, changeVal, label, type}: checkProp) => {
//     return (
//         <div className="basic-input">
//             <label className="label">{label}</label>
//             <input
//                 value={val}
//                 onChange={e => changeVal(e.target.value)}
//                 type={'checkbox'}
//             />
//         </div>
//     );
// };

export const SearchInput = ({val, changeVal, type}: InputProp) => {
    return (
        <div className="basic-input search">
            <input
                value={val}
                onChange={e => changeVal(e.target.value)}
                type={'search'}
                placeholder="Search For..."
            />
            <FiSearch />
        </div>
    );
};

export const SelectInput = ({
    val,
    changeVal,
    label,
    option,
    placeholder,
}: InputProp) => {
    return (
        <div className="basic-input">
            <label className="label">{label}</label>
            <select value={val} onChange={e => changeVal(e.target.value)}>
                <option value={''}>{placeholder || ''}</option>
                {option &&
                    Array.isArray(option) &&
                    option.map((op, i) => (
                        <option value={op.value} key={i + 1}>
                            {op.label}
                        </option>
                    ))}
            </select>
        </div>
    );
};

export const DropDownSelect = ({
    options,
    changeSelected,
    value,
    label,
    loading,
    placeholder,
}: SelectProp) => {
    const customStyles = {
        control: (base: any, state: any) => ({
            ...base,
            background: '#fff',
            border: '1px solid #c2c7df',
            height: '45px',
            width: '100%',
            fontSize: '0.9rem',
            borderRadius: '4px',
        }),
        placeholder: (base: any, state: any) => ({
            ...base,
            color: '#333',
            fontSize: '0.9rem',
        }),
        menu: (base: any, state: any) => ({
            ...base,
            color: '#333',
            fontSize: '0.9rem',
        }),
        singleValue: (base: any, state: any) => ({
            ...base,
            color: '#333',
            fontSize: '0.9rem',
        }),
        option: (base: any, props: any) => {
            return {
                ...base,
                color: props.data?.isService ? 'blue' : 'black',
            };
        },
    };

    return (
        <div className="select">
            <label>{label}</label>
            <Select
                options={options}
                styles={customStyles}
                placeholder={placeholder || `Select`}
                onChange={v => changeSelected(v)}
                value={value}
                isLoading={loading}
                isClearable
            />
        </div>
    );
};

const BasicInputs = () => {
    return <div>BasicInputs</div>;
};

export default BasicInputs;
