import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppSelector} from '../../../redux/hooks';
import {TextInput} from '../../../component/BasicInputs';
import axios from 'axios';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import {displayError} from '../../../utils/errors';
import ModalComponent from '../../../component/ModalComponent';
import SecurityOtp from '../../../component/SecurityOtp';
import {toast} from 'react-toastify';
import corporateService from '../../../redux/features/corporate/corporateService';

function CorporateForm({id, details}: {id?: string; details?: any}) {
    const {token, details: det} = useAppSelector(state => state.auth);

    // const type = id !== 'new' ? 'edit' : 'create';

    const navigate = useNavigate();

    //OTP
    const [otp, setOtp] = useState('');
    const [otpModal, setOtpModal] = useState(false);
    const [otpLoad, setOtpLoad] = useState(false);

    //Business Info
    const [business_name, setBusinessName] = useState('');
    const [business_address, setBusinessAddress] = useState('');
    const [business_email, setBusinessEmail] = useState('');
    const [business_phone, setBusinessPhone] = useState('');
    const [cac_reg_no, setCacRegNo] = useState('');
    const [tin, setTin] = useState('');
    const [business_start_date, setBusinessStartDate] = useState('');
    const [business_type, setBusinessType] = useState('');
    const [industry_sector, setIndustrySector] = useState('');
    const [director_name, setDirectorName] = useState('');
    const [director_email, setDirectorEmail] = useState('');
    const [director_phone, setDirectorPhone] = useState('');
    const [director_address, setDirectorAddress] = useState('');
    const [admin, setAdmin] = useState({
        first_name: '',
        last_name: '',
        others_name: '',
        address: '',
        email: '',
        phone_number: '',
        bvn: '',
        date_of_birth: '',
    });
    const [initiator, setInitiator] = useState({
        first_name: '',
        last_name: '',
        others_name: '',
        address: '',
        email: '',
        phone_number: '',
        bvn: '',
        date_of_birth: '',
    });
    const [authorizer, setAuthorizer] = useState({
        first_name: '',
        last_name: '',
        others_name: '',
        address: '',
        email: '',
        phone_number: '',
        bvn: '',
        date_of_birth: '',
    });
    const [document_url, setDocumentUrl] = useState('');
    const [loader, setLoader] = useState(false);
    const [imageLoader, setImageLoader] = useState(false);

    const uploadDocument = async (e: any) => {
        try {
            setImageLoader(true);
            let file = e.target.files[0];
            const formData = new FormData();
            formData.append('file', file);

            const {data} = await axios.post(
                `https://api.getripay.com/files/api/documents`,
                formData,
                // {aes_key: det.aes_key, aes_iv: det.aes_iv},
            );
            let res = data?.data?.document_url;
            setDocumentUrl(res);
            setImageLoader(false);
        } catch (err) {
            setImageLoader(false);
            alert('Unable to connect to upload document');
        }
    };
    const prefill = (from: any, to: any) => {
        if (from === 'director') {
            setAdmin({
                ...admin,
                first_name: director_name,
                email: director_email,
                phone_number: director_phone,
                address: director_address,
            });
        } else if (to === 'initiator') {
            setInitiator(admin);
        } else if (to === 'authorizer') {
            setAuthorizer(admin);
        }
    };

    const submitForm = (e: any) => {
        e.preventDefault();
        setOtpModal(true);
    };

    useEffect(() => {
        if (details?.id) {
            setBusinessName(details?.business_name);
            setBusinessAddress(details?.business_address);
            setBusinessEmail(details?.business_email);
            setBusinessPhone(details?.business_phone);
            setCacRegNo(details?.cac_reg_no);
            setTin(details?.tin);
            setBusinessStartDate(details?.business_start_date);
            setBusinessType(details?.business_type);
            setIndustrySector(details?.industry_sector);
            setDirectorName(details?.director_name);
            setDirectorEmail(details?.director_email);
            setDirectorPhone(details?.director_phone);
            setDirectorAddress(details?.director_address);
            setAdmin(details?.admin);
            setInitiator(details?.initiator);
            setAuthorizer(details?.authorizer);
            setDocumentUrl(details?.document_url);
            setLoader(true);
        } else {
            navigate('/dashboard/corporate-account/new');
        }
    }, []);

    const submitHandler = async (e: any) => {
        e.preventDefault();
        if (document_url) {
            setOtpLoad(true);
            try {
                let obj = {
                    business_name,
                    business_address,
                    business_email,
                    business_phone,
                    cac_reg_no,
                    tin,
                    business_start_date,
                    business_type,
                    industry_sector,
                    director_name,
                    director_email,
                    director_phone,
                    director_address,
                    admin,
                    initiator,
                    authorizer,
                    document_url,
                    google_auth_code: otp,
                };
                const res = await corporateService.createCorporateAccount(
                    token,
                    obj,
                    {aes_key: det.aes_key, aes_iv: det.aes_iv},
                );
                if (res) {
                    setOtpLoad(false);
                    setOtpModal(false);
                    toast.success('Account Creation Successful');
                    navigate('/dashboard/corporate-account');
                }
            } catch (err) {
                displayError(err, true);
                setOtpLoad(false);
            }
        } else {
            alert('Please upload a document');
        }
    };
    return (
        <div>
            {id !== 'new' && <BreadCrumbTitle title={`Create Corporate`} />}

            <div className="row">
                <div className="col-lg-12">
                    <div className="basic-card mt-3">
                        <div className="head"></div>
                        <div className="body">
                            <div className="form-body">
                                <form
                                    onSubmit={submitForm}
                                    className=" agent-box">
                                    <h5>BUSINESS INFORMATION</h5>
                                    <div className="row">
                                        <div className="col-lg-4 mb-3">
                                            <TextInput
                                                type="text"
                                                required
                                                label="Business Name"
                                                disabled={loader}
                                                val={business_name}
                                                changeVal={setBusinessName}
                                                placeholder="Enter business name"
                                            />
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <TextInput
                                                type="email"
                                                required
                                                disabled={loader}
                                                val={business_email}
                                                changeVal={setBusinessEmail}
                                                label="Business email"
                                                placeholder="Enter business email"
                                            />
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <TextInput
                                                type="number"
                                                required
                                                disabled={loader}
                                                val={business_phone}
                                                changeVal={setBusinessPhone}
                                                label="Business Phone Number"
                                                placeholder="Enter business Phone Number"
                                            />
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <TextInput
                                                type="text"
                                                required
                                                disabled={loader}
                                                val={business_type}
                                                changeVal={setBusinessType}
                                                label="Business Type"
                                                placeholder="Enter business Type"
                                            />
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <TextInput
                                                type="text"
                                                required
                                                disabled={loader}
                                                val={industry_sector}
                                                changeVal={setIndustrySector}
                                                label="Industry sectors"
                                                placeholder="Enter industry sector"
                                            />
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            {!details?.id ? (
                                                <TextInput
                                                    type="date"
                                                    required
                                                    disabled={loader}
                                                    val={business_start_date}
                                                    changeVal={
                                                        setBusinessStartDate
                                                    }
                                                    label="Business Start Date"
                                                    placeholder="enter business start date"
                                                />
                                            ) : (
                                                <TextInput
                                                    type="text"
                                                    disabled={true}
                                                    val={new Date(
                                                        business_start_date,
                                                    ).toDateString()}
                                                    changeVal={
                                                        setBusinessStartDate
                                                    }
                                                    label="Business Start Date"
                                                    placeholder="enter business start date"
                                                />
                                            )}
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <TextInput
                                                type="text"
                                                required
                                                disabled={loader}
                                                val={cac_reg_no}
                                                changeVal={setCacRegNo}
                                                label="C.A.C"
                                                placeholder="enter cac"
                                            />
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <TextInput
                                                type="text"
                                                required
                                                disabled={loader}
                                                val={tin}
                                                changeVal={setTin}
                                                label="TIN"
                                                placeholder="Enter Tin"
                                            />
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <TextInput
                                                type="text"
                                                required
                                                disabled={loader}
                                                val={business_address}
                                                changeVal={setBusinessAddress}
                                                label="Business Address"
                                                placeholder="Enter business address"
                                            />
                                        </div>
                                        <h5 className="mt-3">
                                            DIRECTOR'S INFORMATION
                                        </h5>
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <TextInput
                                                    type="text"
                                                    required
                                                    disabled={loader}
                                                    val={director_name}
                                                    changeVal={setDirectorName}
                                                    label="Directors Name"
                                                    placeholder="Enter directors Name"
                                                />
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <TextInput
                                                    type="email"
                                                    required
                                                    disabled={loader}
                                                    val={director_email}
                                                    changeVal={setDirectorEmail}
                                                    label="Directors Email"
                                                    placeholder="Enter directors Email"
                                                />
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <TextInput
                                                    type="text"
                                                    required
                                                    disabled={loader}
                                                    val={director_phone}
                                                    changeVal={setDirectorPhone}
                                                    label="Directors Phone"
                                                    placeholder="Enter directors Phone"
                                                />
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <TextInput
                                                    type="text"
                                                    required
                                                    disabled={loader}
                                                    val={director_address}
                                                    changeVal={
                                                        setDirectorAddress
                                                    }
                                                    label="Directors Address"
                                                    placeholder="Enter directors address"
                                                />
                                            </div>
                                        </div>
                                        <h5 className="mt-5">DOCUMENT</h5>
                                        <div className="row">
                                            <div className="col-lg-2 mb-3">
                                                <label className="label">
                                                    {imageLoader
                                                        ? 'Hold On! Image is uploading...'
                                                        : 'Upload Document'}
                                                </label>
                                                <div className="upload-btn-wrapper">
                                                    <button className="btn wide">
                                                        Click to Upload
                                                    </button>
                                                    <input
                                                        type="file"
                                                        onChange={
                                                            uploadDocument
                                                        }
                                                        disabled={imageLoader}
                                                    />
                                                </div>
                                                {document_url && (
                                                    <div>
                                                        <a
                                                            href={document_url}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            style={{
                                                                fontSize:
                                                                    '0.9rem',
                                                                textDecoration:
                                                                    'underline',
                                                            }}>
                                                            View Uploaded Image
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}>
                                            <h5 className="mt-4">
                                                DIRECTORS DETAILS
                                            </h5>
                                            {!details?.id && (
                                                <div>
                                                    <div
                                                        style={{
                                                            fontSize: '0.8rem',
                                                            color: ' blue',
                                                            textDecoration:
                                                                'underline',
                                                        }}
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            prefill(
                                                                'director',
                                                                'admin',
                                                            );
                                                        }}>
                                                        Prefill with Director's
                                                        details
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-4 basic-input mb-3">
                                            <label className="label">
                                                First Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter first name"
                                                required
                                                disabled={loader}
                                                value={admin?.first_name}
                                                onChange={e =>
                                                    setAdmin({
                                                        ...admin,
                                                        first_name:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-lg-4 basic-input  mb-3">
                                            <label className="label">
                                                Last Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter last name"
                                                required
                                                disabled={loader}
                                                value={admin?.last_name}
                                                onChange={e =>
                                                    setAdmin({
                                                        ...admin,
                                                        last_name:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-lg-4 basic-input  mb-3">
                                            <label className="label">
                                                Other Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Other Name"
                                                required
                                                disabled={loader}
                                                value={admin?.others_name}
                                                onChange={e =>
                                                    setAdmin({
                                                        ...admin,
                                                        others_name:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-lg-4 basic-input  mb-3">
                                            <label className="label">
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Enter email address"
                                                required
                                                disabled={loader}
                                                value={admin?.email}
                                                onChange={e =>
                                                    setAdmin({
                                                        ...admin,
                                                        email: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-lg-4 basic-input  mb-3">
                                            <label className="label">
                                                Mobile Number
                                            </label>
                                            <input
                                                type="tel"
                                                className="form-control"
                                                placeholder="Enter Mobile Number"
                                                required
                                                disabled={loader}
                                                value={admin?.phone_number}
                                                onChange={e =>
                                                    setAdmin({
                                                        ...admin,
                                                        phone_number:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-lg-4 basic-input  mb-3">
                                            <label className="label">
                                                Address
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter email address"
                                                required
                                                disabled={loader}
                                                value={admin?.address}
                                                onChange={e =>
                                                    setAdmin({
                                                        ...admin,
                                                        address: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-lg-6 basic-input  mb-3">
                                            <label className="label">BVN</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter BVN"
                                                required
                                                disabled={loader}
                                                value={admin?.bvn}
                                                onChange={e =>
                                                    setAdmin({
                                                        ...admin,
                                                        bvn: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-lg-6 basic-input  mb-3">
                                            <label className="label">
                                                Date of Birth
                                            </label>
                                            {!details?.id ? (
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    required
                                                    disabled={loader}
                                                    value={admin?.date_of_birth}
                                                    onChange={e =>
                                                        setAdmin({
                                                            ...admin,
                                                            date_of_birth:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            ) : (
                                                <input
                                                    type="text"
                                                    disabled={true}
                                                    className="form-control"
                                                    value={new Date(
                                                        admin?.date_of_birth,
                                                    ).toDateString()}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}>
                                        <h5 className="mt-4">
                                            INITIATOR DETAILS
                                        </h5>
                                        {id === 'new' && (
                                            <div>
                                                <div
                                                    style={{
                                                        fontSize: '0.8rem',
                                                        textDecoration:
                                                            'underline',
                                                        color: 'blue',
                                                    }}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        prefill(
                                                            'admin',
                                                            'initiator',
                                                        );
                                                    }}>
                                                    Prefill with Admin's details
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-4 basic-input  mb-3">
                                            <label className="label">
                                                First Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter First Name"
                                                required
                                                disabled={loader}
                                                value={initiator?.first_name}
                                                onChange={e =>
                                                    setInitiator({
                                                        ...initiator,
                                                        first_name:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-lg-4 basic-input  mb-3">
                                            <label className="label">
                                                Last Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                required
                                                placeholder="Enter last name"
                                                disabled={loader}
                                                value={initiator?.last_name}
                                                onChange={e =>
                                                    setInitiator({
                                                        ...initiator,
                                                        last_name:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-lg-4 basic-input  mb-3">
                                            <label className="label">
                                                Other Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                required
                                                placeholder="Enter other names"
                                                disabled={loader}
                                                value={initiator?.others_name}
                                                onChange={e =>
                                                    setInitiator({
                                                        ...initiator,
                                                        others_name:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-lg-4 basic-input mb-3">
                                            <label className="label">
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                required
                                                placeholder="Enter email address"
                                                disabled={loader}
                                                value={initiator?.email}
                                                onChange={e =>
                                                    setInitiator({
                                                        ...initiator,
                                                        email: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-lg-4 basic-input  mb-3">
                                            <label className="label">
                                                Mobile Number
                                            </label>
                                            <input
                                                type="tel"
                                                className="form-control"
                                                placeholder="Enter Mobile Number"
                                                required
                                                disabled={loader}
                                                value={initiator?.phone_number}
                                                onChange={e =>
                                                    setInitiator({
                                                        ...initiator,
                                                        phone_number:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-lg-4 basic-input  mb-3">
                                            <label className="label">
                                                Address
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter address"
                                                required
                                                disabled={loader}
                                                value={initiator?.address}
                                                onChange={e =>
                                                    setInitiator({
                                                        ...initiator,
                                                        address: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-lg-6 basic-input  mb-3">
                                            <label className="label">BVN</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter BVN"
                                                required
                                                disabled={loader}
                                                value={initiator?.bvn}
                                                onChange={e =>
                                                    setInitiator({
                                                        ...initiator,
                                                        bvn: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-lg-6 basic-input  mb-3">
                                            <label className="label">
                                                Date of Birth
                                            </label>
                                            {!details?.id ? (
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    required
                                                    disabled={loader}
                                                    value={
                                                        initiator?.date_of_birth
                                                    }
                                                    onChange={e =>
                                                        setInitiator({
                                                            ...initiator,
                                                            date_of_birth:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            ) : (
                                                <input
                                                    type="text"
                                                    disabled={true}
                                                    className="form-control"
                                                    value={new Date(
                                                        initiator?.date_of_birth,
                                                    ).toDateString()}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}>
                                        <h5 className="mt-4">
                                            AUTHORIZER DETAILS
                                        </h5>
                                        {!details?.id && (
                                            <div>
                                                <div
                                                    style={{
                                                        fontSize: '0.8rem',
                                                        textDecoration:
                                                            'underline',
                                                        color: 'blue',
                                                    }}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        prefill(
                                                            'admin',
                                                            'authorizer',
                                                        );
                                                    }}>
                                                    Prefill with Admin's details
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-4 basic-input  mb-3">
                                            <label className="label">
                                                First Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter first name"
                                                required
                                                disabled={loader}
                                                value={authorizer?.first_name}
                                                onChange={e =>
                                                    setAuthorizer({
                                                        ...authorizer,
                                                        first_name:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-lg-4 basic-input  mb-3">
                                            <label className="label">
                                                Last Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter last name"
                                                required
                                                disabled={loader}
                                                value={authorizer?.last_name}
                                                onChange={e =>
                                                    setAuthorizer({
                                                        ...authorizer,
                                                        last_name:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-lg-4 basic-input  mb-3">
                                            <label className="label">
                                                Other Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter other names"
                                                required
                                                disabled={loader}
                                                value={authorizer?.others_name}
                                                onChange={e =>
                                                    setAuthorizer({
                                                        ...authorizer,
                                                        others_name:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-lg-4 basic-input mb-3">
                                            <label className="label">
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Enter email address"
                                                required
                                                disabled={loader}
                                                value={authorizer?.email}
                                                onChange={e =>
                                                    setAuthorizer({
                                                        ...authorizer,
                                                        email: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-lg-4 basic-input mb-3">
                                            <label className="label">
                                                Mobile Number
                                            </label>
                                            <input
                                                type="tel"
                                                className="form-control"
                                                placeholder="Enter mobile number"
                                                required
                                                disabled={loader}
                                                value={authorizer?.phone_number}
                                                onChange={e =>
                                                    setAuthorizer({
                                                        ...authorizer,
                                                        phone_number:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-lg-4 basic-input  mb-3">
                                            <label className="label">
                                                Address
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                disabled={loader}
                                                placeholder="Enter Address"
                                                required
                                                value={authorizer?.address}
                                                onChange={e =>
                                                    setAuthorizer({
                                                        ...authorizer,
                                                        address: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-lg-6 basic-input  mb-3">
                                            <label className="label">BVN</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter BVN"
                                                required
                                                disabled={loader}
                                                value={authorizer?.bvn}
                                                onChange={e =>
                                                    setAuthorizer({
                                                        ...authorizer,
                                                        bvn: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-lg-6 basic-input mb-3">
                                            <label className="label">
                                                Date of Birth
                                            </label>
                                            {!details?.id ? (
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    required
                                                    disabled={loader}
                                                    value={
                                                        authorizer?.date_of_birth
                                                    }
                                                    onChange={e =>
                                                        setAuthorizer({
                                                            ...authorizer,
                                                            date_of_birth:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            ) : (
                                                <input
                                                    type="text"
                                                    disabled={true}
                                                    className="form-control"
                                                    value={new Date(
                                                        authorizer?.date_of_birth,
                                                    ).toDateString()}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    {!details?.id && (
                                        <div className="text-center mt-3">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                disabled={loader}>
                                                {loader
                                                    ? 'Please Hold...'
                                                    : 'Create Account'}
                                            </button>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalComponent
                open={otpModal}
                title="Enter Google Auth Code"
                close={() => setOtpModal(false)}>
                <SecurityOtp
                    otp={otp}
                    setOtp={setOtp}
                    load={otpLoad}
                    handleSubmit={(e?: any) => {
                        submitHandler(e);
                    }}
                />
            </ModalComponent>
        </div>
    );
}

export default CorporateForm;
