import React, {useEffect, useState} from 'react';
import {displayError} from '../../../utils/errors';
import reportService from '../../../redux/features/reports/reportService';
import dateFormat from 'dateformat';
import {DateSelect} from '../../../component/BasicInputs';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import LoadTable from '../../../component/Loaders/LoadTable';
import PageCover from '../../../component/PageCover';

function UserReport() {
    const [startDate, setStartDate] = useState(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    );
    const [endDate, setEndDate] = useState(
        new Date(new Date().setDate(new Date().getDate() + 1)),
    );

    const [report, setReport] = useState<any>([]);

    const [load, setLoad] = useState(false);

    const filter = `?startDate=${dateFormat(
        startDate,
        'yyyy-mm-dd',
    )}&endDate=${dateFormat(endDate, 'yyyy-mm-dd')}`;

    const getInstitutionReport = async () => {
        setLoad(true);
        try {
            const res = await reportService.getUserReport(filter);
            if (res) {
                setReport(res?.data?.report);
            }
            setLoad(false);
        } catch (err) {
            displayError(err, true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getInstitutionReport();
    }, [filter]);

    // Data transformation
    const transformData = (report: any[]) => {
        const tableData: Record<string, any> = {};

        report.forEach((user: any) => {
            const userId = user._id;

            if (!tableData[userId]) tableData[userId] = {};

            user.serviceTransactions.forEach((transaction: any) => {
                const serviceName = transaction.service[0]?.serviceName;

                if (!tableData[userId][serviceName]) {
                    tableData[userId][serviceName] = {
                        completed: 0,
                        pending: 0,
                        failed: 0,
                    };
                }

                transaction.statusTransactions.forEach((status: any) => {
                    if (status.status === 'TS_COMPLETED') {
                        tableData[userId][serviceName].completed +=
                            status.count;
                    } else if (status.status === 'TS_FAILED') {
                        tableData[userId][serviceName].failed += status.count;
                    } else if (status.status === 'TS_PENDING') {
                        tableData[userId][serviceName].pending += status.count;
                    }
                });
            });
        });

        return tableData;
    };

    const tableData = transformData(report);

    // Extract unique service names
    const serviceNames = Array.from(
        new Set(
            report.flatMap((user: any) =>
                user.serviceTransactions.map(
                    (transaction: any) => transaction.service[0]?.serviceName,
                ),
            ),
        ),
    );

    return (
        <PageCover page="User Report">
            <div>
                <BreadCrumbTitle title="User Report" />
                <div className="row">
                    <div className="col-md-6 mb-3"></div>
                    <div className="col-md-6 mb-3">
                        <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                                {' '}
                                <DateSelect
                                    dateVal={startDate}
                                    changeDateVal={setStartDate}
                                    label="Start Date"
                                />
                            </div>
                            <div className="col-md-4">
                                {' '}
                                <DateSelect
                                    dateVal={endDate}
                                    changeDateVal={setEndDate}
                                    label="End Date"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="basic-card mt-3">
                    <div className="head"></div>
                    <div className="body">
                        <div className="table-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>User</th>
                                            {!load &&
                                                serviceNames.map(
                                                    (name: any) => (
                                                        <th
                                                            key={name}
                                                            colSpan={2}>
                                                            {name}
                                                        </th>
                                                    ),
                                                )}
                                        </tr>
                                        {/* <tr>
                                        <th></th>
                                        {!load &&
                                            serviceNames.map((name: any) => (
                                                <React.Fragment key={name}>
                                                    <th>Completed</th>
                                                    <th>Pending</th>
                                                    <th>Failed</th>
                                                </React.Fragment>
                                            ))}
                                    </tr> */}
                                    </thead>
                                    <tbody>
                                        {!load &&
                                            Object.entries(tableData).map(
                                                ([userId, services]: any) => (
                                                    <tr key={userId}>
                                                        <td>{userId}</td>
                                                        {serviceNames.map(
                                                            (name: any) => {
                                                                const service =
                                                                    services[
                                                                        name
                                                                    ] || {
                                                                        completed: 0,
                                                                        pending: 0,
                                                                        failed: 0,
                                                                    };
                                                                return (
                                                                    <React.Fragment
                                                                        key={`${userId}-${name}`}>
                                                                        <td>
                                                                            <span
                                                                                style={{
                                                                                    color: 'green',
                                                                                    width: '10px',
                                                                                }}>
                                                                                {' '}
                                                                                {
                                                                                    service.completed
                                                                                }
                                                                            </span>{' '}
                                                                            |
                                                                            <span
                                                                                style={{
                                                                                    color: 'yellow',
                                                                                    width: '10px',
                                                                                }}>
                                                                                {
                                                                                    service.pending
                                                                                }
                                                                            </span>{' '}
                                                                            |
                                                                            <span
                                                                                style={{
                                                                                    color: 'red',
                                                                                    width: '10px',
                                                                                }}>
                                                                                {
                                                                                    service.failed
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                    </React.Fragment>
                                                                );
                                                            },
                                                        )}
                                                    </tr>
                                                ),
                                            )}
                                    </tbody>
                                </table>

                                {load && <LoadTable />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageCover>
    );
}

export default UserReport;
