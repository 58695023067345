import { toast } from "react-toastify";
import store from "../redux/store";
import { logout } from "../redux/features/auth/authSlice";

const displayError = (error: any, display?: boolean) => {
	const message =
		error?.response?.data?.message ||
		error?.response?.data?.response_message ||
		error?.message?.toString() ||
		error.toString();

	if (display) {
		toast.error(Array.isArray(message) ? message[0] : String(message));
	}
 
	if (error?.response?.status === 401) {
		store.dispatch(logout()); 
	}

	return message;
};

export { displayError };