const headers = {
    'Content-Type': 'application/json',
    Authorization:'timilehin',
    'f-x-token':
        'eyJraWQiOiJNbjVDS1EiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIxOjI1ODkzMTUzMDI0NTphbmRyb2lkOmM0Mjk4YTQ5OTEzZGIwYTg3ZmRhNmMiLCJhdWQiOlsicHJvamVjdHNcLzI1ODkzMTUzMDI0NSIsInByb2plY3RzXC9nZXRyaXBheS1ub3RpZmljYXRpb25zIl0sInByb3ZpZGVyIjoiZGVidWciLCJpc3MiOiJodHRwczpcL1wvZmlyZWJhc2VhcHBjaGVjay5nb29nbGVhcGlzLmNvbVwvMjU4OTMxNTMwMjQ1IiwiZXhwIjoxNzE5MzMyNjIyLCJpYXQiOjE3MTkzMjkwMjIsImp0aSI6Im1PMjl4RzZFaTRfeXNraUxtSTlGc3pqTWZIVklXODluRlIxQmhteEwwLWcifQ.J9Kqs-QkaA97DR4_aGS2BTftW3DmChaHpxf5gL43wP-somJ1ufmn1T9mI4deId5_XmgZZplUu-9Xx-QIvvbZvVDZgJvNv5v5W-GlzbHO24r2vjXF0zzpJoEK16RKWNdUs0qODfj6uS4mr9cire2GKLKXM5GXzWEXD-J8M9AYvC1h1CcooJmZN-N0OTAKJxPVPCFjz6BFDQNfk2jhzOgJkCbLOFIls5vATCH3vB1g4MfnhQOnXgU6mJHExn6yOh9hWCJNYvYB_rl9lKtPXVhLFKlKzl79jJCiPtlhdZVM32-OVJpesSZqwYtHz1V-Yn9YgKM9aYduq1HlZtx3gE-HwSZ2fY6hpmqGIKkxrWdIoqe0NOMVEqewfZ3t4OxRZ6pul_2-btnVYnbWmOz2PVz7ZaUhz4EiNzqvGDhiu3jn6gBgSm4Fj8zFhJUAVbFCHB5EqmgpMROWpKbkH0gNtUGwfGcmuoj-4G9aAV2JF2OFUac-D-I905BttxbTF-tQw4Bx',
};

const authHeader = ( otp?: any) => ({
    'Content-Type': 'application/json',
    'google-auth-code': otp,
});

export {headers, authHeader};
