import {authHeader} from '../../../utils/headers';
import {getPayload} from '../../../utils/encrypt';
import {apiRequest} from '../../../utils/axiosInstance';

const AllCustomers = async (payload: any, key: any) => {
    let passPayload = getPayload(key, {}, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/users-all${payload}`,
        passPayload,
    );
    return data.data;
};

const searchCustomers = async (
    word: string,
    limit: number,
    page: number,
    key: any,
) => {
    let passPayload = getPayload(key, {}, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/search/users?words=${word}&per_page=${limit}&page=${page}`,
        passPayload,
    );
    return data.data;
};

const getOneCustomer = async (id: any) => {
    const {data} = await apiRequest('baseUrl').get(`/user/users-profile/${id}`);
    return data;
};

const getBalance = async (account: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/accounts/${account}/balance`,
    );
    return data;
};

const toggleCustomer = async (
    status: any,
    userId: any,
    filter: any,
    otp: any,
) => {
    let url =
        status !== '0' ? `enable/users/${userId}` : `disable/user/${userId}`;
    const {data} = await apiRequest('baseUrl3Admin').get(`/${url}${filter}`, {
        headers: authHeader(otp),
    });
    return data.data;
};

//NEW ACCOUNT

const newAccount = async (filter: string) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/request_additional_accounts${filter}`,
    );
    return data.data;
};

const approveRequest = async (payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/approve/request_additional_accounts`,
        passPayload,
    );
    return data.data;
};

const resetGoogleAuth = async (id: number, otp: any, key: any) => {
    let passPayload = getPayload(key, {}, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').put(
        `/users-profile/${id}/reset_ga`,
        passPayload,
        {
            headers: authHeader(otp),
        },
    );
    return data.data;
};

const verifyBVN = async (payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/verify_bvn`,
        passPayload,
    );
    return data.data;
};

const createCustomer = async (payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/register_user`,
        passPayload,
    );
    return data.data;
};

//USER DETAILS
const userDocument = async (filters: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/user_documents?user_id=${filters}`,
    );
    return data.data;
};

const userLoginHistory = async (id: any, filters: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/login_histories/user/${id}${filters}`,
    );
    return data.data;
};

const userKycDocument = async (filters: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/kyc_upgrade_request?user_id=${filters}`,
    );
    return data.data;
};

const userKyc = async (filters: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/kyc_upgrade_request?user_id=${filters}`,
    );
    return data.data;
};

const userDocuments = async (id: number) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/kyc/documents?kyc_level=4&user_id=${id}`,
    );
    return data.data;
};

const uploadDocument = async (payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/upload_document`,
        passPayload,
    );
    return data.data;
};

const userDevices = async (id: number) => {
    const {data} = await apiRequest('baseUrl3Admin').get(`/user/${id}/devices`);
    return data.data;
};

const allDevices = async (filters: string) => {
    const {data} = await apiRequest('baseUrl3Admin').get(`/devices${filters}`);
    return data.data;
};

const lockHistories = async (id: number) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/user/lock_histories/${id}`,
    );
    return data.data;
};

const getProfileRequest = async (filter:string) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/user-profile-update-requests${filter}`,
    );
    return data.data;
};

const editProfileRequest = async (id: number, payload: any, otp: string) => {
    const {data} = await apiRequest('baseUrl3Admin').put(
        `/user-profile-update-requests/${id}`,
        payload,
        {
            headers: authHeader(otp),
        },
    );
    return data.data;
};

const createProfileRequest = async (
    payload: any,
    otp: string,
    key: any,
) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/user-profile-update-requests`,
        passPayload,
        {
            headers: authHeader(otp),
        },
    );
    return data.data;
};

const approveUpgrade = async (id: number) => {
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/user-profile-update-requests/${id}/review`,
    );
    return data.data;
};
const deleteUpgrade = async (id: number) => {
    const {data} = await apiRequest('baseUrl3Admin').delete(
        `/user-profile-update-requests/${id}`,
    );
    return data.data;
};

const getBankAccounts = async (id: number) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/users/${id}/bank_accounts`,
    );
    return data.data;
};
const editBankAccounts = async (
    id: number,
    payload: any,
    otp: string,
    key: any,
) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').put(
        `/bank_accounts/${id}/update`,
        passPayload,
        {
            headers: authHeader(otp),
        },
    );
    return data.data;
};

const customerServices = {
    AllCustomers,
    searchCustomers,
    getOneCustomer,
    getBalance,
    toggleCustomer,
    newAccount,
    approveRequest,
    resetGoogleAuth,
    verifyBVN,
    createCustomer,
    userDocument,
    userKyc,
    userKycDocument,
    userLoginHistory,
    userDocuments,
    uploadDocument,
    userDevices,
    allDevices,
    lockHistories,
    getProfileRequest,
    editProfileRequest,
    createProfileRequest,
    deleteUpgrade,
    approveUpgrade,
    getBankAccounts,
    editBankAccounts,
};

export default customerServices;
