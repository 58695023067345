import {authHeader} from '../../../utils/headers';

 
import {apiRequest} from '../../../utils/axiosInstance';

const AllAgents = async (token: string, filters: string) => {
    const {data} = await apiRequest('posUrl').get(`/agent${filters}`, {
        headers: authHeader(token),
    });
    return data.data;
};

const AgentCategories = async (token: string) => {
    const {data} = await apiRequest('posUrl').get(`/agent-category`, {
        headers: authHeader(token),
    });
    return data.data;
};

const getTerminal = async (token: string,filter:any) => {
    const {data} = await apiRequest('url').get(`https://pos-api.getripay.com/tms/api/terminal/in-active`, {
        headers: authHeader(token),
    });
    return data.data;
};

const assignPos = async (token: string, payload: any) => {
    const {data} = await apiRequest('posUrl').post(`/agent_terminal`, payload, {
        headers: authHeader(token),
    });
    return data.data;
};

const agentActivation = async (token: string, id: any, type: any) => {
    let url =
        type === 'Active' ? `/agent/deactivate/${id}` : `/agent/activate/${id}`;
    const {data} = await apiRequest('posUrl').get(`${url}`, {
        headers: authHeader(token),
    });
    return data.data;
};

const agentService = {
    AllAgents,
    AgentCategories,
    assignPos,
    agentActivation,
    getTerminal,
};
export default agentService;
