import React from 'react';
import {TextInput} from '../BasicInputs';

function ToggleCustomer({
    close,
    comment,
    setComment,
    setOtpModal,
}: {
    close: () => void;
    comment: string;
    setComment: (arg: string) => void;
    setOtpModal: (arg: any) => void;
}) {
    return (
        <div className="row">
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={comment}
                    changeVal={setComment}
                    label="Comment"
                    placeholder="Enter Comment"
                />
            </div>

            <div className="col-sm-6 sm-mb-3 text-center">
                <button
                    onClick={() => {
                        setOtpModal(true);
                    }}
                    className="main-btn">
                    {'Approve'}
                </button>
            </div>
            <div className="col-sm-6 text-center">
                <button onClick={() => close()} className="main-btn danger">
                    cancel
                </button>
            </div>
        </div>
    );
}

export default ToggleCustomer;
