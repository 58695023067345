import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import KYCService from '../../../redux/features/kyc/kycService';
import {displayError} from '../../../utils/errors';
import Paginate from '../../../component/Paginate';
import dateFormat from 'dateformat';
import {GoPencil} from 'react-icons/go';
import {AiOutlineDelete} from 'react-icons/ai';
import {LiaEyeSolid} from 'react-icons/lia';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import KycLevelDetails from '../../../component/Kyc/KycLevelDetails';
import ModalComponent from '../../../component/ModalComponent';
import AddEditKycLevel from '../../../component/Kyc/AddEditKycLevel';
import LoadTable from '../../../component/Loaders/LoadTable';
import PageCover from '../../../component/PageCover';

function Levels() {
    const {token} = useAppSelector(state => state.auth);
    //FILTER
    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});
    const limit = 15;
    const page = 1;

    const [open, setOpen] = useState(false);
    const [details, setDetails] = useState<any>({});
    const [add, setAdd] = useState(false);

    //ADD EDIT KYC LEVELS

    const fetchLevels = async () => {
        try {
            setLoad(true);
            const res = await KYCService.getLevels(token);
            setLoad(false);
            setList(res);
        } catch (err) {
            setLoad(false);
            setList([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchLevels();
    }, []);
    return (
        <PageCover page="KYC Levels">
        <div>
            <BreadCrumbTitle
                title="KYC Levels"
                dataCount={list?.totalElements}
            />
            <div className="basic-card mt-3">
                <div className="head"></div>
                <div className="body">
                    <div className="table-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Level Name</th>
                                        <th>Description</th>
                                        <th>Position</th>
                                        <th>KYC Level</th>
                                        <th>Created At</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                {!load && list?.length > 0 && (
                                    <tbody>
                                        {list.map((tr: any, i: number) => (
                                            <tr key={tr.id}>
                                                <td>
                                                    {' '}
                                                    {limit * (page - 1) + i + 1}
                                                </td>

                                                <td>{tr.level_name}</td>
                                                <td>{tr.description}</td>
                                                <td>{tr.position}</td>
                                                <td>{tr.level}</td>
                                                <td>
                                                    {dateFormat(tr?.created_at)}
                                                </td>
                                                <td className='d-flex justify-content-evenly'>
                                                    <span role='button'
                                                        onClick={() => {
                                                            setOpen(true);
                                                            setDetails(tr);
                                                        }}>
                                                        {' '}
                                                        <LiaEyeSolid />
                                                    </span>
                                                    {/* <span  role='button'
                                                        onClick={() => {
                                                            setAdd(true);
                                                            setDetails(tr);
                                                        }}>
                                                        {' '}
                                                        <GoPencil />
                                                    </span> */}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
                {load && <LoadTable />}
            </div>
            <ModalComponent
                open={open}
                close={() => setOpen(false)}
                size="sm"
                title="Kyc Level Details">
                <KycLevelDetails {...details} />
            </ModalComponent>

            <ModalComponent
                open={add}
                close={() => setAdd(false)}
                title={`${details?.id ? 'Edit' : 'Add'} Kyc Level`}>
                <AddEditKycLevel
                    details={details}
                    setClosed={() => {
                        setAdd(false);
                        fetchLevels();
                    }}
                />
            </ModalComponent>
        </div></PageCover>
    );
}

export default Levels;
