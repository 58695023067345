import React from 'react';
import {formatCurrency} from '../../utils/numberFormat';
import dateFormat from 'dateformat';

interface props {
    name: string;
    description: string;
    annual_interest: string;
    minimum_amount: string;
    maximum_amount: string;
    createdAt: string;
}

function InterestConfigDetails({
    name,
    description,
    annual_interest,
    minimum_amount,
    maximum_amount,
    createdAt,
}: props) {
    return (
        <div className="row">
            <div className="m-3">
                <strong>Name: </strong>
                {name}
            </div>
            <div className="m-3">
                <strong>Description: </strong>
                {description}
            </div>
            <div className="m-3">
                <strong>Annual Interest: </strong>
                {annual_interest}
            </div>
            <div className="m-3">
                <strong>Minimum Amount: </strong>
                {formatCurrency(minimum_amount)}
            </div>

            <div className="m-3">
                <strong>Maximum Amount: </strong>
                {formatCurrency(maximum_amount)}
            </div>
            <div className="m-3">
                <strong>Date Created: </strong>
                {dateFormat(createdAt, 'yyyy-MM-dd , hh:mm:ss')}
            </div>
        </div>
    );
}

export default InterestConfigDetails;
