import React, {useEffect} from 'react';
import dateFormat from 'dateformat';
import Paginate from '../../Paginate';
import LoadTable from '../../Loaders/LoadTable';

interface props {
    device: any;
    page: number;
    setPage: (arg: number) => void;
    limit: number;
    setLimit: (arg: number) => void;
    load: boolean;
}

function Devices({device, page, setPage, limit, setLimit, load}: props) {
    useEffect(() => {
        setPage(1);
        setLimit(50);
    }, []);
    return (
        <div>
            <div className="basic-card">
                <div className="body">
                    <div className="table-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Device Id</th>
                                        <th>Manufacturer</th>
                                        <th>Device Name</th>
                                        <th>Device Os</th>
                                        <th>Face Verification</th>
                                        <th>Face Id</th>
                                        <th>Date Logged In</th>
                                        <th>Ip Address</th>
                                    </tr>
                                </thead>

                                {!load && device?.data?.length > 0 && (
                                    <tbody>
                                        {device?.data?.map(
                                            (props: any, i: number) => (
                                                <tr key={props.id}>
                                                    <td>
                                                        {' '}
                                                        {limit * (page - 1) +
                                                            i +
                                                            1}
                                                    </td>
                                                    <td>{props.device_id}</td>
                                                    <td>
                                                        {
                                                            props.device_manufacturer
                                                        }
                                                    </td>
                                                    <td>{props.device_name}</td>
                                                    <td>{props.device_os}</td>
                                                    <td>
                                                        {
                                                            props?.has_verified_face?"Done":"Not Done"
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            props?.face_verification_id
                                                            
                                                        }
                                                    </td>
                                                    <td>
                                                        {dateFormat(
                                                            props.created_at,
                                                        )}
                                                    </td>
                                                    <td>{props.ip_address}</td>
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        {!load && device?.last_page > 1 && (
                            <Paginate
                                currentPage={page}
                                totalCount={device.total}
                                pageSize={limit}
                                onSelect={(p: number) => setPage(Number(p))}
                                onNext={(p: number) => setPage(p)}
                                onPrev={(p: number) => setPage(p)}
                                changeLimit={(p: string) => setLimit(Number(p))}
                            />
                        )}
                        {load && <LoadTable />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Devices;
