import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import basicService from './basicService';

const initialState = {
    load: false,
    userStats: {} as any,
    transactionStats: {} as any,
};

export const getUserStats = createAsyncThunk(
    'basic/userStats',
    async (data: any, thunkAPI: any) => {
        try {
            let res = await basicService.getUserStats(
                data.startDate,
                data.endDate,
                
            );
            return res;
        } catch (error: any) {
            console.log(error);
            // if (error?.response?.status === 401) {
            //     thunkAPI.dispatch(logout());
            // }
        }
    },
);

export const getTransactionStats = createAsyncThunk(
    'basic/transactionStats',
    async (data: any, thunkAPI: any) => {
        try {
            const { details} = thunkAPI.getState().auth;
            let res = await basicService.getTransactionStats(
                details.id,
                data.startDate,
                data.endDate,
                
            );
            return res;
        } catch (error) {
            console.log(error);
        }
    },
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearStats: state => {
            state.userStats = {};
            state.transactionStats = {};
        },
    },
    extraReducers: builder => {
        builder.addCase(getUserStats.fulfilled, (state, action) => {
            state.userStats = action.payload;
        });
        builder.addCase(getTransactionStats.pending, (state, action) => {
            state.load = true;
        });
        builder.addCase(getTransactionStats.fulfilled, (state, action) => {
            state.transactionStats = action.payload;
            state.load = false;
        });
        builder.addCase(getTransactionStats.rejected, state => {
            state.load = false;
        });
    },
});

export const {clearStats} = authSlice.actions;

export default authSlice.reducer;
