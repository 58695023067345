import dateFormat from 'dateformat';
import React from 'react';
import { GoPencil } from 'react-icons/go';

function Accounts({
    id,
    setModalState,
    setModal,
    setEditAccount,
    setCall,
    list
}: {
    list:any;
    id: number;
    setModalState: (arg: any) => void;
    setModal: (arg: boolean) => void;
    setEditAccount: (arg: string) => void;
    setCall:(arg:number)=>void;
}) {
   
    return (
        <div>
            <div className="">
                <div className="body">
                    <div className="table-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>account name</th>
                                        <th>Account number</th>
                                        <th>account type</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                {list.length > 0 && (
                                    <tbody>
                                        {list?.map((props: any, i: number) => (
                                            <tr key={props.id}>
                                                <td> {i + 1} </td>
                                                <td>{props.account_name}</td>
                                                <td>{props.account_number}</td>
                                                <td>{props.account_type}</td>

                                                <td>
                                                    {dateFormat(
                                                        props.created_at,
                                                    )}
                                                </td>

                                                <td
                                                    role='button'
                                                    onClick={() => {
                                                        setModal(true);
                                                        setModalState({
                                                            function: 'account',
                                                            state: 'account',  
                                                        });
                                                        setCall(props.id)
                                                        setEditAccount(
                                                            props.account_name,
                                                        );
                                                    }}>
                                                      <GoPencil />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Accounts;
