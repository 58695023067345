import {authHeader} from '../../../utils/headers';
import {getPayload} from '../../../utils/encrypt';
import {apiRequest} from '../../../utils/axiosInstance';

const AllCustomers = async (token: string, payload: any, key: any) => {
    let passPayload = getPayload(key, {}, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/users-all${payload}`,
        passPayload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const searchCustomers = async (
    token: string,
    word: string,
    limit: number,
    page: number,
    key: any,
) => {
    let passPayload = getPayload(key, {}, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/search/users?words=${word}&per_page=${limit}&page=${page}`,
        passPayload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const getOneCustomer = async (token: string, id: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/users-profile/${id}`,
        {
            headers: authHeader(token),
        },
    );
    return data;
};

const getBalance = async (token: string, account: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/accounts/${account}/balance`,
        {
            headers: authHeader(token),
        },
    );
    return data;
};

const toggleCustomer = async (
    token: string,
    status: any,
    userId: any,
    filter: any,
    otp: any,
) => {
    let url =
        status !== '0' ? `enable/users/${userId}` : `disable/user/${userId}`;
    const {data} = await apiRequest('baseUrl3Admin').get(`/${url}${filter}`, {
        headers: authHeader(token, otp),
    });
    return data.data;
};

//NEW ACCOUNT

const newAccount = async (token: string, filter: string) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/request_additional_accounts${filter}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const approveRequest = async (token: string, payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/approve/request_additional_accounts`,
        passPayload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

//CORPORATE ACCOUNT
const corporateAccount = async (token: string, filters: any) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/corporate_profiles${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const createCorporateAccount = async (
    token: string,
    payload: any,
    key: any,
) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/corporate_profiles`,
        passPayload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const resetGoogleAuth = async (
    token: string,
    id: number,
    otp: any,
    key: any,
) => {
    let passPayload = getPayload(key, {}, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').put(
        `/users-profile/${id}/reset_ga`,
        passPayload,
        {
            headers: authHeader(token, otp),
        },
    );
    return data.data;
};

const verifyBVN = async (token: string, payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/verify_bvn`,
        passPayload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const createCustomer = async (token: string, payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/register_user`,
        passPayload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

//USER DETAILS
const userDocument = async (token: string, filters: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/user_documents?user_id=${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const userLoginHistory = async (token: string, id: any, filters: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/login_histories/user/${id}${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const userKycDocument = async (token: string, filters: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/kyc_upgrade_request?user_id=${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const userKyc = async (token: string, filters: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/kyc_upgrade_request?user_id=${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const userDocuments = async (token: string, id: number) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/kyc/documents?kyc_level=4&user_id=${id}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const uploadDocument = async (token: string, payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/upload_document`,
        passPayload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const userDevices = async (token: string, id: number) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/user/${id}/devices`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const allDevices = async (token: string,filters:string) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/devices${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const customerServices = {
    AllCustomers,
    searchCustomers,
    getOneCustomer,
    getBalance,
    toggleCustomer,
    newAccount,
    corporateAccount,
    createCorporateAccount,
    approveRequest,
    resetGoogleAuth,
    verifyBVN,
    createCustomer,
    userDocument,
    userKyc,
    userKycDocument,
    userLoginHistory,
    userDocuments,
    uploadDocument,
    userDevices,
    allDevices,
};

export default customerServices;
